import React, { useEffect, useState } from "react";
import {
  getUserQuestionData,
  getProfileDetails,
  getUserQuestionDataHistory,
  getCouponCode,
} from "../../../api/ApiRequest";
import { useHistory, useLocation } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";
import "./History.scss";
import "./tab.scss";
import "./mobile.scss";
import ModalComponent from "../../comon-component/modal/Modal";
import Loader from "../../comon-component/Loader/Loader";
import { assets } from "../../../utils/assets";

const HistoryPage = () => {
  const [userId, setUserId] = useState("");
  const [programId, setProgramId] = useState("");
  const [queData, setQueData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [selectedDay, setSelectedDay] = useState("1");
  const [userDaysCompleted, setUserDaysCompleted] = useState(0);
  const [pmRunTotalDays, setPmRunTotalDays] = useState(0);
  const [isSeeMoreClicked, setIsSeeMoreClicked] = useState(false);
  const [historydata, setHistoryData] = useState([]);
  const [RowIndex, setRowIndex] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showCoupn, setShowCoupon] = useState(false);
  const [coupon, setCoupon] = useState("");

  const DesktopAchievementCard = assets['achievement_card_desktop'];
  const TabAchievementCard = assets['achievement_card_tab'];
  const MobileAchievementCard = assets['achievement_card_mobile'];
  const backArrow = assets['back arrow'];

  const windowWidth = useWindowWidth();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userIdParam = searchParams.get("userId");
    const programIdParam = searchParams.get("pmRunProgramTypeId");
    setUserId(userIdParam);
    setProgramId(programIdParam);

    getProfileDetails()
      .then((profileRes) => {
        setProfileData(profileRes.data);
        getUserQuestionData({
          userId: profileRes.data.id.toString(),
          pmRunProgramTypeId: programIdParam,
        })
          .then((questionDataRes) => {
            setQueData(questionDataRes.data);
            setUserDaysCompleted(questionDataRes.data.daysCompleted);
            setPmRunTotalDays(questionDataRes.data.totalDays);
          })
          .catch((error) =>
            console.error("Error fetching user question data:", error)
          );

        getUserQuestionDataHistory({
          pmRunProgramTypeId: programIdParam,
          userId: profileRes.data.id.toString(),
        })
          .then((historyDataRes) => {
            setHistoryData(historyDataRes.data);
          })
          .catch((error) =>
            console.error("Error fetching user question history data:", error)
          );
        getCouponCode({
          pmRunProgramTypeId: programIdParam,
        })
          .then((couponResponse) => {
            setCoupon(couponResponse.data[0]?.coupon_code);
          })
          .catch((error) => console.error("Error fetching coupon code", error));
      })
      .catch((error) =>
        console.error(
          "Error fetching profile data and user question data:",
          error
        )
      );
  }, []);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleDaySelection = (day) => {   
    if (day <= userDaysCompleted) {
      setSelectedDay(day);
      setIsSeeMoreClicked(false);

      if (windowWidth <= 475) {
        // Adjust row index based on window width <= 475
        if (day >= 1 && day <= 5) {
          setRowIndex(1);
        } else if (day >= 6 && day <= 10) {
          setRowIndex(2);
        } else if (day >= 11 && day <= 15) {
          setRowIndex(3);
        } else if (day >= 16 && day <= 20) {
          setRowIndex(4);
        } else if (day >= 21 && day <= 25) {
          setRowIndex(5);
        } else if (day >= 26 && day <= 30) {
          setRowIndex(6);
        }
      } else if (windowWidth <= 770) {
        // Default row index adjustments for window width <= 770
        if (day >= 1 && day <= 7) {
          setRowIndex(1);
        } else if (day >= 8 && day <= 14) {
          setRowIndex(2);
        } else if (day >= 15 && day <= 21) {
          setRowIndex(3);
        } else if (day >= 22 && day <= 28) {
          setRowIndex(4);
        } else if (day >= 29 && day <= pmRunTotalDays) {
          setRowIndex(5);
        }
      }
      if (
        day == queData?.daysCompleted &&
        queData?.totalDays === queData?.daysCompleted
      ) {
        setShowCoupon(true);
      }
      else{
        setShowCoupon(false);
      }
    }
  };

  const handleSeeMoreClick = () => {
    setIsSeeMoreClicked((prev) => !prev);
  };

  const handlePreviousDay = () => {
    const prevDay = parseInt(selectedDay) - 1;
    if (prevDay >= 1) {
      setSelectedDay(prevDay.toString());
      if (
        prevDay == queData?.daysCompleted &&
        queData?.totalDays === queData?.daysCompleted
      ) {
        setShowCoupon(true);
      }
      else{
        setShowCoupon(false);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleNextDay = () => {
    const nextDay = parseInt(selectedDay) + 1;
    if (nextDay <= userDaysCompleted) {
      setSelectedDay(nextDay.toString());
      if (
        nextDay == queData?.daysCompleted &&
        queData?.totalDays === queData?.daysCompleted
      ) {
        setShowCoupon(true);
      }
      else{
        setShowCoupon(false);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const viewCertificateHandler = () => {
    setShowPopup(true);
  };
  return (
    <>
      {" "}
      <div style={{ display: loading ? "" : "none" }}>
        <Loader />
      </div>
      <div data-testid='history-container' className="container-history">
        <div className="back-arrow-container" onClick={handleBackClick}>
          <img src={backArrow} alt="backarrow" className="Group-26931"></img>
          <span className="Back-to-PM-Run">Back to PM Run</span>
        </div>
        <p className="Check-your-progress">Check your progress</p>
        <p className="Select-the-day-from-the-left-section-and-review-your-learnings">
          Select the day from the left section and review your learnings.
        </p>
        <div className="container-2">
          <div className="calendar" data-testid="calendar">
            <span className="-Day-Run">{pmRunTotalDays} Days Run</span>
            {/* Calendar for devices larger than tab */}
            {windowWidth >= 770 ? (
              <div className="circle-container">
                {[...Array(pmRunTotalDays)].map((_, index) => (
                  <div key={index} className="day-circle">
                    <div
                      className={`${
                        index + 1 <= userDaysCompleted
                          ? selectedDay === `${index + 1}`
                            ? "selected"
                            : "Day"
                          : "Day_noborder"
                      }`}
                      onClick={() => handleDaySelection(`${index + 1}`)}
                    >
                      <p
                        className={`${
                          index + 1 <= userDaysCompleted
                            ? selectedDay === `${index + 1}`
                              ? "selected_day_text"
                              : "day_text"
                            : "day_text_grey"
                        }`}
                      >
                        DAY
                      </p>
                      <p
                        className={`${
                          index + 1 <= userDaysCompleted
                            ? selectedDay === `${index + 1}`
                              ? "selected_day_text_no"
                              : "day_text_no"
                            : "day_text_no_text"
                        }`}
                      >
                        {index + 1 < 10 ? `0${index + 1}` : index + 1}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {/* Calendar for tabs */}
            {windowWidth > 475 && windowWidth < 770 ? (
              <>
                {(RowIndex === 1 || isSeeMoreClicked) && (
                  <div className="circle-container">
                    {[...Array(7)].map((_, index) => {
                      if (index > 6 && !isSeeMoreClicked) return;
                      return (
                        <div key={index} className="day-circle">
                          <div
                            className={`${
                              index + 1 <= userDaysCompleted
                                ? selectedDay === `${index + 1}`
                                  ? "selected"
                                  : "Day"
                                : "Day_noborder"
                            }`}
                            onClick={() => handleDaySelection(`${index + 1}`)}
                          >
                            <p
                              className={`${
                                index + 1 <= userDaysCompleted
                                  ? selectedDay === `${index + 1}`
                                    ? "selected_day_text"
                                    : "day_text"
                                  : "day_text_grey"
                              }`}
                            >
                              DAY
                            </p>
                            <p
                              className={`${
                                index + 1 <= userDaysCompleted
                                  ? selectedDay === `${index + 1}`
                                    ? "selected_day_text_no"
                                    : "day_text_no"
                                  : "day_text_no_text"
                              }`}
                            >
                              {index + 1 < 10 ? `0${index + 1}` : index + 1}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {(RowIndex === 2 || isSeeMoreClicked) && (
                  <div className="circle-container">
                    {[...Array(7)].map((_, index = 8, rowIndex) => {
                      if (index > 6 && !isSeeMoreClicked) return;
                      const dayNumber = index + 8; // Days start from 8

                      return (
                        <div key={index} className="day-circle">
                          <div
                            className={`${
                              dayNumber <= userDaysCompleted
                                ? selectedDay === `${dayNumber}`
                                  ? "selected"
                                  : "Day"
                                : "Day_noborder"
                            }`}
                            onClick={() => handleDaySelection(`${dayNumber}`)}
                          >
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text"
                                    : "day_text"
                                  : "day_text_grey"
                              }`}
                            >
                              DAY
                            </p>
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text_no"
                                    : "day_text_no"
                                  : "day_text_no_text"
                              }`}
                            >
                              {dayNumber < 10 ? `0${dayNumber}` : dayNumber}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {(RowIndex === 3 || isSeeMoreClicked) && (
                  <div className="circle-container">
                    {[...Array(7)].map((_, index = 8, rowIndex) => {
                      if (index > 6 && !isSeeMoreClicked) return;
                      const dayNumber = index + 15;

                      return (
                        <div key={index} className="day-circle">
                          <div
                            className={`${
                              dayNumber <= userDaysCompleted
                                ? selectedDay === `${dayNumber}`
                                  ? "selected"
                                  : "Day"
                                : "Day_noborder"
                            }`}
                            onClick={() => handleDaySelection(`${dayNumber}`)}
                          >
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text"
                                    : "day_text"
                                  : "day_text_grey"
                              }`}
                            >
                              DAY
                            </p>
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text_no"
                                    : "day_text_no"
                                  : "day_text_no_text"
                              }`}
                            >
                              {dayNumber < 10 ? `0${dayNumber}` : dayNumber}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {(RowIndex === 4 || isSeeMoreClicked) && (
                  <div className="circle-container">
                    {[...Array(7)].map((_, index = 8, rowIndex) => {
                      if (index > 6 && !isSeeMoreClicked) return;
                      const dayNumber = index + 22;

                      return (
                        <div key={index} className="day-circle">
                          <div
                            className={`${
                              dayNumber <= userDaysCompleted
                                ? selectedDay === `${dayNumber}`
                                  ? "selected"
                                  : "Day"
                                : "Day_noborder"
                            }`}
                            onClick={() => handleDaySelection(`${dayNumber}`)}
                          >
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text"
                                    : "day_text"
                                  : "day_text_grey"
                              }`}
                            >
                              DAY
                            </p>
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text_no"
                                    : "day_text_no"
                                  : "day_text_no_text"
                              }`}
                            >
                              {dayNumber < 10 ? `0${dayNumber}` : dayNumber}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {(RowIndex === 5 || isSeeMoreClicked) && (
                  <div className="circle-container">
                    {[...Array(2)].map((_, index = 8) => {
                      if (index > 6 && !isSeeMoreClicked) return;
                      const dayNumber = index + 29;
                      return (
                        <div key={index} className="day-circle">
                          <div
                            className={`${
                              dayNumber <= userDaysCompleted
                                ? selectedDay === `${dayNumber}`
                                  ? "selected"
                                  : "Day"
                                : "Day_noborder"
                            }`}
                            onClick={() => handleDaySelection(`${dayNumber}`)}
                          >
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text"
                                    : "day_text"
                                  : "day_text_grey"
                              }`}
                            >
                              DAY
                            </p>
                            <p
                              className={`${
                                dayNumber <= userDaysCompleted
                                  ? selectedDay === `${dayNumber}`
                                    ? "selected_day_text_no"
                                    : "day_text_no"
                                  : "day_text_no_text"
                              }`}
                            >
                              {dayNumber < 10 ? `0${dayNumber}` : dayNumber}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {pmRunTotalDays > 7 ? (
                  !isSeeMoreClicked ? (
                    <button
                      className="see-more-btn"
                      onClick={handleSeeMoreClick}
                    >
                      See more <IoChevronDown />
                    </button>
                  ) : (
                    <button
                      className="see-more-btn"
                      onClick={handleSeeMoreClick}
                    >
                      See less <IoChevronUp />
                    </button>
                  )
                ) : null}
              </>
            ) : null}
            {/* Calendar for mobile phones */}
            {windowWidth <= 475 ? (
              <>
                <>
                  {pmRunTotalDays <= 7 ? (
                    <>
                      {(RowIndex === 1 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(5)].map((_, index) => {
                            if (index > 4 && !isSeeMoreClicked) return;
                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    index + 1 <= userDaysCompleted
                                      ? selectedDay === `${index + 1}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${index + 1}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      index + 1 <= userDaysCompleted
                                        ? selectedDay === `${index + 1}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      index + 1 <= userDaysCompleted
                                        ? selectedDay === `${index + 1}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {index + 1 < 10
                                      ? `0${index + 1}`
                                      : index + 1}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(RowIndex === 2 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(2)].map((_, index = 8, rowIndex) => {
                            if (index > 6 && !isSeeMoreClicked) return;
                            const dayNumber = index + 6; // Days start from 8

                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    dayNumber <= userDaysCompleted
                                      ? selectedDay === `${dayNumber}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${dayNumber}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {dayNumber < 10
                                      ? `0${dayNumber}`
                                      : dayNumber}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {(RowIndex === 1 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(5)].map((_, index) => {
                            if (index > 4 && !isSeeMoreClicked) return;
                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    index + 1 <= userDaysCompleted
                                      ? selectedDay === `${index + 1}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${index + 1}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      index + 1 <= userDaysCompleted
                                        ? selectedDay === `${index + 1}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      index + 1 <= userDaysCompleted
                                        ? selectedDay === `${index + 1}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {index + 1 < 10
                                      ? `0${index + 1}`
                                      : index + 1}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(RowIndex === 2 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(5)].map((_, index = 8, rowIndex) => {
                            if (index > 6 && !isSeeMoreClicked) return;
                            const dayNumber = index + 6; // Days start from 8

                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    dayNumber <= userDaysCompleted
                                      ? selectedDay === `${dayNumber}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${dayNumber}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {dayNumber < 10
                                      ? `0${dayNumber}`
                                      : dayNumber}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(RowIndex === 3 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(5)].map((_, index = 8, rowIndex) => {
                            if (index > 6 && !isSeeMoreClicked) return;
                            const dayNumber = index + 11; // Days start from 8

                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    dayNumber <= userDaysCompleted
                                      ? selectedDay === `${dayNumber}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${dayNumber}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {dayNumber < 10
                                      ? `0${dayNumber}`
                                      : dayNumber}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(RowIndex === 4 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(5)].map((_, index = 8, rowIndex) => {
                            if (index > 6 && !isSeeMoreClicked) return;
                            const dayNumber = index + 16; // Days start from 8

                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    dayNumber <= userDaysCompleted
                                      ? selectedDay === `${dayNumber}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${dayNumber}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {dayNumber < 10
                                      ? `0${dayNumber}`
                                      : dayNumber}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(RowIndex === 5 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(5)].map((_, index = 8) => {
                            if (index > 6 && !isSeeMoreClicked) return;
                            const dayNumber = index + 21; // Days start from 8
                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    dayNumber <= userDaysCompleted
                                      ? selectedDay === `${dayNumber}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${dayNumber}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {dayNumber < 10
                                      ? `0${dayNumber}`
                                      : dayNumber}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(RowIndex === 6 || isSeeMoreClicked) && (
                        <div className="circle-container">
                          {[...Array(5)].map((_, index = 8) => {
                            if (index > 6 && !isSeeMoreClicked) return;
                            const dayNumber = index + 26; // Days start from 8
                            return (
                              <div key={index} className="day-circle">
                                <div
                                  className={`${
                                    dayNumber <= userDaysCompleted
                                      ? selectedDay === `${dayNumber}`
                                        ? "selected"
                                        : "Day"
                                      : "Day_noborder"
                                  }`}
                                  onClick={() =>
                                    handleDaySelection(`${dayNumber}`)
                                  }
                                >
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text"
                                          : "day_text"
                                        : "day_text_grey"
                                    }`}
                                  >
                                    DAY
                                  </p>
                                  <p
                                    className={`${
                                      dayNumber <= userDaysCompleted
                                        ? selectedDay === `${dayNumber}`
                                          ? "selected_day_text_no"
                                          : "day_text_no"
                                        : "day_text_no_text"
                                    }`}
                                  >
                                    {dayNumber < 10
                                      ? `0${dayNumber}`
                                      : dayNumber}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </>
                {pmRunTotalDays >= 7 ? (
                  !isSeeMoreClicked ? (
                    <button
                      className="see-more-btn"
                      onClick={handleSeeMoreClick}
                    >
                      See more <IoChevronDown />
                    </button>
                  ) : (
                    <button
                      className="see-more-btn"
                      onClick={handleSeeMoreClick}
                    >
                      See less <IoChevronUp />
                    </button>
                  )
                ) : null}
              </>
            ) : null}
          </div>
          <div className="Rectangle-5960">
            <div className="Rectangle-6004">
              <img
                loading="lazy"
                src={
                  windowWidth > 769
                    ? DesktopAchievementCard
                    : windowWidth > 475
                    ? TabAchievementCard
                    : MobileAchievementCard
                }
                alt="Achievement Card"
              />
              <div className="Rectangle-6004-content">
                {showPopup && (
                  <ModalComponent
                    userId={profileData.id}
                    programId={programId}
                    day={selectedDay}
                    userName={profileData.name}
                    open={showPopup}
                    setShowPopup={setShowPopup}
                    questionData={queData}
                    showCoupn={showCoupn}
                    coupon={coupon}
                  />
                )}

                <span className="Congratulations-on-completing-Day-4-of-30-Day-PM-Run">
                  Congratulations on completing Day {selectedDay} of{" "}
                  {pmRunTotalDays}-Days PM Run.
                </span>
                <button
                  data-testid="VIEW CERTIFICATE"
                  className="btn-white-button"
                  onClick={viewCertificateHandler}
                >
                  VIEW CERTIFICATE <FaChevronRight />
                </button>
              </div>
            </div>

            <div data-testid='question-data' className="questions">
              {historydata[parseInt(selectedDay) - 1]?.questions.map(
                (question, index) => (
                  <div key={index}>
                    <span className="Topic-Problem-Solving-Market-Research">
                      <span data-testid='question-topic' className="text-style-1">Topic:&nbsp;</span>
                      <span className="">{question.categoryName}</span>
                    </span>
                    <div key={index} className="question">
                      <p className="questionNo">
                        QUESTION {question.questionNo}.
                      </p>
                      <p className="questionTitle">{question.questionName}</p>
                      <div className="options">
                        {question?.answerOptions.map((option, index) => (
                          <div
                            className={`${
                              question.userAnswer === option
                                ? question.isCorrect
                                  ? "correct_option"
                                  : "wrong_option"
                                : question.correctAnswer === option
                                ? "correct_option"
                                : "Rectangle-5926"
                            }`}
                            key={option}
                          >
                            <div className="option_text">
                              <p
                                className={`${
                                  question.userAnswer === option
                                    ? question.isCorrect
                                      ? "correct_option_text"
                                      : "wrong_option_text"
                                    : question.correctAnswer === option
                                    ? "correct_option_text"
                                    : "no_text"
                                }`}
                              >
                                {question.userAnswer === option
                                  ? question.isCorrect
                                    ? "Correct answer"
                                    : "Selected answer"
                                  : question.correctAnswer === option
                                  ? "Correct answer"
                                  : null}
                              </p>
                              <p className="optionText">{option}</p>
                            </div>
                            {question.userAnswer === option &&
                            question.isCorrect ? (
                              // If the user answer and the current iterated option is equal
                              // and the question is answered correctly then render checked icon.
                              <FaRegCircleCheck className="check-icon" />
                            ) : question.userAnswer === option &&
                              !question.isCorrect ? (
                              // If the user answer and the current iterated option is equal
                              // and the question is not answered correctly then render wrong icon.
                              <RxCrossCircled className="wrong-icon" />
                            ) : !question.isCorrect &&
                              question?.correctAnswer === option ? (
                              // If the question is not answered correctly
                              // and the correct answer of question is equal to current iterated option
                              // then render wrong icon.
                              <FaRegCircleCheck className="check-icon" />
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="button-row">
              <button
                className={`${
                  selectedDay <= 1 ? "white-button_disabled" : "white-button"
                }`}
                onClick={handlePreviousDay}
              >
                <FaChevronLeft />
                PREVIOUS DAY
              </button>
              <button
                className={`${
                  selectedDay >= userDaysCompleted
                    ? "green-button_disabled"
                    : "green-button"
                }`}
                onClick={handleNextDay}
              >
                NEXT DAY
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryPage;

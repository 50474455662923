import React, { useEffect } from "react";
import $ from "jquery";
import Helmet from "react-helmet";
import { assets } from "../../utils/assets";

const FAQ = ({ mainEntity, title }) => {
  const plus = assets['plus-icon'];
  const minush = assets['minush-icon']
  useEffect(() => {
    $(".card").click(function () {
      $(".card").not(this).removeClass("main");
      $(this).toggleClass("main");
    });
  }, []);
  return (
    <section className="hm-faqs">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: mainEntity || [
              {
                "@type": "Question",
                name: "Who is eligible for live program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Working professionals and students in the final year of their degree programs interested in PM roles are ideal for this program. You need not have prior product experience. The application questions act as a shortlisting criteria. Please carefully answer the questions in the application link above.",
                },
              },

              {
                "@type": "Question",
                name: "Do I have to pay for the Live Program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, the Live Program is paid. If you're accepted into the Program and decide to go ahead, there is an upfront fee of Rs. 70,000. Your seat is reserved after you've completed the payment. Please note that we do not have any scholarships or discounts at the moment.",
                },
              },

              {
                "@type": "Question",
                name: "How are the sessions conducted? Is it remote?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Yes, we conduct all the sessions on Zoom. Our Live classes are scheduled on Saturdays, followed by case-study discussions every Tuesdays. Mentor sessions are conducted as per the time mutually agreeable between you and your Mentor.",
                },
              },
              {
                "@type": "Question",
                name: "What do I get after completion of this program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "You get a certificate of completion from PM School, a strong network of Product management peers and a lifetime access to the PM School community.",
                },
              },
              {
                "@type": "Question",
                name: "How many applicants get invited to a Program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "In an effort to ensure quality and a terrific experience for our participants, we cap the number of participants for our Program to a limited number of participants. If we are oversubscribed for a specific Program, we request waitlisted folks to join the next cohort. ",
                },
              },
              {
                "@type": "Question",
                name: "How much time should I spend to get the maximum out of the Live Program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Our cohort-based Program is challenging. You get out what you put in. We compressed decades of professional experience into 8 week modules. To get the most out of the program, you should expect to spend a minimum of 5 hours per week on assignments and quizzes. Our most successful participants spend 8-10 hours per week. ",
                },
              },
              {
                "@type": "Question",
                name: "What if I miss a class on a weekend?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Do not worry, we record all our sessions and each session recording will be available on our learning management system (LMS).",
                },
              },
              {
                "@type": "Question",
                name: "What tools are covered in the program?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "We cover all the relevant tools (Figma, Google Analytics, Mixpanel, etc.) required to succeed as a PM. Our approach is to focus on the concepts and use tools as a strong means to an end.",
                },
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="faqs-container">
        <div className="page-title">
          <h2>{title || "FAQs"}</h2>
        </div>
        {mainEntity ? (
          <div id="accordion" className="accordion-row">
            <div className="faqs-row">
              <div className="myaccordion">
                {mainEntity.map((item, index) => (
                  <div className="card" key={index}>
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                        >
                          {item.name}
                          <span className="fa-stack fa-2x">
                            <img className="fa-plus" src={assets["plus-icon"]} alt="" />
                            <img className="fa-minus" src={minush} alt="" />
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className="collapse"
                      aria-labelledby={`heading${index}`}
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>{item.acceptedAnswer.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div id="accordion" className="accordion-row">
            <div className="faqs-row">
              <div className="myaccordion">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Who is eligible for the Live Program?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Working professionals and students in the final year of
                        their degree programs interested in PM roles are ideal
                        for this program. You need not have prior product
                        experience. The application questions act as a
                        shortlisting criteria. Please carefully answer the
                        questions in the application link above. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Do I have to pay for the Live Program?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={assets["plus-icon"]} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, the Live Program (PM Flagship course) is paid. If
                        you're accepted into the Program and decide to go ahead,
                        there is an upfront fee of INR 55,000. Your seat is
                        reserved after you've completed the payment. Please note
                        that the aforementioned fee is already at a 28%
                        discount. This is a limited time offer!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Does PM school help me get a job?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We do everything we possibly can to connect you to the
                        companies in our network. We are dedicated to kickstart
                        your PM career. We help you prepare a resume, practice
                        interviews and even help you in salary negotiation.
                        Please note that job placements are not guaranteed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading7">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                      >
                        How are the sessions conducted? Is it remote?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapse7"
                    className="collapse"
                    aria-labelledby="heading7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, we conduct all the sessions on Zoom. Our Live
                        classes are scheduled on Saturdays, followed by
                        case-study discussions every Tuesdays. Mentor sessions
                        are conducted as per the time mutually agreeable between
                        you and your Mentor 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        What do I get after completion of this program?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        You get a certificate of completion from PM School, a
                        strong network of Product folks, alumni status and a
                        lifetime access to the PM School community.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFore">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseFore"
                        aria-expanded="false"
                        aria-controls="collapseFore"
                      >
                        How many applicants get invited to a Program?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFore"
                    className="collapse"
                    aria-labelledby="headingFore"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        In an effort to ensure quality and a terrific experience
                        for our participants, we cap the number of participants
                        for our Program to a limited number of participants. If
                        we are oversubscribed for a specific Program, we request
                        waitlisted folks to join the next cohort. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading6">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        How much time should I spend to get the maximum out of
                        the Live Program?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapse6"
                    className="collapse"
                    aria-labelledby="heading6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Our cohort-based Program is challenging. You get out
                        what you put in. We compressed decades of professional
                        experience into 8 week modules. To get the most out of
                        the program, you should expect to spend a minimum of 5
                        hours per week on assignments and quizzes. Our most
                        successful participants spend 8-10 hours per week.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading8">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8"
                      >
                        What if I miss a class on a weekend?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapse8"
                    className="collapse"
                    aria-labelledby="heading8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Do not worry, we record all our sessions and each
                        session recording will be available on our learning
                        management system (LMS). 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="heading9">
                    <h2 className="mb-0">
                      <button
                        className="d-flex align-items-center justify-content-between btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapse9"
                      >
                        What tools are covered in the program?
                        <span className="fa-stack fa-2x">
                          <img className="fa-plus" src={plus} alt="" />
                          <img className="fa-minus" src={minush} alt="" />
                        </span>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapse9"
                    className="collapse"
                    aria-labelledby="heading9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We cover all the relevant tools (Figma, Google
                        Analytics, Mixpanel, etc.) required to succeed as a PM.
                        Our approach is to focus on the concepts and use tools
                        as a strong means to an end.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FAQ;

import React from "react";
import CourseDetail from "./CourseDetail";
import { assets } from "../../../utils/assets";
import "./index.scss";

const CourseCard = ({
  courseTeachers,
  description,
  course,
  time,
  certification,
  language,
  access,
  price,
  title,
  getStartedUrl,
  comingsoon,
}) => {
  const PMXTeacherImg = assets["PMXTeacher"];
  const RedirectIcon = assets["redirect"];
  const CourseIcon = assets["course-icon"];
  const TimeIcon = assets["time-icon"];
  const CertificationIcon = assets["certification-icon"];
  const LanguageIcon = assets["language-icon"];
  const AccessIcon = assets["access-icon"];
  const PriceIcon = assets["price-icon"];
  return (
    <div className="course__card">
      <div className="courseCard__top">
        <div className="courseCard__title">
          <img
            loading="lazy"
            src={CourseIcon}
            alt=""
          />
          <h3>{title}</h3>
        </div>
        <div className="courseCard__about">
          <div className="about__wrapper">
            <h6>ABOUT COURSE</h6>
            <p>{description}</p>
          </div>
          <div className="details__wrapper">
            <h6>COURSE DETAILS</h6>
            <div className="details">
              <CourseDetail
                icon={CourseIcon}
                title={course}
              />
              <CourseDetail
                icon={TimeIcon}
                title={time}
              />
              <CourseDetail
                icon={CertificationIcon}
                title={certification}
              />
              <CourseDetail
                icon={LanguageIcon}
                title={language}
              />
              <CourseDetail
                icon={AccessIcon}
                title={access}
              />
              <CourseDetail
                icon={PriceIcon}
                title={price}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="courseCard__bottom">
        {courseTeachers &&
          courseTeachers.map((teacher, index) => (
            <div
              className="teacher__detail"
              key={index}
            >
              <img
                loading="lazy"
                className="teacher__img"
                src={teacher.img}
                alt=""
              />
              <div className="name">
                <span>{teacher.post}</span>
                <a
                  href={teacher.profileLink}
                  target="_blank"
                >
                  <h5>{teacher.name}</h5>
                  <img
                    loading="lazy"
                    src={RedirectIcon}
                    alt=""
                  />
                </a>
              </div>
            </div>
          ))}
        <a
          href={getStartedUrl}
          className="join__btn"
        >
          <button>
            <span>{comingsoon}</span>
            <img
              loading="lazy"
              src={assets["btn-right-arrow"]}
              alt=""
            />
          </button>
        </a>
      </div>
    </div>
  );
};

export default CourseCard;

import React from "react";

const Banner = () => {
  return (
    <section className="homepage-banner">
      <h3 className="title">Crack a Product role with PM School</h3>
      <span className="description">
        You can be a Product Manager if you have great user empathy.
      </span>
      <a
        href="https://forms.gle/HSbYWKHVCcfCGoMy6"
        style={{ textDecoration: "none", color: "unset" }}
        rel="noreferrer"
      >
        <button className="btn-green">Join Us</button>
      </a>
    </section>
  );
};

export default Banner;

import { assets } from "../../../utils/assets";
import "./thank-you.scss";
function ThankYou(props) {
  return (
    <div className="submission-container">
      <div className="img-wrapper">
        <img
          loading="lazy"
          className="congrats-img"
          alt=""
          src={assets["congrats-img"]}
        />
      </div>
      <div className="content">
        <span className="title">Thank you for Submission</span>
        <span className="description">
          {"Winner will be declared by " +
            new Date(props.challengeId.end_date_timestamp)
              .toUTCString()
              .split(" ")
              .slice(0, 4)
              .join(" ")}
        </span>
      </div>
    </div>
  );
}
export default ThankYou;

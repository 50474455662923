import React, { useState } from 'react';

import "./forget-password.scss";
import { forgotPasswordRequest } from '../../../api/ApiRequest';
import { TosterMessageSuccess, TosterMessageError } from '../../Toster/Toster';
import { globalStrings } from '../../../strings';

function ForgetPassword(props) {
  const [email, setEmail] = useState();

  const forgetPassword = () => {
    forgotPasswordRequest(email).then(res => {
      if (res.code === 200) {
        props.onChangeView("reset-password", true);
        props.onSetUserEmail(email);
        TosterMessageSuccess({ msg: globalStrings.forget_pass_success });
      } else {
        // 
        TosterMessageError({ msg: globalStrings.something_went_wrong });
      }
    }).catch(err => {
      let msg;
      
      if (err == "NOT_FOUND") {
        msg = globalStrings.user_not_found;
      } else {
        msg = globalStrings.something_went_wrong;
      }
      TosterMessageError({ msg: msg });
    })
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  return (
    <div className="forget-password-container">
      <div className="content-body">
        <h3 className="title">Forgot PASSWORD</h3>
        <h3 className="sub-title">
          Please enter registered e-mail ID or PM School username
        </h3>
        <div className="field-wrapper">
          <input className="input-field" type="email" placeholder="E-mail ID" onChange={handleEmail} />
        </div>
        <div className="btnWrapper">
          <button
            className="btn-green"
            onClick={forgetPassword}
          >
            Email me a recovery link
          </button>
          <button
            className="btn btn-link back-btn"
            onClick={() => {
              props.onChangeView("login", true);
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;

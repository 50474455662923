import React, { useState } from "react";
import { assets } from "../../../utils/assets";
import certificateTexts from "./CertificateTexts.json"
const ButtonComponent = ({ day, shareCertificateHandler, certificateLink }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleShareButtonClick = async (shareType) => {
    try {
      const certificateId = await shareCertificateHandler();
  
      if (certificateId) {

        const pathUrl = `${process.env.REACT_APP_BASE_URL}api/admin/pmrun/renderCertificate?day=${day}&id=${certificateId}`;
  
        let shareUrl = "";
        const text = certificateTexts.find((item) =>item.day==day);
        if (shareType === "linkedin") {
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(text.text)}&url=${encodeURIComponent(pathUrl)}`;
        } else if (shareType === "twitter") {
          shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text.text)}&url=${encodeURIComponent(pathUrl)}`;
        }
  
        // Open share URL in a new tab/window
        window.open(shareUrl, "_blank");
      } else {
        console.error("Error: Certificate ID not available");
      }
    } catch (error) {
      console.error("Error sharing certificate:", error);
      setIsLoading(false);
    }
  };
  

  const handleDownloadButtonClick = async () => {
    try {
      // Call shareCertificateHandler to retrieve certificateId

      if (certificateLink) {
        // Fetch the image data
        const response = await fetch(certificateLink);

        if (!response.ok) {
          throw new Error("Failed to fetch certificate image");
        }

        const blob = await response.blob();

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = url;
        link.download = `certificate_${day}.jpeg`;

        // Programmatically click the anchor to trigger the download
        link.click();

        // Clean up: remove the anchor and revoke the object URL
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Error: Certificate URL not available");
      }
    } catch (error) {
      console.error("Error downloading certificate:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="share-icons-row">
      {/* LinkedIn Share Button */}
      <div
        onClick={() => handleShareButtonClick("linkedin")}
        disabled={isLoading}
      >
        <div className="Ellipse-10">
          <img loading="lazy" src={assets.LinkedIn} alt="LinkedIn" className="Vector" />
        </div>
      </div>

      {/* Twitter Share Button */}
      <div
        onClick={() => handleShareButtonClick("twitter")}
        disabled={isLoading}
      >
        <div className="Ellipse-10">
          <img loading="lazy" src={assets.Twitter} alt="Twitter" className="Vector" />
        </div>
      </div>

      {/* Download Button (Placeholder) */}
      <div onClick={handleDownloadButtonClick} disabled={isLoading}>
        <div className="Ellipse-10">
          <img loading="lazy" src={assets.download} alt="Download" className="Vector" />
        </div>
      </div>
    </div>
  );
};

export default ButtonComponent;

import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import "./Modal.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { assets } from "../../../utils/assets";
import ButtonComponent from "./ButtonComponent";
import {
  getCertificateById,
  getDynamicCertificate,
  getCertificateImage,
} from "../../../api/ApiRequest";
function ModalComponent({
  open,
  setShowPopup,
  userId,
  programId,
  day,
  showCoupn,
  coupon,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState("");
  const [certificateData, setCertificateData] = useState([]);
  const certificateLink = `${process.env.REACT_APP_BASE_URL}api/admin/pmrun/certificateImage?day=${day}&pmRunProgramTypeId=${programId}&userId=${userId}`;

  const loadImage = async () => {
    try {
      const response = await fetch(certificateLink);
      if (response.status === 200) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } catch (error) {
      setIsLoading(true);
      console.error("Error loading image:", error);
    }
  };

  const handleOnCopy = () => {
    setIsCopied(true);
    if (windowWidth < 769) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  useEffect(() => {
    loadImage();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shareCertificateHandler = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const certificateRes = await getDynamicCertificate({
          userId: userId,
          day: day,
          pmRunProgramTypeId: programId,
        });
  
        if (certificateRes && certificateRes.data && certificateRes.data) {
          resolve(certificateRes.data);
        } else {
          reject(new Error("Certificate ID not found"));
        }
      } catch (error) {
        console.error("Error fetching certificate data:", error);
        reject(error);
      }
    });
  };
  
  

  return (
    <>
      <Modal open={open} data-testid="modal-component" className="custom-modal">
        <div className="Rectangle-4713">
          {isLoading ? (
            <div data-testid="loading-indicator" className="container">
              {windowWidth < 769 ? (
                <button
                  className="Vector-1181"
                  onClick={() => {
                    setShowPopup(false);
                  }}
                >
                  <img loading="lazy" src={assets.delete} alt="" />
                </button>
              ) : null}
              <div className="loading-certificate">
                <svg
                  viewBox="0 0 842 595"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="#fff" d="M0 0h842v595H0z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M557.733 377.75 457.501 218l-77.428 123.406L344.5 287 285 378h119l-.163-.25h153.896z"
                    fill="#D9D9D9"
                    fillOpacity=".25"
                  />
                </svg>
              </div>
              <div className="share-column isMobile">
                {windowWidth >= 769 ? (
                  <button
                    className="Vector-1181"
                    onClick={() => {
                      setShowPopup(false);
                    }}
                  >
                    <img loading="lazy" src={assets.delete} alt="" />
                  </button>
                ) : null}
                <div className="share-icons-row">
                  <svg
                    width="349"
                    height="100"
                    viewBox="0 0 349 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="#404040" d="M0 0h285v16H0z" />
                    <rect
                      y="40"
                      width="60"
                      height="60"
                      rx="30"
                      fill="#404040"
                    />
                    <rect
                      x="76"
                      y="40"
                      width="60"
                      height="60"
                      rx="30"
                      fill="#404040"
                    />
                    <rect
                      x="152"
                      y="40"
                      width="60"
                      height="60"
                      rx="30"
                      fill="#404040"
                    />
                  </svg>
                </div>
                {showCoupn && (
                  <div className="loading-skelton">
                    <svg
                      width="349"
                      height="185"
                      viewBox="0 0 349 185"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path stroke="#404040" d="M0 .5h316" />
                      <path
                        fill="#404040"
                        d="M0 41h349v16H0zM0 65h240v12H0zM0 85h134v12H0z"
                      />
                      <rect
                        y="125"
                        width="349"
                        height="60"
                        rx="30"
                        fill="#404040"
                      />
                      <path fill="#6A6A6A" d="M24 147h81v16H24z" />
                      <rect
                        x="295"
                        y="131"
                        width="48"
                        height="48"
                        rx="24"
                        fill="#6A6A6A"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="container">
              {windowWidth < 769 ? (
                <button
                  className="Vector-1181"
                  onClick={() => {
                    setShowPopup(false);
                  }}
                >
                  <img loading="lazy" src={assets.delete} alt="" />
                </button>
              ) : null}
              <div>
                <img
                  onError={() => setIsLoading(true)}
                  src={certificateLink}
                  alt="certificate"
                  className="A4---39 certificate-img"
                  loading="lazy"
                />
              </div>
              <div className="share-column">
                {windowWidth >= 769 ? (
                  <button
                    className="Vector-1181"
                    onClick={() => {
                      setShowPopup(false);
                    }}
                  >
                    <img loading="lazy" src={assets.delete} alt="" />
                  </button>
                ) : null}
                <span className="Share-achievement">Share achievement</span>
                <ButtonComponent
                  day={day}
                  shareCertificateHandler={shareCertificateHandler}
                  certificateLink={certificateLink}
                />
                {showCoupn && (
                  <>
                    <div className="Line-17"></div>
                    <p className="Claim-your-reward-in-Micro-courses">
                      Claim your reward in Micro courses
                    </p>
                    <p className="Use-the-code-below-to-avail-10-discount-on-PM-School-Micro-courses">
                      Use the code below to avail{" "}
                      {coupon === "PM10" ? "10%" : "30%"} discount on PM School
                      Micro courses.
                    </p>
                    <div className="Frame-38">
                      <span className="PM10">{coupon}</span>
                      <CopyToClipboard text={coupon} onCopy={handleOnCopy}>
                        <div className="Frame-41 coupon-code-copy">
                          <img loading="lazy" src={assets.Copy} alt="copy icon" />
                          {isCopied && (
                            <div className="Rectangle-8 coupon-code-tooltip">
                              <span className="Coupon-code-copied">
                                Coupon code copied
                              </span>
                            </div>
                          )}
                        </div>
                      </CopyToClipboard>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ModalComponent;

import "./footer.scss";
import { useLocation } from "react-router-dom";
import { assets } from "../../../utils/assets";

function Footer() {
  const location = useLocation();
  const matchPath =
    location.pathname === "/product-management-training-program";
  return (
    <footer className="footer-container">
      <div className="wrapper">
        <div className="explore-course">
          <div>
            <h4 className="title">
              {matchPath
                ? "Still not sure which course to pick?"
                : "Accelerate your Career with a course at PM School"}
            </h4>
            <p className="disc">
              {matchPath ? (
                "Reach out to our team."
              ) : (
                <>
                  Apply as early as possible, as we have limited seats in a
                  cohort.
                  <br />
                  Shortlists are announced in 1-2 days from your application
                  submission.
                </>
              )}
            </p>
            <div className="btnWrapper">
              <a
                href={
                  matchPath
                    ? "mailto: hello@pmschool.io"
                    : "https://forms.gle/HSbYWKHVCcfCGoMy6"
                }
                style={{ textDecoration: "none", color: "unset" }}
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn-green">
                  {matchPath ? "REACH OUT" : "Apply now"}
                </button>
              </a>
            </div>
          </div>
          <div>
            <img loading="lazy" className="footerImg" src={assets["footer-img"]} alt="" />
          </div>
        </div>
        <div className="info-wrapper">
          <img loading="lazy" className="footer-logo" src={assets.logo} alt="" />
          <div className="content-wrapper">
            <div className="list-wrapper">
              <ul style={{ paddingLeft: "0px" }}>
                {/* <li className="list-item"> <a className="page-item" href="#footer">About Our Community</a></li> */}
                <li className="list-item dot">
                  <a className="page-item" href="/product-management-course">
                    PM Flagship Course
                  </a>
                </li>
                <li className="list-item dot">
                  <a
                    className="page-item"
                    href="/product-management-training-program"
                  >
                    Micro Courses
                  </a>
                </li>
                <li className="list-item">
                  <a
                    className="page-item"
                    href="/testimonials"
                  >
                    Testimonials
                  </a>
                </li>
                {/* {!matchPath && ( */}
                <li className="list-item">
                  <a className="page-item" href="/terms-and-condition">
                    Terms & Conditions
                  </a>
                </li>
                {/* )} */}
              </ul>
            </div>
            <div className="list-wrapper-2">
              <ul>
                {/* <li className="list-item"><a className="page-item" href="#footer">Privacy Policy</a></li> */}
                <li className="list-item">
                  <a className="page-item" href="/product-challenges">
                    Challenges
                  </a>
                </li>
                {/* <li className="list-item">
                  <a className="page-item" href="#">
                    Hire from us
                  </a>
                </li> */}
                <li className="list-item">
                  <a className="page-item" href="/about-us">
                    About Us
                  </a>
                </li>
                {/* <li className="list-item">
                  <a className="page-item" href="#">
                    Blog
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="list-wrapper-3">
              <div>
                <span className="connect-title">Connect With Us On</span>
              </div>
              <div>
                <a className="email-lnk" href="mailto:hello@pmschool.io">
                  hello@pmschool.io
                </a>
              </div>
              <div className="social-wrapper">
                <a
                  href="https://www.linkedin.com/school/pmschoolx/"
                  className="social-link"
                >
                  <img loading="lazy" alt="" className="social-icon" src={assets.linkedIn} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCjOeIGWiIhPdKDrDRrVnvRA"
                  className="social-link"
                >
                  <img loading="lazy" alt="" className="social-icon" src={assets.youtube} />
                </a>
                <a
                  href="https://www.instagram.com/pmschoolx/?hl=en"
                  className="social-link"
                >
                  <img loading="lazy" alt="" className="social-icon" src={assets.insta} />
                </a>
                <a
                  href="https://twitter.com/pmschoolx?lang=en"
                  className="social-link"
                >
                  <img loading="lazy" alt="" className="social-icon" src={assets.twitter} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrapper">
        <span className="copyright-text">
          <span className="copy-icon">&copy;</span>Absorb Technologies Private
          Limited
        </span>
      </div>
    </footer>
  );
}

export default Footer;

export const globalStrings = {
    signin_success: "Signed in Successfully!",
    user_not_found: "Oops, you don't have an account with us. Please Sign up first!",
    wrong_cred: "Please enter the correct password",
    signup_success: "Sign up Successfully!",
    something_went_wrong: "Something Went Wrong!",
    forget_pass_success: "New Password sent to Email Successfully!",
    change_pass_success: "Password changed Successfully!",
    change_pass_fail: "Old Password Not Mached!",
    email_exists: "Email Already Exists!",
    invalid_email: "Email is invalid!",
    link_copied: "Link is copied!"
}
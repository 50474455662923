import React from "react";
import "./styles/_about-us.scss";
import Helmet from "react-helmet";
import MetaTagsScreen from "../../utils/metaTags";
import Banner from "./banner";
import Overview from "./overview";
import WhyPmSchool from "./why-pm-school";
import ForMentor from "../homepage/for-mentor-section";
import Companies from "../course/companies";

const AboutUs = () => {
  return (
    <div className="about-us-main">
      <MetaTagsScreen
        title="Product Management Certification Program in India | PM School"
        desc="India's leading product management certification and training program with job assistance. PM School offers cohort-based program by experienced mentors. Apply today."
        canonicalLink="https://pmschool.io/about-us"
      />
      <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "PM School",
            alternateName: "ABSORB TECHNOLOGIES PRIVATE LIMITED",
            url: "https://pmschool.io/",
            logo: "https://prod2-pmschool.blr1.cdn.digitaloceanspaces.com/assets/images/logo.svg",
            address: {
              "@type": "PostalAddress",
              addressLocality: "MALAD E, Mumbai City, Maharashtra",
              postalCode: "400097",
              streetAddress:
                "3C/805, MUKTANGAN, KAILASHPURI ROAD UPPER GOVID NAGAR",
            },
            email: "hello@pmschool.io",
            sameAs: [
              "https://twitter.com/pmschoolx",
              "https://www.instagram.com/pmschoolx/",
              "https://www.youtube.com/channel/UCjOeIGWiIhPdKDrDRrVnvRA",
              "https://www.linkedin.com/school/pmschoolx/",
            ],
          })}
        </script>
      </Helmet>

      <Banner />
      <Overview />
      <WhyPmSchool />

      <ForMentor />
    </div>
  );
};

export default AboutUs;

import React, { useEffect, useState, lazy } from "react";
import MetaTagsScreen from "../../utils/metaTags";
import LandingBanner from "./landing-banner";
import LiveChallenges from "./live-challenges";
import Logo from "./logo";
import PastChallenges from "./past-challenges";
import { Loader } from "./loader";
import { getAllChallengesRequest } from "../../api/ApiRequest";
import moment from "moment";

const past_challenge_params = {
  number_of_pages: 1,
  search_key: "",
  page: 1,
  limit: 1000,
  sort_by: "number",
  sort_order: -1,
  challenges_listing: [],
  filter_type: "past",
  type_filters: [],
};

const live_challenge_params = {
  number_of_pages: 1,
  search_key: "",
  page: 1,
  limit: 1000,
  sort_by: "number",
  sort_order: -1,
  challenges_listing: [],
  filter_type: "live",
};

const ChallengesListing = () => {
  const [loading, setLoading] = useState(true);
  const [liveChallenges, setLiveChallenges] = useState([]);
  const [pastChallenges, setPastChallenges] = useState([]);
  const [pastFilters, setPastFilters] = useState([]);

  const getChallenges = async (params, type) => {
    return getAllChallengesRequest(params)
      .then((response) => {
        if (response.code == 200) {
          if (type === "past") {
            response.data.forEach((element) => {
              // element['end_date_timestamp'] = new Date(element['end_date_timestamp']).toDateString();
              element["end_date_timestamp"] = new Date(
                element["end_date_timestamp"]
              ).toUTCString();
              element["end_date_timestamp"] = element["end_date_timestamp"]
                .split(" ")
                .slice(0, 4)
                .join(" ");
              element["entries"] = element["all_entries"]
                ? element["all_entries"].length
                : 0;
            });
            setPastChallenges(response.data);

            if (response.filters) {
              setPastFilters(response.filters);
            }
          }
        } else {
          response.data.forEach((element) => {
            element["entries"] = element["all_entries"]
              ? element["all_entries"].length
              : 0;
            var diff = new Date(element.end_date_timestamp) - new Date();
            element["days_difference"] = Math.round(diff / (1000 * 3600 * 24));
            element["end_date_timestamp"] =
              moment(element["end_date_timestamp"]).format(
                "Do MMM YY, hh:mm A"
              ) + " IST";
            // element['end_date_timestamp'] = element['end_date_timestamp'].split(' ').slice(0, 4).join(' ');
          });

          setLiveChallenges(response.data);
        }

        return 1;
      })
      .catch(() => {
        return 1;
      });
  };

  useEffect(() => {
    (async function () {
      // await getChallenges(past_challenge_params, "past");
      // await getChallenges(live_challenge_params, "live");
      setTimeout(() => {
        setLoading(false);
      }, 250);
    })();
  }, []);

  return (
    <>
      <MetaTagsScreen
        title="Solve Product Challenges | For Product Manager & Aspirants | PM School"
        desc="Product challenges enable Product Managers & aspirants solve real world business problems & brands to hire PMs from PM School. Join our clan to learn & grow together."
        canonicalLink="https://pmschool.io/product-challenges"
      />
      {/* {loading ?
				<Loader />
				: */}
      <>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader />
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <LandingBanner />
          <LiveChallenges challenges={liveChallenges || []} />
          <Logo />
          <PastChallenges
            challenges={pastChallenges || []}
            filters={pastFilters || []}
          />
        </div>
      </>
      {/* } */}
    </>
  );
};

export default ChallengesListing;

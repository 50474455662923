import React from "react";
import "./index.scss";
import { assets } from "../../utils/assets";

const PmRunAchieve = ({ userName }) => {
    const InstagramIcon = assets['Instagram-black'];
    const CupImg = assets['achievement-cup'];
    const LinkedinIcon = assets['linkedIn-black'];
    const LogoBlack = assets['logo-black'];
    const TwitterIcon = assets['twitter-black'];
    return (
        <div className="achievement__wrapper">
            <div className="achievement__left">
                <img loading="lazy" src={CupImg} alt="" className="clap__img" />
                <img loading="lazy" className="pm__logo" src={LogoBlack} alt="" />
                <h2>Congratulations {userName}!</h2>
                <p>
                    <span>Days filled with dedication and remarkable achievements!</span>
                    <span>Completed my PM Run with PM School!</span>
                </p>
                <p>
                    <span>Grateful for the knowledge gained and ready for what’s next!</span>
                    <span>#PMRun #ProductManagement #PMschool</span>
                </p>
                <div className="divider"></div>
                <div className="social__share">
                    <span className="share">Share as your achievement</span>
                    <div className="social__icons">
                        <a href="">
                            <img loading="lazy" src={LinkedinIcon} alt="" />
                        </a>
                        <a href="">
                            <img loading="lazy" src={InstagramIcon} alt="" />
                        </a>
                        <a href="">
                            <img loading="lazy" src={TwitterIcon} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="achievement__right">
                <div className="offer__badge">
                    <span className="percent">30%</span>
                    <span className="text">off</span>
                </div>
                <h2>Avail your reward. You have earned it!</h2>
                <p>
                    Use the following coupon code for availing exciting discount on our Micro
                    Courses @ PM School.
                </p>
                <div className="reference__code">
                    <span>A B C D E F G H</span>
                </div>
                <a href="">Check out our Micro Courses @ PM School</a>
            </div>
        </div>
    );
};

export default PmRunAchieve;

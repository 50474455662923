import React, { useEffect } from "react";
import $ from "jquery";
import { assets } from "../../utils/assets";

const MentorsSection = () => {
  const laurin=assets['laurin-thum'];
  const kushal=assets['kushal-thumb'];
  const lavanya=assets['lavanya-thumb'];
  const nikhita=assets['nikhita-thumb'];
  const ankit=assets['ankit-thumb'];
  const khanjan=assets['khanjan-thumb'];
  const piyush=assets['piyush-lahoti'];
  const mentor1=assets['mentor-img-1'];
  const mentor3=assets['mentor-img-3'];
  const fave = assets['fave-logo'];
  const parking=assets['parking-logo'];
  const uber=assets['uber-logo'];
  const dream=assets['dream-logo'];
  const shop=assets['shop-logo'];
  const myntra=assets['deliveryhero'];
  const epifi=assets['epifi'];
  const paypal=assets['paypal-logo'];
  const games2=assets['Swiggy'];
  const google2=assets['google2']
  // window.call_slider()
  useEffect(() => {
    // $(document).ready(function () {
    window.call_slider();
    // })
  }, []);
  return (
    <section className="for-mentor-section">
      <section className="our-mentors">
        <div className="our-mentors-container">
          <div className="our-mentors-title">
            <h2>Our Mentors</h2>
          </div>
          <div className="mentors-slider-wrapper">
            <div className="mentors-slider">
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={laurin} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Laurin</h3>
                  <p>Product Owner at Revolut (Fintech)</p>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={kushal} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Kushaal</h3>
                  <p>Cofounder at Proximity Works, ex GMP</p>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={lavanya} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Lavanya</h3>
                  <p>Product Manager at Google</p>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={mentor1} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Nihar</h3>
                  <p>Senior Product Mgr at DialPad</p>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={mentor3} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Nikunj</h3>
                  <p>AVP at HDFC Life, ex Swiggy</p>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={piyush} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Piyush Lahoti</h3>
                  <p>Product Manager at EpiFi</p>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={nikhita} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Nikhita</h3>
                  <p>Principal Product manager at Delivery Hero</p>
                </div>
              </div>
              {/* <div className="slide-container">
                <div className="mentors-thumb">
                  <img src={Jitendra} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Jitendra</h3>
                  <p>Senior Product manager at Dream11</p>
                </div>
              </div>  */}
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={ankit} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Ankit</h3>
                  <p>Cofounder at PM School, ex Games24x7</p>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <img loading="lazy" src={khanjan} alt="images" />
                </div>
                <div className="mentors-content">
                  <h3>Khanjan</h3>
                  <p>Product at Booking.com</p>
                </div>
              </div>
            </div>
            <div className="mentors-dslider">
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={fave} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={parking} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={google2} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={uber} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={games2} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={epifi} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={myntra} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img src={dream} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div> */}
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={shop} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={paypal} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default MentorsSection;

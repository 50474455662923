import React from 'react'
import ContentLoader from 'react-content-loader'
import "./landing-banner/landing-banner.scss";

const bannerLoader = () => (
    <ContentLoader height={'100%'} width={'100%'} speed={1} backgroundColor={'#404142'} foregroundColor={'#424242'}>
        <rect x="10%" y="10%" rx="2" ry="2" width="60%" height="20%" />

        <rect x="10%" y="35%" rx="2" ry="2" width="60%" height="4%" />
        <rect x="10%" y="41%" rx="2" ry="2" width="60%" height="4%" />

        <rect x="10%" y="55%" rx="2" ry="2" width="30%" height="6%" />
    </ContentLoader>
)

export const Loader = () => (
    <div className='pm-challange-banner-main loader'>
        <div className='pm-challange-bg-1'>
            {bannerLoader()}
        </div>
        <div className='pm-challange-bg-2'>
            {bannerLoader()}
        </div>
    </div>
)
import { Tab, Nav } from "react-bootstrap";
import "./about-challenges.scss";
import { useState, useEffect } from 'react';
//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';
import {
  getChallengeDetailsRequest
} from '../../../api/ApiRequest';
const data = [
  // {
  //   tabName: "Challenge DetailS",
  //   title: "Details about the challenge",
  //   subTitle: "Customer success team at Google",
  //   description:
  //     "At Google, we want to create a customer centric culture, where customer experience comes first and foremost. To kickstart this, for the first time, we have decided to create a customer success team (CX Team) with the objective of improving customer satisfaction and experience of Google Pay",
  //   responsibility: "You as a PM is responsible for",
  //   responsibilityItems: [
  //     {
  //       item: "Enabling this team to interact with users who can come to us through several different channels",
  //     },
  //     {
  //       item: "Customer communication in the whole query resolution lifecycle",
  //     },
  //     {
  //       item: "Optimise bandwidth of the CX team so that we serve more with less",
  //     },
  //   ],
  // },
  {
    tabName: "Evaluation Criteria",
    title: "Evaluation Criteria",
    subTitle: "100 points challenge",
    description:
      "Our evaluations provide structured feedback to students about what they’re doing right and what could be improved. Please state any assumptions you make. ",
    responsibility: "POINTS bREAK-UP",
    responsibilityItems: [
      {
        item: "Enabling this team to interact with users who can come to us through several different channels",
        points: "20 Points",
      },
      {
        item: "Customer communication in the whole query resolution lifecycle",
        points: "20 Points",
      },
      {
        item: "Optimise bandwidth of the CX team so that we serve more with less",
        points: "20 Points",
      },
    ],
  },
];
const Past_challenge_about = (props) => {
  const [challenge, setChallengeData] = useState();
  useEffect(() => {
    if (props.challenge) {

      setChallengeData(props.challenge);
    }
  }, [])

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return value;
  }

  return (
    <Tab.Container defaultActiveKey="item_0">
      <div className="d-block  d-md-flex tab-section">
        {/* <div className="tab-btn-container">
          <Nav variant="pills" className="flex-row flex-md-column flex-nowrap">
            {data.map((item, idx) => {
              return (
                <Nav.Item key={idx}>
                  <Nav.Link className={`tab-item`} eventKey={`item_${idx}`}>
                    {item.tabName}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </div> */}
        {challenge && <div>
          <Tab.Content className="h-100">
            {data.map((item, idx) => {
              return (
                <Tab.Pane eventKey={`item_${idx}`} className="h-100" key={idx}>
                  <div className="tab-details">
                    <span className="title">{item.title}</span>
                    {
                      (idx == 1) && <span><h3 className="sub-title">{challenge.headings && challenge.headings[0]}</h3>
                        <p className="description">{challenge.headings && challenge.headings[1]}</p>
                      </span>
                    }
                    {
                      (idx == 0) && <span><h3 className="sub-title">{challenge.points && challenge.points + ' points challenge'}</h3>
                        <p className="description">{challenge.evaluation_criteria_text && challenge.evaluation_criteria_text}</p>
                      </span>
                    }

                  </div>

                  {/* <Tab.Pane eventKey={`item_${idx}`} className="h-100" key={idx}>
                  <div className="tab-details">
                    <span className="title">{item.title}</span>
                    <h3 className="sub-title">{item.subTitle}</h3>
                    <p className="description">{item.description}</p>
                  </div>
                  <div className="tab-details">
                    <span className="responsibility">
                      {item.responsibility}
                    </span>
                    <ul className="listWrapper">
                      {item.responsibilityItems.map((ri, i) => {
                        return (
                          <li
                            className={`listItem ${idx === 1 ? " bold" : ""}`}
                            key={i}
                          >
                            {ri.item}
                            {ri.points ? (
                              <span className="itemPoints">{ri.points}</span>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Tab.Pane> */}

                  <div className="tab-details">
                    <span className="responsibility">
                      {/* {
                        1 && "You as a PM is responsible for"
                      } */}
                      {
                        idx == 0 && "POINTS BREAK-UP"
                      }
                    </span>
                    {/* {(1) && challenge &&
                      <ul className="listWrapper" style={{ listStyle: 'none' }}>
                        <li
                          className={`listItem`}
                        >
                          {challenge.detail_text}
                        </li>
                      </ul>

                    } */}
                    {(idx == 0) && challenge &&
                      <ul className="listWrapper">
                        {challenge.categories_listing.map((ri, i) => {
                          return (
                            <li
                              className={`listItem ${idx === 1 ? " bold" : ""}`}
                              key={i}
                            >
                              {ri.title}
                              {ri.out_of_score ? (
                                <span className="itemPoints">{ri.out_of_score}</span>
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    }
                  </div>
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </div>}
      </div>
    </Tab.Container>
  );
}

export default Past_challenge_about;

import React from "react";
import { assets } from "../../utils/assets";

const Mentors = () => {
  const Image1=assets['img1'];
  const Image2=assets['img2'];
  const Image3=assets['img3'];
  const Image4=assets['img4']
  return (
    <section className="industries-mentors">
      <div className="content-wrapper text-center">
        <h3 className="title">
          An 8 week hands-on product manager certification program from the
          industry’s seasoned mentors
        </h3>
        <a
          href="/product-management-course"
          style={{ textDecoration: "none", color: "unset" }}
          rel="noreferrer"
        >
          <button className="btn-green">Explore the PM Course</button>
        </a>
      </div>
      <div className="img-wrapper d-flex justify-content-center row no-gutters">
        <div className="item-wrapper col-6 col-md-3 text-center">
          <img
            loading="lazy"
            className="img-item img-fluid"
            src={Image1}
            alt="Online Product Management Certification Program"
          />
          <h3 className="item-title">Live Interactive Classes</h3>
        </div>
        <div className="item-wrapper col-6 col-md-3 text-center">
          <img
            loading="lazy"
            className="img-item img-fluid"
            src={Image2}
            alt="Best Product Management Certification Program"
          />
          <h3 className="item-title">Industry Case-studies</h3>
        </div>
        <div className="item-wrapper col-6 col-md-3 text-center">
          <img
            loading="lazy"
            className="img-item img-fluid"
            src={Image3}
            alt="Mentorship and Mock Interview Style Learning"
          />
          <h3 className="item-title">Mentorship & mock interviews</h3>
        </div>
        <div className="item-wrapper col-6 col-md-3 text-center">
          <img
            loading="lazy"
            className="img-item img-fluid"
            src={Image4}
            alt="Lifetime Membership to PM School Community"
          />
          <h3 className="item-title">Lifetime Membership</h3>
        </div>
      </div>
    </section>
  );
};

export default Mentors;

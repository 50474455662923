import React, { useEffect, useState } from "react";
import Banner from "./banner";
import DayRun from "./day-run";
import "./index.scss";
import Learning from "./learning";
import Loader from "../comon-component/Loader/Loader";

const PmRun = ({
 showLoginModal,
 changeLoginState,
 isLoggedIn,
 setRedirectUrl,
}) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
          }, 3000);
      }, []);
 return (
    <>
    <div style={{ display: loading ? "" : "none" }}>
    <Loader />
  </div>
  <div className="pmRun__page" style={{ display: loading ? "none" : "" }}>
   <Banner />
   <DayRun
    showLoginModal={showLoginModal}
    isLoggedIn={isLoggedIn}
    setRedirectUrl={setRedirectUrl}
   />
   <Learning />
   {/* <FAQ /> */}
  </div>
  </>
 );
};

export default PmRun;

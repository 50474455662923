import React, { useEffect } from "react";
import { assets } from "../../utils/assets";

const Learning = () => {
  const one = assets['one'];
  const two = assets['two'];
  const three = assets['three'];
  const four = assets['four'];
  const five = assets['five'];
  const six = assets['six'];
  const seven = assets['seven'];
  const sliderImg = assets['slider-images'];
  const slider = assets['slider-planning'];
  useEffect(() => {
    window.call_learning_slide();
  }, []);
  return (
    <section className="learning-experience-wrap learning-experience-wrap-pmRun">
      <div className="container-wrap">
        <div className="slider-title">
          <h4>Put your abilities to test</h4>
          <p>
            Put your product management skills to the test and conquer the PM
            Run contest with your knowledge and expertise.
          </p>
        </div>
        <div className="slider-wrapper" id="slick-1">
          <ul className="slider-nav-course">
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link active"
                data-slick-index="0"
              >
                <span className="icon">
                  <img src={slider} align="icon images" />
                </span>
                Introduction to Product management role
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="1"
              >
                <span className="icon">
                  <img src={slider} align="icon images" />
                </span>
                UX / Design for Product managers
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="2"
              >
                <span className="icon">
                  <img src={slider} align="icon images" />
                </span>
                PRDs & Agile processes
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="3"
              >
                <span className="icon">
                  <img src={slider} align="icon images" />
                </span>
                Acquisition & Retention
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="4"
              >
                <span className="icon">
                  <img src={slider} align="icon images" />
                </span>
                Analytics & Experimentation
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="5"
              >
                <span className="icon">
                  <img src={slider} align="icon images" />
                </span>
                Problem definition & Market research
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="6"
              >
                <span className="icon">
                  <img src={slider} align="icon images" />
                </span>
                Technology for PMs
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
          </ul>

          <div className="learning-experience-slider">
            <div className="learning-slider">
              <div className="slide">
                <div className="slid-images">
                  <img src={one} alt="Primer on All things Technology" />
                </div>
                <div className="slid-content">
                  <h2>Introduction to Product management role</h2>
                  <p>
                    How does a Product manager oversee the development and
                    launch of products, coordinating teams and aligning business
                    goals.
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={two}
                    alt="Problem Framing and Building for Success"
                  />
                </div>
                <div className="slid-content">
                  <h2>UX / Design for Product managers</h2>
                  <p>
                    How do Product managers collaborate with designers to create
                    intuitive and user-centric product experiences.
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={three}
                    alt="Achieve Analytical Skill Set through Our PM Course"
                  />
                </div>
                <div className="slid-content">
                  <h2>PRDs & Agile processes</h2>
                  <p>
                    How to write PRDs (Product Requirement Documents) and work
                    with Agile methodologies to plan, prioritize, and iterate
                    product development.
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={four}
                    alt="PM Course to Help with Design Thinking"
                  />
                </div>
                <div className="slid-content">
                  <h2>Acquisition & Retention</h2>
                  <p>
                    Learn how product managers drive strategies to acquire and
                    retain customers, leveraging data and user insights for
                    effective growth.
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={five}
                    alt="Learn Product Growth Levers within Our PM Course"
                  />
                </div>
                <div className="slid-content">
                  <h2>
                    Analytics & <br />
                    Experimentation
                  </h2>
                  <p>
                    How to identify user problems, conduct market research, and
                    validate product ideas for successful development.
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={six}
                    alt="Acquire knowledge about OKRs, Scrum Planning, Sprints etc."
                  />
                </div>
                <div className="slid-content">
                  <h2>Problem definition & Market research</h2>
                  <p>
                    How to identify user problems, conduct market research, and
                    validate product ideas for successful development.
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={seven}
                    alt="Acquire knowledge about OKRs, Scrum Planning, Sprints etc."
                  />
                </div>
                <div className="slid-content">
                  <h2>Technology for PMs</h2>
                  <p>
                    How to leverage technology, and work or enable engineering
                    stakeholders, to streamline workflows and drive product
                    success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Learning;

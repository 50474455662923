import React from "react";
import "./index.scss";
import BrainIcon from "../../../assets/brain.svg";

const StudentPathCard = ({ img, text }) => {
  return (
    <div className="studentPath__card">
      <div className="wrapper">
        <img loading="lazy" src={img} alt="" />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default StudentPathCard;

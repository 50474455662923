import React, { useEffect } from "react";
import { assets } from "../../utils/assets";

const Learning = () => {
  const virali = assets['Virali Parekh'];
  const sai = assets['Sai Ruthvik'];
  const aga = assets['user'];
  const karish = assets['Karishma Kapoor'];
  const anuj = assets['Anuj Hegde'];
  const ray = assets['Ravyant Gupta'];
  useEffect(() => {
    window.call_learning_slide();
  }, []);
  return (
    <section className="learning-experience-wrap learning-experience-wrap-2">
      <div className="container-wrap">
        <div className="slider-title">
          <h4>Stories by our students</h4>
        </div>
        <div className="slider-wrapper" id="slick-1">
          <div className="slider-title">
            <h4>Stories by our students</h4>
          </div>
          <ul className="slider-nav-course">
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link active"
                data-slick-index="0"
              >
                <span className="icon">
                  <img loading="lazy" src={virali} align="icon images" />
                </span>
                Virali Parekh
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="1"
              >
                <span className="icon">
                  <img loading="lazy" src={sai} align="icon images" />
                </span>
                Sai Ruthvik
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="2"
              >
                <span className="icon">
                  <img loading="lazy" src={aga} align="icon images" />
                </span>
                Agastya Tiwari
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="3"
              >
                <span className="icon">
                  <img loading="lazy" src={karish} align="icon images" />
                </span>
                Karishma Kapoor
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="4"
              >
                <span className="icon">
                  <img loading="lazy" src={anuj} align="icon images" />
                </span>
                Anuj Hegde
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="5"
              >
                <span className="icon">
                  <img loading="lazy" src={ray} align="icon images" />
                </span>
                Rayvant Gupta
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
          </ul>

          <div className="learning-experience-slider">
            <div className="learning-slider">
              <div className="slide">
                <div className="slid-content">
                  <p>
                    <span>I</span>stumbled on PM School weekend challenges when
                    I was looking for a way to get into a Product role. By
                    winning the weekend challenge, I got a chance for a mock
                    interview with Nikunj. Later in the year, I decided to join
                    the PM School cohort.
                    <br />
                    <br />
                    The program has well-structured interactive sessions, 1-1
                    mentorship program, and an awesome product community.
                    <br />
                    <br />
                    I want to thank Ankit, who suggested me to create a
                    portfolio for the case studies and referred me to a lot of
                    company for a product role. Nikunj & Kushaal for clearing
                    the doubts during the live session. Shantanu for evaluating
                    assignments for every session and giving detailed feedback.
                    <br />
                    <br />
                    Special thanks to my mentor Nikhil, who helped me to
                    customize the mentorship experience based on my needs and
                    areas to improve on.
                  </p>
                  <a
                    href="https://www.linkedin.com/posts/virali-parekh_mentorship-productmanager-productmanagement-activity-6777854638772260864-RJxm/"
                    target="_blank"
                    rel="noreferrer"
                    className="button-hover"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Read More <i className="fa fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
              <div className="slide">
                <div className="slid-content">
                  <p>
                    <span>I</span> have completed Product Management course from
                    PM School with great learnings. This helped me to an
                    effective switch in job to product management domain. The
                    one to one mentorship provided at PM school is very helpful.
                    PM school has it's own seperate placement cell which is very
                    helpful to find you a right job in product domain.
                    <br />
                    <br />
                    Thankyou Ankit Bareja, Nikunj Sharma, Kushaal Devanahalli
                    and Harshita Singla for all the help.
                    <br />
                    <br />
                    This post is not just a testimonial but a call to all the
                    folks out there who are looking to pursue a career in
                    product management domain. Admissions for next cohort at PM
                    School are open. Now is your chance.
                    <br />
                    <br />
                    #productmanagement #productcareer
                  </p>
                  <a
                    href="https://www.linkedin.com/posts/sairuthvik_productmanagement-productcareer-activity-6933709451010945024-gfSG/?utm_source=share&utm_medium=member_desktop"
                    target="_blank"
                    rel="noreferrer"
                    className="button-hover"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Read More <i className="fa fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
              <div className="slide">
                <div className="slid-content">
                  <p>
                    <span>I</span>n the last couple of weeks when I was looking
                    to explore the #productmanagement space, I felt the need to
                    join a program where
                    <br />
                    <br />
                    a) I will get my basics in place
                    <br />
                    b) Get 1-on-1 mentorship
                    <br />
                    c) Be a part of an awesome product community
                    <br />
                    <br />
                    PM School ticked all these boxes for me.
                    <br />
                    <br />
                    In my first week of the program I had a 1-on-1 session with
                    Nikunj and Ankit; I realized they genuinely cared about the
                    cohort members and wanted to help them in every possible
                    way. And yes, they have been there throughout- clearing the
                    silliest of doubts, guiding and motivating me. Coming to the
                    course; it was well structured and the detailed feedbacks
                    provided by Shantanu on the Product case assignments were
                    invaluable. Kushaal helped us with the much needed strategy
                    around interviews and Harmeet was always available to help
                    me understand the nuances of the job.
                    <br />
                    <br />
                    All this helped me crack a Product role at Sportskeeda.
                    <br />
                    <br />
                    Thank you for everything guys! Y'all are awesome :D
                    <br />
                    <br />
                    Anyone looking to transition into a #productmanager role or
                    just wanting to explore what this craze about a #PM role is,
                    should definitely book their seat for PM School's May
                    Cohort!
                  </p>
                  <a
                    href="https://www.linkedin.com/posts/agastyatiwari9_productmanagement-productmanager-pm-activity-6773111873786269697-3Dd3/"
                    target="_blank"
                    rel="noreferrer"
                    className="button-hover"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Read More <i className="fa fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
              <div className="slide">
                <div className="slid-content">
                  <p>
                    <span>T</span>here are secret opportunities hidden inside
                    every failure.
                    <br />I am happy to share that I joined BYJU's as a Product
                    Manager. I am beyond grateful for everything that #pmschool
                    community has poured into me! The learnings I've gained and
                    the wisdom I've collected will stay with me forever. I would
                    like to thank to the entire team of PMSchool for creating
                    such an intensive cohort based curriculum that gives such a
                    holistic view of Product Management. I met some incredible
                    people Prashanth Bhaskaran, Kriti Sharma and learned a ton
                    from my mentors Ankit Bareja, Nikunj S., Manish Soni,
                    Nikhita Aron in my cohort. Also, I would like to thank
                    Shravan Tickoo for creating and delivering such an amazing
                    content that learning product management becomes fun. It is
                    fulfilling to be a part of such an amazing Product team at
                    BYJU'S. Thank you Pranathi Murthy who supported throughout
                    and helped me grow in every possible way.
                    <br />
                    <br />
                    Cheers to the new beginning!!
                  </p>
                  <a
                    href="https://www.linkedin.com/posts/karishma-kapoor-b8a68670_pmschool-productmanagement-activity-6860833130094428160-cqf9/"
                    target="_blank"
                    rel="noreferrer"
                    className="button-hover"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Read More <i className="fa fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
              <div className="slide">
                <div className="slid-content">
                  <p>
                    <span>I</span> recently completed a 2 month Product
                    Management program at PM School. This extensive course
                    covered topics right from what a PM does, problem-solving,
                    analytics to how one should prepare for an interview. I
                    would like to thank Ankit Bareja, Nikunj S. and Kushaal
                    Devanahalli for this amazing course and their support
                    before, during, and even after the course. <br />
                    <br />
                    Here are few reasons WHY one should consider the PMSchool's
                    course - 8 live modules covered by experts in the field
                    (Anand Madanapalle Sridhar, Paritosh Singh, Nikunj S., Asis
                    Panda, Piyush Lahoti, Nihar Sawant, Laurin Lukas Stahl,
                    Sonupam Kaur) - top-notch case assignments, evaluated by
                    TAs( Shantanu Korada, Debarati Bhattacharjee) who provide
                    personalized feedback - dedicated PM mentors who help with
                    resume review, mock interviews, and more (Aashish Sawhney) -
                    community of PMs and aspiring PMs always ready to help one
                    grow A special thanks to Ankit Bareja for his unconditional
                    support whenever I felt stuck and Anand Madanapalle Sridhar
                    for his practical guidance throughout the interview process!
                    <br />
                  </p>
                  <a
                    href="https://www.linkedin.com/posts/activity-6834739203537010688-Oune/"
                    target="_blank"
                    rel="noreferrer"
                    className="button-hover"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Read More <i className="fa fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
              <div className="slide">
                <div className="slid-content">
                  <p>
                    <span>I</span> recently completed the Live Program at PM
                    School. Would recommend it to anyone looking to grasp the
                    finer nuances of product management and build further on it.
                    The 8-week program comprises of live lectures from industry
                    experts and case studies which simulate actual problems .
                    Add to this the 1-on-1 mentorship, mock-interviews,
                    assignment feedback & an access to PM community and its
                    support. All in all, I felt home and was able to make the
                    most out of my time.
                    <br />
                    <br />
                    For the course curriculum and structure please check out :
                    https://lnkd.in/gxzhrWc9
                    <br />
                    <br />A big shoutout to the PM school team : Ankit Bareja,
                    Kushaal Devanahalli, Nikunj S., Debarati Bhattacharjee,
                    Shantanu Korada, Chushul Suri thank you for all the
                    unconditional support and guidance.
                  </p>
                  <a
                    href="https://www.linkedin.com/posts/rayvantgupta_reinvent-your-career-with-a-course-at-pm-activity-6848624657713774592-IwUN/"
                    target="_blank"
                    rel="noreferrer"
                    className="button-hover"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    Read More <i className="fa fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Learning;

import { Modal } from "react-bootstrap";
// import { useState } from "react";
import { useState } from "react";
import FilesListScreen from "./files-list";
import ForgotPasswordScreen from "./forgot-password";
import LoginScreen from "./login-modal";
import "./modal-pages.scss";
import ResetPasswordScreen from "./reset-password";
import ThankYou from "./thank-you";
import UpoadDrive from "./upload-drive";
import UploadFilesScreen from "./upload-files";
function ModalPages(props) {
 const [key, setKey] = useState("login");
 return (
  <Modal
   className="modal-container"
   show={props.show}
   backdrop="static"
   keyboard={false}
   centered
   size="xl">
   <Modal.Body>
    {props.type === "uploadDrive" ||
    props.type === "uploadModal" ||
    props.type === "fileList" ||
    props.type === "thank-you" ? null : key === "login" ? (
     <div className="modal-img-section">
      <span className="caption">
       Augment your Product Management Skills with PM School
      </span>
     </div>
    ) : (
     <div className="modal-img-section signup">
      <span className="caption">
       You’re on your first step to becoming a Product Manager
      </span>
     </div>
    )}
    {props.type === "login" ? (
     <LoginScreen
      tabKey={key}
      setTabKey={setKey}
      tabScreen="login"
      onChangeView={props.onModalChange}
      isLoggedIn={props.isLoggedIn}
      changeLoginState={props.changeLoginState}
      redirectUrl={props.redirectUrl}
     />
    ) : props.type === "forgot-password" ? (
     <ForgotPasswordScreen
      onChangeView={props.onModalChange}
      onSetUserEmail={props.setUserEmail}
     />
    ) : props.type === "reset-password" ? (
     <ResetPasswordScreen
      email={props.userEmail}
      onChangeView={props.onModalChange}
     />
    ) : props.type === "uploadModal" ? (
     <UploadFilesScreen
      challengeId={props.challengeId}
      onSetFile={props.setFile}
      onChangeView={props.onModalChange}
     />
    ) : props.type === "fileList" ? (
     <FilesListScreen
      challengeId={props.challengeId}
      selectedFile={props.selectedFile}
      onChangeView={props.onModalChange}
     />
    ) : props.type === "thank-you" ? (
     <ThankYou challengeId={props.challengeId} />
    ) : props.type === "uploadDrive" ? (
     <UpoadDrive
      entryId={props.entryId}
      onChangeView={props.onModalChange}
      linkUrl={props.linkUrl}
     />
    ) : null}
    <div
     className="btn-close"
     onClick={() => {
      props.onModalChange(props.type, false);
     }}></div>
   </Modal.Body>
  </Modal>
 );
}
export default ModalPages;

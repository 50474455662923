import React from "react";
import { assets } from "../../utils/assets";

const Banner = () => {
    const CalenderIcon=assets['noun-calender-2643697'];
    const ConsistencyIcon=assets['noun-consistency-5265752']
 return (
  <section className="banner-wrap pm__run">
   <div className="banner-images">
    <div className="banner-content">
     <div className="container-wrap">
      <h1>What is PM Run?</h1>
      <p className="description">
       Answer a quiz question about the PM Topics every day, and try to maintain
       a streak! This is a chance to understand how well, do you know the PM
       Basics. Share the results with your friends and earn rewards!
      </p>
      <div className="topic__list">
       <div className="topic">
        <img loading="lazy" src={ConsistencyIcon} alt="" />
        <p>Stay Consistent with your Learning</p>
       </div>
       <div className="topic">
        <img loading="lazy" src={CalenderIcon} alt="" />
        <p>Track your Progress</p>
       </div>
      </div>
     </div>
    </div>
   </div>
  </section>
 );
};

export default Banner;

export const assets = {
  1024: `${process.env.REACT_APP_S3_SERVER_URL}/1024.webp`,
  bayjus: `${process.env.REACT_APP_S3_SERVER_URL}/bayjus.webp`,
  "floating-facebook": `${process.env.REACT_APP_S3_SERVER_URL}/floating-facebook.svg`,
  mpl: `${process.env.REACT_APP_S3_SERVER_URL}/mpl.webp`,
  "linkedin-green": `${process.env.REACT_APP_S3_SERVER_URL}/linkedin-green.svg`,
  "khanjan-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/khanjan-thumb.webp`,
  "story-nav-7": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-7.webp`,
  "play-video": `${process.env.REACT_APP_S3_SERVER_URL}/play-video.svg`,
  "shop-logo": `${process.env.REACT_APP_S3_SERVER_URL}/shop-logo.webp`,
  375: `${process.env.REACT_APP_S3_SERVER_URL}/375.webp`,
  "story-nav-6": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-6.webp`,
  linkadd: `${process.env.REACT_APP_S3_SERVER_URL}/linkadd.webp`,
  "about-us-banner-new": `${process.env.REACT_APP_S3_SERVER_URL}/about-us-banner-new.webp`,
  freecharge: `${process.env.REACT_APP_S3_SERVER_URL}/freecharge.webp`,
  "overview-tile-2-old": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-2-old.webp`,
  "suggestion-thumb-1": `${process.env.REACT_APP_S3_SERVER_URL}/suggestion-thumb-1.webp`,
  "time-icon": `${process.env.REACT_APP_S3_SERVER_URL}/time-icon.svg`,
  "resume-share": `${process.env.REACT_APP_S3_SERVER_URL}/resume-share.webp`,
  "Instagram-green": `${process.env.REACT_APP_S3_SERVER_URL}/Instagram-green.svg`,
  "empty-challenge-list": `${process.env.REACT_APP_S3_SERVER_URL}/empty-challenge-list.webp`,
  "icons8-correct": `${process.env.REACT_APP_S3_SERVER_URL}/icons8-correct.svg--`,
  "interview-preparation": `${process.env.REACT_APP_S3_SERVER_URL}/interview-preparation.webp`,
  highradius: `${process.env.REACT_APP_S3_SERVER_URL}/highradius.webp`,
  "suggestion-thumb-3": `${process.env.REACT_APP_S3_SERVER_URL}/suggestion-thumb-3.webp`,
  "pm-run-banner": `${process.env.REACT_APP_S3_SERVER_URL}/pm-run-banner.webp`,
  "laurin-thum": `${process.env.REACT_APP_S3_SERVER_URL}/laurin-thum.webp`,
  "challenges-dots-img": `${process.env.REACT_APP_S3_SERVER_URL}/challenges-dots-img.svg`,
  "story-nav-4": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-4.webp`,
  "story-nav-5": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-5.webp`,
  "suggestion-thumb-2": `${process.env.REACT_APP_S3_SERVER_URL}/suggestion-thumb-2.webp`,
  "oyo-person": `${process.env.REACT_APP_S3_SERVER_URL}/oyo-person.webp`,
  "ninjacart-person": `${process.env.REACT_APP_S3_SERVER_URL}/ninjacart-person.webp`,
  ananya: `${process.env.REACT_APP_S3_SERVER_URL}/ananya.webp`,
  "ebook-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/ebook-resource-tab.webp`,
  "pm-challange-b3": `${process.env.REACT_APP_S3_SERVER_URL}/pm-challange-b3.webp`,
  yellowbanner: `${process.env.REACT_APP_S3_SERVER_URL}/yellowbanner.svg`,
  "past-feedback-like": `${process.env.REACT_APP_S3_SERVER_URL}/past-feedback-like.webp`,
  favicon: `${process.env.REACT_APP_S3_SERVER_URL}/favicon.ico`,
  "byjus-round": `${process.env.REACT_APP_S3_SERVER_URL}/byjus-round.webp`,
  "noun-calender-2643697": `${process.env.REACT_APP_S3_SERVER_URL}/noun-calender-2643697.svg`,
  "btn-right-arrow-png": `${process.env.REACT_APP_S3_SERVER_URL}/btn-right-arrow.webp`,
  "week-testimonial-img": `${process.env.REACT_APP_S3_SERVER_URL}/week-testimonial-img.webp`,
  "story-nav-1": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-1.webp`,
  "banner768-1": `${process.env.REACT_APP_S3_SERVER_URL}/banner768-1.webp`,
  "retention-focus": `${process.env.REACT_APP_S3_SERVER_URL}/retention-focus.webp`,
  three: `${process.env.REACT_APP_S3_SERVER_URL}/three.webp`,
  "mobilebanner-img": `${process.env.REACT_APP_S3_SERVER_URL}/mobilebanner-img.webp`,
  ipad: `${process.env.REACT_APP_S3_SERVER_URL}/ipad.zip`,
  visibility: `${process.env.REACT_APP_S3_SERVER_URL}/visibility.svg`,
  "paytm-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/paytm-resource-tab.webp`,
  "iped-banner": `${process.env.REACT_APP_S3_SERVER_URL}/iped-banner.webp`,
  PMXTeacher: `${process.env.REACT_APP_S3_SERVER_URL}/PMXTeacher.webp`,
  student1: `${process.env.REACT_APP_S3_SERVER_URL}/student1.webp`,
  "Deepak-Doguparti": `${process.env.REACT_APP_S3_SERVER_URL}/Deepak-Doguparti.webp`,
  "plus-icon": `${process.env.REACT_APP_S3_SERVER_URL}/plus-icon.svg`,
  "btn-share-icon": `${process.env.REACT_APP_S3_SERVER_URL}/btn-share-icon.svg`,
  "badge-icon": `${process.env.REACT_APP_S3_SERVER_URL}/badge-icon.svg`,
  noun_Login: `${process.env.REACT_APP_S3_SERVER_URL}/noun_Login.svg`,
  "arrow-right": `${process.env.REACT_APP_S3_SERVER_URL}/arrow-right.webp`,
  "problem-framing": `${process.env.REACT_APP_S3_SERVER_URL}/problem-framing.webp`,
  brain: `${process.env.REACT_APP_S3_SERVER_URL}/brain.svg`,
  "story-nav-2": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-2.webp`,
  "story-nav-3": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-3.webp`,
  Sagar: `${process.env.REACT_APP_S3_SERVER_URL}/Sagar.webp`,
  "banner768-2": `${process.env.REACT_APP_S3_SERVER_URL}/banner768-2.webp`,
  "header-mobile-logo": `${process.env.REACT_APP_S3_SERVER_URL}/header-mobile-logo.svg`,
  "google-logo-png": `${process.env.REACT_APP_S3_SERVER_URL}/google-logo.webp`,
  "cycle-1": `${process.env.REACT_APP_S3_SERVER_URL}/cycle-1.webp`,
  "green-globe-left": `${process.env.REACT_APP_S3_SERVER_URL}/green-globe-left.svg`,
  "certification-icon": `${process.env.REACT_APP_S3_SERVER_URL}/certification-icon.svg`,
  "overview-tile-3-old": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-3-old.webp`,
  games2: `${process.env.REACT_APP_S3_SERVER_URL}/games2.webp`,
  "story-img": `${process.env.REACT_APP_S3_SERVER_URL}/story-img.webp`,
  "badge-5": `${process.env.REACT_APP_S3_SERVER_URL}/badge-5.webp`,
  "filter-icon": `${process.env.REACT_APP_S3_SERVER_URL}/filter-icon.svg`,
  "Group 25367": `${process.env.REACT_APP_S3_SERVER_URL}/Group 25367.webp`,
  repmrunadaptations: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations.zip`,
  "login-icon": `${process.env.REACT_APP_S3_SERVER_URL}/login-icon.svg`,
  lock: `${process.env.REACT_APP_S3_SERVER_URL}/lock.svg`,
  varsha: `${process.env.REACT_APP_S3_SERVER_URL}/varsha.webp`,
  mobile: `${process.env.REACT_APP_S3_SERVER_URL}/mobile.zip`,
  "story-img-7": `${process.env.REACT_APP_S3_SERVER_URL}/story-img-7.webp`,
  "btn-right-nav": `${process.env.REACT_APP_S3_SERVER_URL}/btn-right-nav.svg`,
  "ninja-round": `${process.env.REACT_APP_S3_SERVER_URL}/ninja-round.webp`,
  "logo-icon": `${process.env.REACT_APP_S3_SERVER_URL}/logo-icon.svg`,
  "week-icon-1": `${process.env.REACT_APP_S3_SERVER_URL}/week-icon-1.svg`,
  "testimonial-icon": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial-icon.svg`,
  "google-logo": `${process.env.REACT_APP_S3_SERVER_URL}/google-logo.svg`,
  "carousel-left-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/carousel-left-resource-tab.webp`,
  "pm-run-banner-414": `${process.env.REACT_APP_S3_SERVER_URL}/pm-run-banner-414.webp`,
  "Anu-Sharma": `${process.env.REACT_APP_S3_SERVER_URL}/Anu-Sharma.webp`,
  "story-img-6": `${process.env.REACT_APP_S3_SERVER_URL}/story-img-6.webp`,
  "case-study": `${process.env.REACT_APP_S3_SERVER_URL}/case-study.webp`,
  Prithu: `${process.env.REACT_APP_S3_SERVER_URL}/Prithu.webp`,
  "floating-linkedin": `${process.env.REACT_APP_S3_SERVER_URL}/floating-linkedin.svg`,
  "badge-4": `${process.env.REACT_APP_S3_SERVER_URL}/badge-4.webp`,
  map: `${process.env.REACT_APP_S3_SERVER_URL}/map.webp`,
  "course-icon": `${process.env.REACT_APP_S3_SERVER_URL}/course-icon.svg`,
  "razor-logo": `${process.env.REACT_APP_S3_SERVER_URL}/razor-logo.svg`,
  "story-img-4": `${process.env.REACT_APP_S3_SERVER_URL}/story-img-4.webp`,
  "btn-left-nav": `${process.env.REACT_APP_S3_SERVER_URL}/btn-left-nav.svg`,
  "week-icon-2": `${process.env.REACT_APP_S3_SERVER_URL}/week-icon-2.svg`,
  bottom: `${process.env.REACT_APP_S3_SERVER_URL}/bottom.webp`,
  "story-img-5": `${process.env.REACT_APP_S3_SERVER_URL}/story-img-5.webp`,
  "Web Student Testimonials": `${process.env.REACT_APP_S3_SERVER_URL}/Web Student Testimonials.zip`,
  "mobile-logo": `${process.env.REACT_APP_S3_SERVER_URL}/mobile-logo.svg`,
  "overview-tile-1-old": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-1-old.webp`,
  "noun-consistency-5265752": `${process.env.REACT_APP_S3_SERVER_URL}/noun-consistency-5265752.svg`,
  "nihar-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/nihar-thumb.webp`,
  sharecut: `${process.env.REACT_APP_S3_SERVER_URL}/sharecut.webp`,
  "past-feedback": `${process.env.REACT_APP_S3_SERVER_URL}/past-feedback.webp`,
  "btn-right-arrow": `${process.env.REACT_APP_S3_SERVER_URL}/btn-right-arrow.svg`,
  "Path 17951": `${process.env.REACT_APP_S3_SERVER_URL}/Path 17951.svg`,
  "badge-3": `${process.env.REACT_APP_S3_SERVER_URL}/badge-3.webp`,
  "achievement-cup": `${process.env.REACT_APP_S3_SERVER_URL}/achievement-cup.svg`,
  six: `${process.env.REACT_APP_S3_SERVER_URL}/six.webp`,
  khatabook: `${process.env.REACT_APP_S3_SERVER_URL}/khatabook.webp`,
  "uber-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/uber-resource-tab.webp`,
  two: `${process.env.REACT_APP_S3_SERVER_URL}/two.webp`,
  "product-cases": `${process.env.REACT_APP_S3_SERVER_URL}/product-cases.webp`,
  "jio-round": `${process.env.REACT_APP_S3_SERVER_URL}/jio-round.webp`,
  "story-img-1": `${process.env.REACT_APP_S3_SERVER_URL}/story-img-1.webp`,
  "btn-background-yellow": `${process.env.REACT_APP_S3_SERVER_URL}/btn-background-yellow.svg`,
  ninjacart: `${process.env.REACT_APP_S3_SERVER_URL}/ninjacart.webp`,
  arrow: `${process.env.REACT_APP_S3_SERVER_URL}/arrow.webp`,
  challenge_banner_2: `${process.env.REACT_APP_S3_SERVER_URL}/challenge_banner_2.webp`,
  deliveryhero: `${process.env.REACT_APP_S3_SERVER_URL}/deliveryhero.webp`,
  "story-img1": `${process.env.REACT_APP_S3_SERVER_URL}/story-img1.webp`,
  "btn-background-green": `${process.env.REACT_APP_S3_SERVER_URL}/btn-background-green.svg`,
  "challenge-master": `${process.env.REACT_APP_S3_SERVER_URL}/challenge-master.webp`,
  Rayvant: `${process.env.REACT_APP_S3_SERVER_URL}/Rayvant.webp`,
  "challenge-1-full": `${process.env.REACT_APP_S3_SERVER_URL}/challenge-1-full.webp`,
  "mobile-menu-icon": `${process.env.REACT_APP_S3_SERVER_URL}/mobile-menu-icon.svg`,
  cashfree: `${process.env.REACT_APP_S3_SERVER_URL}/cashfree.svg`,
  "paypal-logo": `${process.env.REACT_APP_S3_SERVER_URL}/paypal-logo.webp`,
  "banner-test": `${process.env.REACT_APP_S3_SERVER_URL}/banner-test.webp`,
  "badge-2": `${process.env.REACT_APP_S3_SERVER_URL}/badge-2.webp`,
  four: `${process.env.REACT_APP_S3_SERVER_URL}/four.webp`,
  "byjus-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/byjus-resource-tab.webp`,
  "our-challenges-green-bg": `${process.env.REACT_APP_S3_SERVER_URL}/our-challenges-green-bg.webp`,
  obottom: `${process.env.REACT_APP_S3_SERVER_URL}/obottom.webp`,
  "twitter-black": `${process.env.REACT_APP_S3_SERVER_URL}/twitter-black.svg`,
  "winner-icon-2": `${process.env.REACT_APP_S3_SERVER_URL}/winner-icon-2.webp`,
  "Logos sent on 08_007_2022": `${process.env.REACT_APP_S3_SERVER_URL}/Logos sent on 08_007_2022.zip`,
  trophy: `${process.env.REACT_APP_S3_SERVER_URL}/trophy.svg`,
  Karishma: `${process.env.REACT_APP_S3_SERVER_URL}/Karishma.webp`,
  "story-img-2": `${process.env.REACT_APP_S3_SERVER_URL}/story-img-2.webp`,
  "circle-star": `${process.env.REACT_APP_S3_SERVER_URL}/circle-star.svg`,
  "winner-icon": `${process.env.REACT_APP_S3_SERVER_URL}/winner-icon.webp`,
  challenge_banner_1: `${process.env.REACT_APP_S3_SERVER_URL}/challenge_banner_1.webp`,
  "language-icon": `${process.env.REACT_APP_S3_SERVER_URL}/language-icon.svg`,
  "nikunj-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/nikunj-thumb.webp`,
  "floating-youtube": `${process.env.REACT_APP_S3_SERVER_URL}/floating-youtube.svg`,
  "oyo-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/oyo-resource-tab.webp`,
  "overview-tile-4-new": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-4-new.webp`,
  "Debarati-Bhattacharjee": `${process.env.REACT_APP_S3_SERVER_URL}/Debarati-Bhattacharjee.webp`,
  "green-globe-right": `${process.env.REACT_APP_S3_SERVER_URL}/green-globe-right.svg`,
  "story-img-3": `${process.env.REACT_APP_S3_SERVER_URL}/story-img-3.webp`,
  "puzzle-icon": `${process.env.REACT_APP_S3_SERVER_URL}/puzzle-icon.svg`,
  "pm-run-banner-767": `${process.env.REACT_APP_S3_SERVER_URL}/pm-run-banner-767.webp`,
  "winner-icon-3": `${process.env.REACT_APP_S3_SERVER_URL}/winner-icon-3.webp`,
  "floating-close": `${process.env.REACT_APP_S3_SERVER_URL}/floating-close.svg`,
  "logo-black": `${process.env.REACT_APP_S3_SERVER_URL}/logo-black.svg`,
  jio: `${process.env.REACT_APP_S3_SERVER_URL}/jio.webp`,
  "group-icon": `${process.env.REACT_APP_S3_SERVER_URL}/group-icon.svg`,
  "right-circle-btn": `${process.env.REACT_APP_S3_SERVER_URL}/right-circle-btn.svg`,
  "badge-1": `${process.env.REACT_APP_S3_SERVER_URL}/badge-1.webp`,
  "Parijat-Kalita": `${process.env.REACT_APP_S3_SERVER_URL}/Parijat-Kalita.webp`,
  "solution-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/solution-resource-tab.webp`,
  "mentor-nav-2": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-nav-2.webp`,
  "about-us-banner1": `${process.env.REACT_APP_S3_SERVER_URL}/about-us-banner1.webp`,
  "overview-tile-1-new": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-1-new.webp`,
  quote2: `${process.env.REACT_APP_S3_SERVER_URL}/quote2.webp`,
  "jio-logo": `${process.env.REACT_APP_S3_SERVER_URL}/jio-logo.webp`,
  entry: `${process.env.REACT_APP_S3_SERVER_URL}/entry.webp`,
  "twitter-green": `${process.env.REACT_APP_S3_SERVER_URL}/twitter-green.svg`,
  "byjus-person": `${process.env.REACT_APP_S3_SERVER_URL}/byjus-person.webp`,
  "game-logo": `${process.env.REACT_APP_S3_SERVER_URL}/game-logo.svg`,
  img4: `${process.env.REACT_APP_S3_SERVER_URL}/img4.webp`,
  "slack-group-icon": `${process.env.REACT_APP_S3_SERVER_URL}/slack-group-icon.svg`,
  google2: `${process.env.REACT_APP_S3_SERVER_URL}/google2.svg`,
  "mentor-nav-3": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-nav-3.webp`,
  "calender-ok-icon": `${process.env.REACT_APP_S3_SERVER_URL}/calender-ok-icon.svg`,
  cm4: `${process.env.REACT_APP_S3_SERVER_URL}/cm4.webp`,
  bayzus: `${process.env.REACT_APP_S3_SERVER_URL}/bayzus.webp`,
  "filter-cross-icon": `${process.env.REACT_APP_S3_SERVER_URL}/filter-cross-icon.svg`,
  Ruthvik: `${process.env.REACT_APP_S3_SERVER_URL}/Ruthvik.webp`,
  "mentor-nav-1": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-nav-1.webp`,
  quote1: `${process.env.REACT_APP_S3_SERVER_URL}/quote1.webp`,
  "Group 10560": `${process.env.REACT_APP_S3_SERVER_URL}/Group 10560.svg`,
  "lavanya-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/lavanya-thumb.webp`,
  uploaddrive: `${process.env.REACT_APP_S3_SERVER_URL}/uploaddrive.webp`,
  "btn-left-arrow": `${process.env.REACT_APP_S3_SERVER_URL}/btn-left-arrow.webp`,
  "fave-logo": `${process.env.REACT_APP_S3_SERVER_URL}/fave-logo.webp`,
  "video-play-icon": `${process.env.REACT_APP_S3_SERVER_URL}/video-play-icon.svg`,
  "mobilebanner-img2": `${process.env.REACT_APP_S3_SERVER_URL}/mobilebanner-img2.webp`,
  "minush-icon": `${process.env.REACT_APP_S3_SERVER_URL}/minush-icon.svg`,
  "1-banner": `${process.env.REACT_APP_S3_SERVER_URL}/1.webp`,
  "2-banner": `${process.env.REACT_APP_S3_SERVER_URL}/2.webp`,
  "3-banner": `${process.env.REACT_APP_S3_SERVER_URL}/3.webp`,
  "4-banner": `${process.env.REACT_APP_S3_SERVER_URL}/4.webp`,
  "5-banner": `${process.env.REACT_APP_S3_SERVER_URL}/5.webp`,
  "jio-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/jio-resource-tab.webp`,
  "Debpriya-Pal": `${process.env.REACT_APP_S3_SERVER_URL}/Debpriya-Pal.webp`,
  "Student Speaks": `${process.env.REACT_APP_S3_SERVER_URL}/Student Speaks.xlsx`,
  "circle-cross-btn": `${process.env.REACT_APP_S3_SERVER_URL}/circle-cross-btn.svg`,
  cm3: `${process.env.REACT_APP_S3_SERVER_URL}/cm3.webp`,
  "Shantanu Korada": `${process.env.REACT_APP_S3_SERVER_URL}/Shantanu Korada.webp`,
  "noun_Planning_252745 (1)": `${process.env.REACT_APP_S3_SERVER_URL}/noun_Planning_252745 (1).svg`,
  "overview-tile-4-old": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-4-old.webp`,
  "green-globe-right-2": `${process.env.REACT_APP_S3_SERVER_URL}/green-globe-right-2.svg`,
  "oyo-round": `${process.env.REACT_APP_S3_SERVER_URL}/oyo-round.webp`,
  "myntra-logo": `${process.env.REACT_APP_S3_SERVER_URL}/myntra-logo.webp`,
  "mentor-nav-4": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-nav-4.webp`,
  upArrow: `${process.env.REACT_APP_S3_SERVER_URL}/upArrow.webp`,
  "download-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/download-resource-tab.webp`,
  img3: `${process.env.REACT_APP_S3_SERVER_URL}/img3.webp`,
  "parking-logo": `${process.env.REACT_APP_S3_SERVER_URL}/parking-logo.webp`,
  btnicon: `${process.env.REACT_APP_S3_SERVER_URL}/btnicon.svg`,
  five: `${process.env.REACT_APP_S3_SERVER_URL}/five.webp`,
  laptop: `${process.env.REACT_APP_S3_SERVER_URL}/laptop.webp`,
  "congrats-img": `${process.env.REACT_APP_S3_SERVER_URL}/congrats-img.svg`,
  yulu: `${process.env.REACT_APP_S3_SERVER_URL}/yulu.svg`,
  "classes-images-3": `${process.env.REACT_APP_S3_SERVER_URL}/classes-images-3.webp`,
  "jio-person": `${process.env.REACT_APP_S3_SERVER_URL}/jio-person.webp`,
  img2: `${process.env.REACT_APP_S3_SERVER_URL}/img2.webp`,
  "mentor-nav-5": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-nav-5.webp`,
  linkedIn: `${process.env.REACT_APP_S3_SERVER_URL}/linkedIn.svg`,
  one: `${process.env.REACT_APP_S3_SERVER_URL}/one.webp`,
  "1st-position": `${process.env.REACT_APP_S3_SERVER_URL}/1st-position.webp`,
  mindtickle: `${process.env.REACT_APP_S3_SERVER_URL}/mindtickle.webp`,
  cm2: `${process.env.REACT_APP_S3_SERVER_URL}/cm2.webp`,
  unknown1: `${process.env.REACT_APP_S3_SERVER_URL}/unknown1.webp`,
  Siddhesh: `${process.env.REACT_APP_S3_SERVER_URL}/Siddhesh.webp`,
  "Mohammad-Hussain": `${process.env.REACT_APP_S3_SERVER_URL}/Mohammad-Hussain.webp`,
  men2: `${process.env.REACT_APP_S3_SERVER_URL}/men2.webp`,
  "slick-next-arrow": `${process.env.REACT_APP_S3_SERVER_URL}/slick-next-arrow.svg`,
  "slider-images": `${process.env.REACT_APP_S3_SERVER_URL}/slider-images.webp`,
  "floating-instagram": `${process.env.REACT_APP_S3_SERVER_URL}/floating-instagram.svg`,
  "upload-icon": `${process.env.REACT_APP_S3_SERVER_URL}/upload-icon.svg`,
  redirect: `${process.env.REACT_APP_S3_SERVER_URL}/redirect.svg`,
  salon: `${process.env.REACT_APP_S3_SERVER_URL}/salon.webp`,
  classplus: `${process.env.REACT_APP_S3_SERVER_URL}/classplus.webp`,
  "ankit-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/ankit-thumb.webp`,
  "Shantanu-Korada": `${process.env.REACT_APP_S3_SERVER_URL}/Shantanu-Korada.webp`,
  "btn-right-arrow-green": `${process.env.REACT_APP_S3_SERVER_URL}/btn-right-arrow-green.svg`,
  "classes-images-1": `${process.env.REACT_APP_S3_SERVER_URL}/classes-images-1.webp`,
  youtube: `${process.env.REACT_APP_S3_SERVER_URL}/youtube.svg`,
  img1: `${process.env.REACT_APP_S3_SERVER_URL}/img1.webp`,
  men3: `${process.env.REACT_APP_S3_SERVER_URL}/men3.webp`,
  oyo: `${process.env.REACT_APP_S3_SERVER_URL}/oyo.webp`,
  "nikhita-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/nikhita-thumb.webp`,
  cm1: `${process.env.REACT_APP_S3_SERVER_URL}/cm1.webp`,
  unknown2: `${process.env.REACT_APP_S3_SERVER_URL}/unknown2.webp`,
  ved: `${process.env.REACT_APP_S3_SERVER_URL}/ved.webp`,
  "mentor-img-2": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-img-2.webp`,
  "hdfc-round": `${process.env.REACT_APP_S3_SERVER_URL}/hdfc-round.webp`,
  "active-video-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/active-video-thumb.webp`,
  "login-modal-img": `${process.env.REACT_APP_S3_SERVER_URL}/login-modal-img.webp`,
  "add-linkedin": `${process.env.REACT_APP_S3_SERVER_URL}/add-linkedin.webp`,
  "banner375-2": `${process.env.REACT_APP_S3_SERVER_URL}/banner375-2.webp`,
  c1: `${process.env.REACT_APP_S3_SERVER_URL}/c1.webp`,
  "price-icon": `${process.env.REACT_APP_S3_SERVER_URL}/price-icon.svg`,
  "college-4": `${process.env.REACT_APP_S3_SERVER_URL}/college-4.webp`,
  "piyush-lahoti": `${process.env.REACT_APP_S3_SERVER_URL}/piyush-lahoti.webp`,
  "laurin-lukas": `${process.env.REACT_APP_S3_SERVER_URL}/laurin-lukas.webp`,
  noun_Planning_252745: `${process.env.REACT_APP_S3_SERVER_URL}/noun_Planning_252745.svg`,
  "college-5": `${process.env.REACT_APP_S3_SERVER_URL}/college-5.webp`,
  "footer-img": `${process.env.REACT_APP_S3_SERVER_URL}/footer-img.webp`,
  seven: `${process.env.REACT_APP_S3_SERVER_URL}/seven.webp`,
  "menu-icon": `${process.env.REACT_APP_S3_SERVER_URL}/menu-icon.svg`,
  yourstory: `${process.env.REACT_APP_S3_SERVER_URL}/yourstory.svg`,
  "access-icon": `${process.env.REACT_APP_S3_SERVER_URL}/access-icon.svg`,
  "list-item-img-1": `${process.env.REACT_APP_S3_SERVER_URL}/list-item-img-1.webp`,
  footerlogo: `${process.env.REACT_APP_S3_SERVER_URL}/footerlogo.svg`,
  "mentor-img-3": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-img-3.webp`,
  Swiggy: `${process.env.REACT_APP_S3_SERVER_URL}/Swiggy.webp`,
  "icons8-correct": `${process.env.REACT_APP_S3_SERVER_URL}/icons8-correct.svg`,
  "mentor-img-1": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-img-1.webp`,
  "list-item-img-3": `${process.env.REACT_APP_S3_SERVER_URL}/list-item-img-3.webp`,
  "banner-img": `${process.env.REACT_APP_S3_SERVER_URL}/banner-img.webp`,
  "banner375-1": `${process.env.REACT_APP_S3_SERVER_URL}/banner375-1.webp`,
  career: `${process.env.REACT_APP_S3_SERVER_URL}/career.webp`,
  "why-pm-school": `${process.env.REACT_APP_S3_SERVER_URL}/why-pm-school.webp`,
  c2: `${process.env.REACT_APP_S3_SERVER_URL}/c2.webp`,
  "overview-tile-2-new": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-2-new.webp`,
  haptik: `${process.env.REACT_APP_S3_SERVER_URL}/haptik.webp`,
  Tushar: `${process.env.REACT_APP_S3_SERVER_URL}/Tushar.webp`,
  twitter: `${process.env.REACT_APP_S3_SERVER_URL}/twitter.svg`,
  "college-7": `${process.env.REACT_APP_S3_SERVER_URL}/college-7.webp`,
  desktopbanner: `${process.env.REACT_APP_S3_SERVER_URL}/desktopbanner.webp`,
  insta: `${process.env.REACT_APP_S3_SERVER_URL}/insta.svg`,
  "floating-close-black": `${process.env.REACT_APP_S3_SERVER_URL}/floating-close-black.svg`,
  "ninjacart-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/ninjacart-resource-tab.webp`,
  "carousel-right-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/carousel-right-resource-tab.webp`,
  "college-6": `${process.env.REACT_APP_S3_SERVER_URL}/college-6.webp`,
  "hdfc-person": `${process.env.REACT_APP_S3_SERVER_URL}/hdfc-person.webp`,
  1: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/1.webp`,
  2: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/2.webp`,
  3: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/3.webp`,
  4: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/4.webp`,
  5: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/5.webp`,
  6: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/6.webp`,
  7: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/7.webp`,
  c3: `${process.env.REACT_APP_S3_SERVER_URL}/c3.webp`,
  cycle: `${process.env.REACT_APP_S3_SERVER_URL}/cycle.webp`,
  "analytical-skills": `${process.env.REACT_APP_S3_SERVER_URL}/analytical-skills.webp`,
  "challenge-2-full": `${process.env.REACT_APP_S3_SERVER_URL}/challenge-2-full.webp`,
  housing: `${process.env.REACT_APP_S3_SERVER_URL}/housing.webp`,
  "file-icon": `${process.env.REACT_APP_S3_SERVER_URL}/file-icon.svg`,
  "list-item-img-2": `${process.env.REACT_APP_S3_SERVER_URL}/list-item-img-2.webp`,
  "sign-up": `${process.env.REACT_APP_S3_SERVER_URL}/sign-up.webp`,
  "mentor-img-4": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-img-4.webp`,
  baby: `${process.env.REACT_APP_S3_SERVER_URL}/baby.webp`,
  "prashanth-bhaskaran": `${process.env.REACT_APP_S3_SERVER_URL}/prashanth-bhaskaran.webp`,
  "youtube-green": `${process.env.REACT_APP_S3_SERVER_URL}/youtube-green.svg`,
  logo: `${process.env.REACT_APP_S3_SERVER_URL}/logo.svg`,
  mobilebanner: `${process.env.REACT_APP_S3_SERVER_URL}/mobilebanner.webp`,
  "agile-planning": `${process.env.REACT_APP_S3_SERVER_URL}/agile-planning.webp`,
  feedback: `${process.env.REACT_APP_S3_SERVER_URL}/feedback.webp`,
  "share-resource-tab": `${process.env.REACT_APP_S3_SERVER_URL}/share-resource-tab.webp`,
  Course: `${process.env.REACT_APP_S3_SERVER_URL}/Course.svg`,
  "college-2": `${process.env.REACT_APP_S3_SERVER_URL}/college-2.webp`,
  "up-arrow": `${process.env.REACT_APP_S3_SERVER_URL}/up-arrow.webp`,
  "overview-tile-3-new": `${process.env.REACT_APP_S3_SERVER_URL}/overview-tile-3-new.webp`,
  "uber-logo": `${process.env.REACT_APP_S3_SERVER_URL}/uber-logo.webp`,
  "story-nav-8": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-8.webp`,
  "Jitendra-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/Jitendra-thumb.webp`,
  "dream-logo": `${process.env.REACT_APP_S3_SERVER_URL}/dream-logo.webp`,
  "story-nav-9": `${process.env.REACT_APP_S3_SERVER_URL}/story-nav-9.webp`,
  "Chandrashekhar-Koushik": `${process.env.REACT_APP_S3_SERVER_URL}/Chandrashekhar-Koushik.webp`,
  epifi: `${process.env.REACT_APP_S3_SERVER_URL}/epifi.webp`,
  "edit-pencil": `${process.env.REACT_APP_S3_SERVER_URL}/edit-pencil.webp`,
  "college-3": `${process.env.REACT_APP_S3_SERVER_URL}/college-3.webp`,
  "design-thinking": `${process.env.REACT_APP_S3_SERVER_URL}/design-thinking.webp`,
  "kushal-thumb": `${process.env.REACT_APP_S3_SERVER_URL}/kushal-thumb.webp`,
  "mentor-img-5": `${process.env.REACT_APP_S3_SERVER_URL}/mentor-img-5.webp`,
  "linkedIn-black": `${process.env.REACT_APP_S3_SERVER_URL}/linkedIn-black.svg`,
  c4: `${process.env.REACT_APP_S3_SERVER_URL}/c4.webp`,
  downArrow: `${process.env.REACT_APP_S3_SERVER_URL}/downArrow.webp`,
  "clap-img": `${process.env.REACT_APP_S3_SERVER_URL}/clap-img.svg`,
  "college-1": `${process.env.REACT_APP_S3_SERVER_URL}/college-1.webp`,
  "list-item-img": `${process.env.REACT_APP_S3_SERVER_URL}/list-item-img.webp`,
  map3: `${process.env.REACT_APP_S3_SERVER_URL}/map3.webp`,
  "profile-pic": `${process.env.REACT_APP_S3_SERVER_URL}/profile-pic.webp`,
  map2: `${process.env.REACT_APP_S3_SERVER_URL}/map2.webp`,
  "Instagram-black": `${process.env.REACT_APP_S3_SERVER_URL}/Instagram-black.svg`,
  "slider-planning": `${process.env.REACT_APP_S3_SERVER_URL}/slider-planning.svg`,
  "edit-linkedin": `${process.env.REACT_APP_S3_SERVER_URL}/edit-linkedin.webp`,
  "linkedin-png": `${process.env.REACT_APP_S3_SERVER_URL}/linkedIn.webp`,
  intellicence: `${process.env.REACT_APP_S3_SERVER_URL}/intellicence.svg`,
  "list-item-img-4": `${process.env.REACT_APP_S3_SERVER_URL}/list-item-img-4.webp`,
  bluebanner: `${process.env.REACT_APP_S3_SERVER_URL}/bluebanner.svg`,
  three: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/three.webp`,
  six: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/six.webp`,
  two: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/two.webp`,
  four: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/four.webp`,
  five: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/five.webp`,
  one: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/one.webp`,
  seven: `${process.env.REACT_APP_S3_SERVER_URL}/repmrunadaptations/seven.webp`,
  "F3BAC8D1-5C70-408B-839C-35E6DBBE1753": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/F3BAC8D1-5C70-408B-839C-35E6DBBE1753.webp`,
  khata: `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/khata.webp`,
  "CED0FF97-8D9E-4210-9826-3BBBFA45DB31": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/CED0FF97-8D9E-4210-9826-3BBBFA45DB31.svg`,
  hap: `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/hap.webp`,
  "B81B3533-3F71-4B96-8394-D99B11006A45": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/B81B3533-3F71-4B96-8394-D99B11006A45.svg`,
  "273E9F9D-F409-4AEF-8C85-460A41856EB2": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/273E9F9D-F409-4AEF-8C85-460A41856EB2.webp`,
  "F14DFA04-675E-4F79-8980-FF7DD38AF224": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/F14DFA04-675E-4F79-8980-FF7DD38AF224.svg`,
  "DD160942-6511-45DB-B52C-06443501F666": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/DD160942-6511-45DB-B52C-06443501F666.svg`,
  "36AC94BC-AF7E-43E2-9A11-4E643F165DB9": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/36AC94BC-AF7E-43E2-9A11-4E643F165DB9.svg`,
  mind: `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/mind.webp`,
  "A1137B48-544B-4733-9559-6CB17DE0400C": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/A1137B48-544B-4733-9559-6CB17DE0400C.webp`,
  "9DDE2988-DD98-4D48-99DE-D8C5E62D66B6": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/9DDE2988-DD98-4D48-99DE-D8C5E62D66B6.webp`,
  house: `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/house.webp`,
  "7E91A5AF-BBB1-4F1D-9F11-BB5F8CFABE7C": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/7E91A5AF-BBB1-4F1D-9F11-BB5F8CFABE7C.webp`,
  share: `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/share.webp`,
  "AF165F2F-E951-4CD3-AAE9-1B9287849F9A": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/AF165F2F-E951-4CD3-AAE9-1B9287849F9A.webp`,
  "7467F328-4DCB-4FEA-8632-75F84C10E279": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/7467F328-4DCB-4FEA-8632-75F84C10E279.webp`,
  "705B6594-B276-438A-8C9A-91F6CA1E6D91": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/705B6594-B276-438A-8C9A-91F6CA1E6D91.svg`,
  "5FF333A4-5C1D-41B6-B4D5-871C3E830E96": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/5FF333A4-5C1D-41B6-B4D5-871C3E830E96.webp`,
  "22961C4E-F120-4C65-A3C4-9E874FF62E21": `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/22961C4E-F120-4C65-A3C4-9E874FF62E21.svg`,
  ATLASSIAN: `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/ATLASSIAN.webp`,
  high: `${process.env.REACT_APP_S3_SERVER_URL}/Final Logos/high.webp`,
  "Siddhesh Desai_CASHFREE_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Siddhesh Desai_CASHFREE_1920.webp`,
  "Karishma Kapoor_BYJUS_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Karishma Kapoor_BYJU’S_1920.webp`,
  "Ruthvik Majety_HIGHRADIUS_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Ruthvik Majety_HIGHRADIUS_1920.webp`,
  "Prashanth Bhaskaran_CRED_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Prashanth Bhaskaran_CRED_1920.webp`,
  "Sagar Dang_MAKE MY TRIP_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Sagar Dang_MAKE MY TRIP_1920.webp`,
  certificateSkelton: `${process.env.REACT_APP_S3_SERVER_URL}/desktop/certificateSkelton.svg`,
  certificateSocial: `${process.env.REACT_APP_S3_SERVER_URL}/desktop/certificateSocial.svg`,
  certificateDown: `${process.env.REACT_APP_S3_SERVER_URL}/desktop/certificateDown.svg`,
  "Tushar Anand_OLA_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Tushar Anand_OLA_1920.webp`,
  "Ananya Nandan_MPL_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Ananya Nandan_MPL_1920.webp`,
  "Prithu Verma_HOUSING-1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Prithu Verma_HOUSING.COM_1920.webp`,
  "Rayvant Gupta_HD WORKS_1920": `${process.env.REACT_APP_S3_SERVER_URL}/desktop/Rayvant Gupta_HD WORKS_1920.webp`,
  CRED: `${process.env.REACT_APP_S3_SERVER_URL}/logo/CRED.webp`,
  MPL: `${process.env.REACT_APP_S3_SERVER_URL}/logo/MPL.webp`,
  leftarrow: `${process.env.REACT_APP_S3_SERVER_URL}/logo/leftarrow.svg`,
  "logo-dark": `${process.env.REACT_APP_S3_SERVER_URL}/logo/logo-dark.webp`,
  Cashfre_payments: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Cashfre_payments.webp`,
  rightarrow: `${process.env.REACT_APP_S3_SERVER_URL}/logo/rightarrow.svg`,
  Highradius: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Highradius.webp`,
  day: `${process.env.REACT_APP_S3_SERVER_URL}/logo/day.webp`,
  Vector: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Vector.svg`,
  czphlc8hlm4yjnhg00iy: `${process.env.REACT_APP_S3_SERVER_URL}/logo/czphlc8hlm4yjnhg00iy.webp`,
  Instagram: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Instagram.svg`,
  Certificate: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Certificate.svg`,
  download: `${process.env.REACT_APP_S3_SERVER_URL}/logo/download.svg`,
  OLA: `${process.env.REACT_APP_S3_SERVER_URL}/logo/OLA.webp`,
  Khatabook: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Khatabook.webp`,
  mmt: `${process.env.REACT_APP_S3_SERVER_URL}/logo/mmt.webp`,
  DAY: `${process.env.REACT_APP_S3_SERVER_URL}/logo/DAY.svg`,
  "back arrow": `${process.env.REACT_APP_S3_SERVER_URL}/logo/back arrow.svg`,
  Copy: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Copy.svg`,
  Hiusing: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Hiusing.com.webp`,
  left: `${process.env.REACT_APP_S3_SERVER_URL}/logo/left.webp`,
  Byjus: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Byju's.webp`,
  hd: `${process.env.REACT_APP_S3_SERVER_URL}/logo/hd.webp`,
  mindtickle: `${process.env.REACT_APP_S3_SERVER_URL}/logo/mindtickle.webp`,
  Yourstory: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Yourstory.webp`,
  Classplus: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Classplus.webp`,
  delete: `${process.env.REACT_APP_S3_SERVER_URL}/logo/delete.svg`,
  Zeta: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Zeta.webp`,
  ShareChat: `${process.env.REACT_APP_S3_SERVER_URL}/logo/ShareChat.webp`,
  LinkedIn: `${process.env.REACT_APP_S3_SERVER_URL}/logo/LinkedIn.svg`,
  Haptik: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Haptik.webp`,
  Twitter: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Twitter.svg`,
  Atlassian: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Atlassian.webp`,
  Yulu: `${process.env.REACT_APP_S3_SERVER_URL}/logo/Yulu.webp`,
  greenarrowright: `${process.env.REACT_APP_S3_SERVER_URL}/logo/greenarrowright.svg`,
  "Vector copy": `${process.env.REACT_APP_S3_SERVER_URL}/logo/Vector copy.svg`,
  "Karishma Kapoor_BYJUS_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Karishma Kapoor_BYJU’S_375.webp`,
  "Sagar Dang_MAKE MY TRIP_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Sagar Dang_MAKE MY TRIP_375.webp`,
  "Prashanth Bhaskaran_CRED_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Prashanth Bhaskaran_CRED_375.webp`,
  "Prithu Verma_HOUSING-786": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Prithu Verma_HOUSING.COM_768.webp`,
  "Prithu Verma_HOUSING-375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Prithu Verma_HOUSING.COM_375.webp`,
  "Siddhesh Desai_CASHFREE_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Siddhesh Desai_CASHFREE_375.webp`,
  "Rayvant Gupta_HD WORKS_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Rayvant Gupta_HD WORKS_375.webp`,
  "Tushar Anand_OLA_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Tushar Anand_OLA_375.webp`,
  "Ruthvik Majety_HIGHRADIUS_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Ruthvik Majety_HIGHRADIUS_375.webp`,
  "Ananya Nandan_MPL_375": `${process.env.REACT_APP_S3_SERVER_URL}/mobile/Ananya Nandan_MPL_375.webp`,
  "Jaideep Kalsi": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Jaideep Kalsi.webp`,
  "B Aravindam": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/B Aravindam.webp`,
  "Sai Ruthvik": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Sai Ruthvik.webp`,
  "Shoaib Hussain": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Shoaib Hussain.webp`,
  "Kriti Sharma": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Kriti Sharma.webp`,
  "Dhananjai Jagannathan": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Dhananjai Jagannathan.webp`,
  Abhijeet_Vyas: `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Abhijeet_Vyas.webp`,
  "Tushar Anand": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Tushar Anand.webp`,
  "Karishma Kapoor": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Karishma Kapoor.webp`,
  "Ajay Athreya": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Ajay Athreya.webp`,
  "Talha Khan": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Talha Khan.webp`,
  "Siddhant Parekh": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Siddhant Parekh.webp`,
  "Hardik Gupta": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Hardik Gupta.webp`,
  "Abhijeet Vyas": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Abhijeet Vyas.webp`,
  "Harish Chakravarthi": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Harish Chakravarthi.webp`,
  "Jatin Garg": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Jatin Garg.webp`,
  "Ruthvik Majety": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Ruthvik Majety.webp`,
  "Siddhesh Desai": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Siddhesh Desai.webp`,
  user: `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/user.webp`,
  "Debpriya Lal": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Debpriya Lal.webp`,
  "Mohammed Hussain": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Mohammed Hussain.webp`,
  "Ananya Nandan": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Ananya Nandan.webp`,
  "Deepak Kumar": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Deepak Kumar.webp`,
  "Harshita SInghla": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Harshita SInghla.webp`,
  "Prithu Verma": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Prithu Verma.webp`,
  "Rohan Mitra": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Rohan Mitra.webp`,
  "Prashanth Bhaskaran": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Prashanth Bhaskaran.webp`,
  "Ravyant Gupta": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Ravyant Gupta.webp`,
  "Virali Parekh": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Virali Parekh.webp`,
  "Saurabh Bhatia": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Saurabh Bhatia.webp`,
  "Pratik Upacharya": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Pratik Upacharya.webp`,
  "Ved Prakash Pandey": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Ved Prakash Pandey.webp`,
  "Anuj Hegde": `${process.env.REACT_APP_S3_SERVER_URL}/testimonial/Anuj Hegde.webp`,
  "Ananya Nandan_MPL_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Ananya Nandan_MPL_768.webp`,
  "Ruthvik Majety_HIGHRADIUS_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Ruthvik Majety_HIGHRADIUS_768.webp`,
  "Tushar Anand_OLA_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Tushar Anand_OLA_768.webp`,
  "Rayvant Gupta_HD WORKS_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Rayvant Gupta_HD WORKS_768.webp`,
  "Siddhesh Desai_CASHFREE_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Siddhesh Desai_CASHFREE_768.webp`,
  "Prashanth Bhaskaran_CRED_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Prashanth Bhaskaran_CRED_768.webp`,
  "Sagar Dang_MAKE MY TRIP_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Sagar Dang_MAKE MY TRIP_768.webp`,
  "Karishma Kapoor_BYJUS_768": `${process.env.REACT_APP_S3_SERVER_URL}/ipad/Karishma Kapoor_BYJU’S_768.webp`,
  "la-regular-400": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/la-regular-400.ttf`,
  "fontawesome-webfont": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/fontawesome-webfont.eot`,
  FontAwesome: `${process.env.REACT_APP_S3_SERVER_URL}/fonts/FontAwesome.otf`,
  "Montserrat-Medium": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/Montserrat-Medium.ttf`,
  "la-brands-400": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/la-brands-400.svg`,
  "la-solid-900": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/la-solid-900.svg`,
  icofont: `${process.env.REACT_APP_S3_SERVER_URL}/fonts/icofont.woff`,
  "Montserrat-Bold": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/Montserrat-Bold.ttf`,
  "Montserrat-SemiBold": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/Montserrat-SemiBold.ttf`,
  "Montserrat-Regular": `${process.env.REACT_APP_S3_SERVER_URL}/fonts/Montserrat-Regular.ttf`,
  pm_school_video: `${process.env.REACT_APP_S3_SERVER_URL}/video/pm_school_video.mp4`,
  "Micro Courses_V2": `${process.env.REACT_APP_S3_SERVER_URL}/video/Micro Courses_V2.mp4`,
  "achievement_card_desktop":`${process.env.REACT_APP_S3_SERVER_URL}/history-page/achievement_card_desktop.webp`,
  "achievement_card_tab":`${process.env.REACT_APP_S3_SERVER_URL}/history-page/achievement_card_tab.webp`,
  "achievement_card_mobile":`${process.env.REACT_APP_S3_SERVER_URL}/history-page/achievement_card_mobile.webp`,
};

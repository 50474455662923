import React, { useEffect } from "react";
import $ from "jquery";
import { assets } from "../../utils/assets";

const SlickCarousal = () => {
const Nav1 =assets['MPL'];
const Nav2 =assets['Byjus'];
const Nav3 =assets['CRED'];
const Nav4 =assets['Hiusing'];
const Nav5 =assets['hd'];
const Nav6 =assets['Highradius'];
const Nav7 =assets['mmt'];
const Nav8 =assets['Cashfre_payments'];
const Nav9 =assets['OLA'];
const MPL = assets['Ananya Nandan_MPL_1920'];
const BYJU = assets['Karishma Kapoor_BYJUS_1920'];
const CRED = assets['Prashanth Bhaskaran_CRED_1920'];
const HOUSING = assets['Prithu Verma_HOUSING-1920'];
const HD = assets['Rayvant Gupta_HD WORKS_1920'];
const HIGHRADIUS = assets['Ruthvik Majety_HIGHRADIUS_1920'];
const TRIP = assets['Sagar Dang_MAKE MY TRIP_1920'];
const cash = assets['Siddhesh Desai_CASHFREE_1920'];
const ola = assets['Tushar Anand_OLA_1920'];
const MPLm = assets['Ananya Nandan_MPL_375'];
const BYJUm = assets['Karishma Kapoor_BYJUS_375'];
const CREDm = assets['Prashanth Bhaskaran_CRED_375'];
const HOUSINGm = assets['Prithu Verma_HOUSING-375'];
const HDm = assets['Rayvant Gupta_HD WORKS_375'];
const HIGHRADIUSm = assets['Ruthvik Majety_HIGHRADIUS_375'];
const TRIPm = assets['Sagar Dang_MAKE MY TRIP_375'];
const cashm = assets['Siddhesh Desai_CASHFREE_375'];
const olam = assets['Tushar Anand_OLA_375'];
const MPLt = assets['Ananya Nandan_MPL_768'];
const BYJUt = assets['Karishma Kapoor_BYJUS_768'];
const CREDt = assets['Prashanth Bhaskaran_CRED_768'];
const HOUSINGt = assets['Prithu Verma_HOUSING-786'];
const HDt = assets['Rayvant Gupta_HD WORKS_768'];
const HIGHRADIUSt = assets['Ruthvik Majety_HIGHRADIUS_768'];
const TRIPt = assets['Sagar Dang_MAKE MY TRIP_768'];
const casht = assets['Siddhesh Desai_CASHFREE_768'];
const olat = assets['Tushar Anand_OLA_768'];
  useEffect(() => {
    // $(document).ready(function () {
    window.call_slider();
    // })
  }, []);
  return (
    <section
      id="slick-carousel-wrapper"
      className="slick-carousel-wrapper banner-wrap"
    >
      <div className="desktopbanner">
        <div className="slider-for">
          <div className="banner-images">
            <img className="img-fluid" alt="Banner Images" src={MPL} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Thank you PM School for helping me understand advanced Product
                concepts. Nikunj helped me with the product Interview dilemmas
                and practice scenarios before interviews.
              </em>
              <h3 className="person-name">Ajay Athreya</h3>
              <span className="designation">APM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" src={BYJU} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                It was well structured and the detailed feedback provided by the
                Mentors on the Product case assignments were invaluable. Kushaal
                helped us with the much needed strategy around interviews and
                Nikunj was always available to help me understand the nuances of
                the job.
              </em>
              <h3 className="person-name">Deepak Doguparti</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" alt="cred logo" src={CRED} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Fortunately I ended up choosing PM School and it had been an
                amazing experience for me, right from the course learnings, to
                the referrals that I got at Purple Talk, ShareChat, StepSetGo,
                Ninjacart, and Byju's.
              </em>
              <h3 className="person-name">Shantanu Korada</h3>
              <span className="designation">APM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" alt="housing logo" src={HOUSING} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Thanks PM School for helping me understand my strengths for
                Product Management interviews. The mock interviews helped me
                immensely in improving my interview preparation as I was able to
                crack the interview process.
              </em>
              <h3 className="person-name">Debarati Bharacharjee</h3>
              <span className="designation">PM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" alt="hd logo" src={HD} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                The course helped me gain product management skills with the
                sessions, assignments & discussions aimed at covering every
                aspect of product management. It is a must-do for securing your
                first product role.
              </em>
              <h3 className="person-name">Debpriya Pal</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" alt="highradius logo" src={HIGHRADIUS} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                The 2 months PM School training helped think analytically with
                product management topics - discovery, roadmap, market research
                and a product plan. Thank you PM School for the immense
                knowledge in product management.
              </em>
              <h3 className="person-name">Masoom Shreshtha</h3>
              <span className="designation">PO</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" alt="trip logo" src={TRIP} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" alt="cash logo" src={cash} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluid" alt="ola logo" src={ola} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
        </div>
        <div className="slider-nav logos-wrapper">
          <div className="logo-item" style={{ width: "193px !important" }}>
            <img className="img-fluid" src={Nav1} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav2} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav3} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav4} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav5} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav6} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav7} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav8} />
          </div>
          <div className="logo-item">
            <img className="img-fluid" src={Nav9} />
          </div>
        </div>
      </div>
      <div className="mobilebanner">
        <div className="slider-for">
          <div className="banner-images">
            <img className="img-fluidm" alt="Banner Images" src={MPLm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Thank you PM School for helping me understand advanced Product
                concepts. Nikunj helped me with the product Interview dilemmas
                and practice scenarios before interviews.
              </em>
              <h3 className="person-name">Ajay Athreya</h3>
              <span className="designation">APM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={BYJUm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                It was well structured and the detailed feedback provided by the
                Mentors on the Product case assignments were invaluable. Kushaal
                helped us with the much needed strategy around interviews and
                Nikunj was always available to help me understand the nuances of
                the job.
              </em>
              <h3 className="person-name">Deepak Doguparti</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={CREDm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Fortunately I ended up choosing PM School and it had been an
                amazing experience for me, right from the course learnings, to
                the referrals that I got at Purple Talk, ShareChat, StepSetGo,
                Ninjacart, and Byju's.
              </em>
              <h3 className="person-name">Shantanu Korada</h3>
              <span className="designation">APM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={HOUSINGm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Thanks PM School for helping me understand my strengths for
                Product Management interviews. The mock interviews helped me
                immensely in improving my interview preparation as I was able to
                crack the interview process.
              </em>
              <h3 className="person-name">Debarati Bharacharjee</h3>
              <span className="designation">PM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={HDm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                The course helped me gain product management skills with the
                sessions, assignments & discussions aimed at covering every
                aspect of product management. It is a must-do for securing your
                first product role.
              </em>
              <h3 className="person-name">Debpriya Pal</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={HIGHRADIUSm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                The 2 months PM School training helped think analytically with
                product management topics - discovery, roadmap, market research
                and a product plan. Thank you PM School for the immense
                knowledge in product management.
              </em>
              <h3 className="person-name">Masoom Shreshtha</h3>
              <span className="designation">PO</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={TRIPm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={olam} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={cashm} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
        </div>
        <div className="slider-nav logos-wrapper">
          <div className="logo-item">
            <img className="img-fluidm" src={Nav1} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav2} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav3} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav4} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav5} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav6} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav7} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav8} />
          </div>
          <div className="logo-item">
            <img className="img-fluidm" src={Nav9} />
          </div>
        </div>
      </div>
      <div className="ipedbanner">
        <div className="slider-for">
          <div className="banner-images">
            <img className="img-fluidm" alt="Banner Images" src={MPLt} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Thank you PM School for helping me understand advanced Product
                concepts. Nikunj helped me with the product Interview dilemmas
                and practice scenarios before interviews.
              </em>
              <h3 className="person-name">Ajay Athreya</h3>
              <span className="designation">APM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={BYJUt} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                It was well structured and the detailed feedback provided by the
                Mentors on the Product case assignments were invaluable. Kushaal
                helped us with the much needed strategy around interviews and
                Nikunj was always available to help me understand the nuances of
                the job.
              </em>
              <h3 className="person-name">Deepak Doguparti</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={CREDt} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Fortunately I ended up choosing PM School and it had been an
                amazing experience for me, right from the course learnings, to
                the referrals that I got at Purple Talk, ShareChat, StepSetGo,
                Ninjacart, and Byju's.
              </em>
              <h3 className="person-name">Shantanu Korada</h3>
              <span className="designation">APM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={HOUSINGt} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Thanks PM School for helping me understand my strengths for
                Product Management interviews. The mock interviews helped me
                immensely in improving my interview preparation as I was able to
                crack the interview process.
              </em>
              <h3 className="person-name">Debarati Bharacharjee</h3>
              <span className="designation">PM</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={HDt} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                The course helped me gain product management skills with the
                sessions, assignments & discussions aimed at covering every
                aspect of product management. It is a must-do for securing your
                first product role.
              </em>
              <h3 className="person-name">Debpriya Pal</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={HIGHRADIUSt} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                The 2 months PM School training helped think analytically with
                product management topics - discovery, roadmap, market research
                and a product plan. Thank you PM School for the immense
                knowledge in product management.
              </em>
              <h3 className="person-name">Masoom Shreshtha</h3>
              <span className="designation">PO</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={TRIPt} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={casht} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
          <div>
            <img className="img-fluidm" src={olat} />
            {/* <div className="content-wrapper">
              <h3 className="title">Product Manager Success Stories</h3>
              <em className="caption">
                Found PM school sessions relevant to product responsibilities.
                Topics handled by Guest PM experts. Nikunj, Ankit helped explain
                concepts with real-life product experiences & made the process
                immersive, insightful.
              </em>
              <h3 className="person-name">Monica Singh</h3>
              <span className="designation">Product Manager</span>
            </div> */}
          </div>
        </div>
        <div className="slider-nav logos-wrapper">
          <div className="logo-item">
            <img className="img-fluidt" src={Nav1} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav2} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav3} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav4} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav5} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav6} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav7} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav8} />
          </div>
          <div className="logo-item">
            <img className="img-fluidt" src={Nav9} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SlickCarousal;

import React from "react";
import { assets } from "../../utils/assets";
import "./styles/_homepage.scss"
const PmRunBox = () => {
    const CalenderIcon=assets['noun-calender-2643697'];
    const ConsistencyIcon=assets['noun-consistency-5265752'];
 return (
  <section className="pmRun__box">
   <div className="pmRunBox__left">
    <h2>Introducing The PM Run - Question streaks!</h2>
    <p>
     Answer a quiz question about the PM Topics every day, and try to maintain a
     streak! This is a chance to understand how well, do you know the PM Basics
     and share the results with your friends!
    </p>
   </div>
   <div className="divider"></div>
   <div className="pmRunBox__right">
    <div className="topic__list">
     <div className="topic">
      <img loading="lazy" src={ConsistencyIcon} alt="" />
      <p>Stay consistent with your learning</p>
     </div>
     <div className="topic">
      <img loading="lazy" src={CalenderIcon} alt="" />
      <p>Track your progress</p>
     </div>
    </div>
    <a
     href="/pm-run"
     className="pmRun__redirect"
     style={{ textDecoration: "none", color: "unset" }}
     rel="noreferrer">
     <button className="btn-white">GO TO PM RUN</button>
    </a>
   </div>
  </section>
 );
};

export default PmRunBox;

import React from "react";
import "./files-list.scss";
import { TosterMessageSuccess, TosterMessageError } from '../../Toster/Toster';
import {
  submitEntry
} from '../../../api/ApiRequest';
import { assets } from "../../../utils/assets";

function FilesList(props) {
  const RemoveBtnIcon = assets['circle-cross-btn'];
  const multilineTextarea = React.useRef(null);
  const [val, setVal] = React.useState("");
  const [maxLength, setMaxLength] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  if (props.selectedFile.remark_text != "" && val == "") {
    setVal(props.selectedFile.remark_text);
  }
  const changeTextarea = () => {
    const text = multilineTextarea.current.value.split(" ");

    if (text.length > 250) {
      setMaxLength(text.join(" ").length);
    } else if (text.length === 250) {
      setVal(text.join(" "));
    } else {
      setMaxLength(false);
      setVal(text.join(" "));
    }
    multilineTextarea.current.style.height = "auto";
    multilineTextarea.current.style.height =
      multilineTextarea.current.scrollHeight + "px";
  };


  const handleChange = event => {

    const formData = new FormData();
    formData.append("challenge_id", props.challengeId.challenge_id);
    formData.append("file", props.selectedFile.file);
    formData.append("remark_text", val);

    submitEntry(formData)
      .then((response) => {
        if (response.code == 200) {
          props.onChangeView("thank-you", true);
        }
      }).catch((e) => {

        TosterMessageError({ msg: e });
        // this.props.alert.error(e)

      });
    // props.onChangeView("thank-you", true);
  };

  const cancelFileUploading = event => {

    props.onChangeView("uploadModal", false);
  }
  return (
    <div className="files-list-conatiner">
      <h3 className="title">Upload Challenge</h3>
      <div className="content-wrapper">
        <div className="listbox">
          <div className="file-detail-wrapper">
            <div className="file-detail">
              <span className="file-name">{props.selectedFile.file.name}</span>
              <span className="file-size">{(props.selectedFile.file.size / 1000)} kb</span>
            </div>
          </div>
          <img loading="lazy" className="remove-btn-icon" onClick={cancelFileUploading} alt="" src={RemoveBtnIcon} />
        </div>
        <div className="field-wrapper">
          <textarea
            className="input-field"
            value={val}
            maxLength={maxLength}
            placeholder="Please write something about your submission (max 250 words)"
            onChange={changeTextarea}
            ref={multilineTextarea}
          />
        </div>
      </div>
      <div className="btnWrapper">
        <button
          className={`btn-green ${isDisabled ? "disabled-btn" : ""}`}
          disabled={isDisabled}
          onClick={handleChange}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
export default FilesList;

import { Link } from "react-router-dom";
import "./live-challenges.scss";
import { getAllChallengesRequest } from "../../../api/ApiRequest";
import { useState, useEffect } from "react";
import HtmlParser from "react-html-parser";
import moment from "moment";

//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';
const Live_challenge = () => {
  const [live_challenges, setChallenges] = useState([]);
  const state = "live";
  useEffect(() => {
    getChallenges();
  }, []);

  const live_challenge_params = {
    number_of_pages: 1,
    search_key: "",
    page: 1,
    limit: 1000,
    sort_by: "number",
    sort_order: -1,
    challenges_listing: [],
    filter_type: "live",
  };

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return HtmlParser(value);
  }

  function getChallenges() {
    getAllChallengesRequest(live_challenge_params)
      .then((response) => {
        if (response.code == 200) {
          response.data.forEach((element) => {
            element["entries"] = element["all_entries"]
              ? element["all_entries"].length
              : 0;
            var diff = new Date(element.end_date_timestamp) - new Date();
            element["days_difference"] = Math.round(diff / (1000 * 3600 * 24));
            element["end_date_timestamp"] =
              moment(element["end_date_timestamp"]).format(
                "Do MMM YY, hh:mm A"
              ) + " IST";
            // element['end_date_timestamp'] = element['end_date_timestamp'].split(' ').slice(0, 4).join(' ');
          });
          setChallenges(response.data);
        } else {
        }
      })
      .catch((e) => {
        // this.props.alert.error(e)
      });
  }

  return (
    <div className="challenge-container" id="container">
      <h3 className="num-of-challenges">
        {live_challenges.length} Live Product Challenge(s){" "}
      </h3>
      {live_challenges.map((element, idx) => {
        return (
          <div className="challenges-card" key={idx}>
            <div className="challenges-card-body">
              <div className="challenges-card-body-title">
                <div>
                  <img
                    src={element.logo || element.image}
                    className="challenges-card-body-logo"
                    loading="lazy"
                  ></img>
                </div>
                <h3 className="challenge-name">{element.name}</h3>
              </div>
              <div>
                <span className="title">You are?</span>
                <span className="description">
                  {Parser(element.persona_text)}
                </span>
                <span className="sub-title">The challenge is?</span>
                <div className="sub-description">
                  {/* {} */}
                  {Parser(element.challenge_text)}
                  <Link
                    className="read-more"
                    to={{
                      pathname: `/ChallengeDetails`,
                      search: `?state=${state}&id=${element._id}`,
                    }}
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="entries">
                <span className="title">ENTRIES</span>
                <span className="description">{element.entries} people</span>
              </div>
              <div className="deadline">
                <span className="title">
                  Deadline
                  <span className="days-left">
                    {element.days_difference == 1
                      ? "1 day left"
                      : element.days_difference == 0
                      ? "less than 1 day left"
                      : element.days_difference + " days left"}
                  </span>
                </span>
                <span className="description">
                  {element.end_date_timestamp}
                </span>
              </div>
              <div className="participate">
                <div>
                  <span className="title">Winners get</span>
                  <span className="description">
                    {Parser(element.winners_get_text)}
                  </span>
                </div>
                <div className="btnWrapper">
                  <Link
                    to={{
                      pathname: `/ChallengeDetails`,
                      search: `?state=${state}&id=${element._id}`,
                    }}
                    className="btn-green"
                  >
                    Participate Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Live_challenge;

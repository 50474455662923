import "./past-winning.scss";
import { assets } from "../../../utils/assets";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HtmlParser from "react-html-parser";
//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';
const data = [1, 2, 3];

const PastWinning = (props) => {
  const [isbtnActive, setBtnActive] = useState(false);
  const [past_challenges, setPastChallenges] = useState([]);
  const state = "past";

  useEffect(() => {
    if (props.past_challenges) {
      setPastChallenges(props.past_challenges);
    }
  }, []);

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return HtmlParser(value);
  }

  return (
    <>
      {past_challenges.length > 0 && (
        <div className="past-winning-container">
          <h3 className="header">Some of our past winning entries</h3>
          <div className="winning-card-wrapper">
            {past_challenges.map((item, idx) => {
              return (
                <div className="winning-card-item" key={idx}>
                  <img
                    loading="lazy"
                    src={item.image || assets["cycle"]}
                    alt=""
                    className="winner-card-img"
                  ></img>
                  <div className="content" style={{ flex: 1 }}>
                    <span className="title">{item.name}</span>
                    <span className="description">
                      {Parser(item.challenge_text)}
                    </span>
                    <div className="winning-card-footer">
                      <div className="no-of-winners">
                        <span className="winners-title">
                          {item.all_ranks && item.all_ranks.length} Winners
                          announced
                        </span>
                        <Link
                          to={{
                            pathname: `/ChallengeDetails`,
                            search: `?state=${state}&id=${item._id}`,
                          }}
                          className="view-list view-winners"
                        >
                          View winner’s entry
                        </Link>
                      </div>
                      <div className="img-wrapper">
                        {item.all_ranks &&
                          item.all_ranks.map((item2, idx) => {
                            if (idx < 5) {
                              let profilepicture =
                                item2.user.profile_picture &&
                                item2.user.profile_picture !== ""
                                  ? item2.user.profile_picture
                                  : assets["profile-pic"];
                              return (
                                <img
                                  loading="lazy"
                                  key={idx}
                                  className="winner-pic"
                                  alt=""
                                  src={profilepicture}
                                ></img>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default PastWinning;

import React from "react";
import Student1 from "../../../assets/student1.png";
import "./index.scss";

const TestimonialCard = ({ name, description, position, img }) => {
  return (
    <div className="testimonial__card">
      <div className="student">
        <img loading="lazy" src={img} alt="" />
        <div className="name__post">
          <h3 className="name">{name}</h3>
          <p className="post">{position}</p>
        </div>
      </div>
      <p className="description">{description}</p>
    </div>
  );
};

export default TestimonialCard;

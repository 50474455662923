import React from "react";
import "./logo.scss";

const Logo = (props) => {
  return (
    <div className="logo-main logo-main-2">
      <div className="flexDiv">
        <h3 className="title">Join our challenge and experience yourself!</h3>
        <span className="description">
          Challenges are your gateway to ace your Product management Interviews.
          Test yourself solving real world product problems by submitting
          solutions with wireframes, success metrics.
        </span>
        <a
          href="/product-challenges"
          style={{ textDecoration: "none", color: "unset" }}
          rel="noreferrer"
        >
          <button className="btn-green">Apply Now</button>
        </a>
      </div>
    </div>
  );
};

export default Logo;

import React, { useCallback } from "react";
import "./upload-files.scss";
import { TosterMessageSuccess, TosterMessageError } from "../../Toster/Toster";
// import { FileDrop } from 'react-file-drop';
import { useDropzone } from "react-dropzone";
import { assets } from "../../../utils/assets";
function UploadFiles(props) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    fileChange(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const multilineTextarea = React.useRef(null);
  const [val, setVal] = React.useState("");
  const [maxLength, setMaxLength] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [selectedFile, setFile] = React.useState({});
  const hiddenFileInput = React.useRef(null);
  let isFileSelected = false;
  const changeTextarea = () => {
    const text = multilineTextarea.current.value.split(" ");

    if (text.length > 250) {
      setMaxLength(text.join(" ").length);
    } else if (text.length === 250) {
      setVal(text.join(" "));
    } else {
      setMaxLength(false);
      setVal(text.join(" "));
    }
    multilineTextarea.current.style.height = "auto";
    multilineTextarea.current.style.height =
      multilineTextarea.current.scrollHeight + "px";
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    //file is larger than 4mb then show error
    if (fileUploaded.size > 4194304) {
      TosterMessageError({ msg: "File is bigger than 4mb" });
    } else {
      fileChange(fileUploaded);
    }
  };

  function fileChange(file) {
    setFile({ file: file, remark_text: val });
    setIsDisabled(false);
    props.onSetFile({ file: file, remark_text: val });
    props.onChangeView("fileList", true);
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <div className="upload-files-conatiner">
      <h3 className="title">Upload Challenge</h3>
      <div
        className="dropbox"
        {...getRootProps()}
      >
        {isDisabled && (
          <>
            <img
              className="upload-icon"
              alt=""
              src={assets["upload-icon"]}
              loading="lazy"
            />
            <span className="caption">
              Drop your file here, or{" "}
              <button
                onClick={handleClick}
                className="btn btn-link browse-btn"
              >
                Browse
              </button>
              <input
                {...getInputProps()}
                ref={hiddenFileInput}
                onChange={handleChange}
                accept="application/pdf"
                style={{ display: "none" }}
                type="file"
                className="btn btn-link browse-btn"
              />
            </span>
            <span className="subcaption">PDF support ,max file size 4mb</span>
          </>
        )}
        {/* {!isDisabled && selectedFile && <>
          <img className="upload-icon" alt="" src={UploadIcon} />
          <span className="subcaption">{selectedFile.name}</span> </>} */}
      </div>
      {/* <div className="field-wrapper">
        <textarea
          className="input-field"
          value={val}
          maxLength={maxLength}
          placeholder="Please write something about your submission (max 250 words)"
          onChange={changeTextarea}
          ref={multilineTextarea}
        />
      </div>
      <div className="btnWrapper">
        <button
          className={`btn-green ${isDisabled ? "disabled-btn" : ""}`}
          disabled={isDisabled}
          onClick={() => {
            props.onSetFile(selectedFile);
            props.onChangeView("fileList", true);
          }}
        >
          Submit
        </button>
      </div> */}
    </div>
  );
}
export default UploadFiles;

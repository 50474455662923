import React from "react";
import "./logo.scss";

import { assets } from "../../../utils/assets";

const Logo = (props) => {
  return (
    <div className="logo-main">
      <div className="flexDiv">
        <h3 className="title">Be the next Challenge-master at PM School</h3>
        <span className="description">
          Think you have a buisness problem that will challenge PM aspirants?
        </span>
        <a
          href="mailto:community@pmschool.io"
          style={{ textDecoration: "none", color: "unset" }}
          rel="noreferrer"
        >
          <button className="btn-green">DROP US A LINE</button>
        </a>
      </div>
      <div className="logo-box flexDiv">
        <div className="clgDesc">College partners for Product Challenges</div>

        <div className="clgLogo">
          <img loading="lazy" src={assets["college-1"]} alt="" />
          <img loading="lazy" src={assets["college-2"]} alt="" />
          <img loading="lazy" src={assets["college-3"]} alt="" />
          <img loading="lazy" src={assets["college-4"]} alt="" />
          <img loading="lazy" src={assets["college-5"]} alt="" />
          <img loading="lazy" src={assets["college-6"]} alt="" />
          <img loading="lazy" src={assets["college-7"]} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Logo;

import React from "react";

import { assets } from "../../utils/assets";

const Classes = () => {
  return (
    <section className="classes-wrap">
      <div className="container-wrap">
        <div className="classes-row-wrap">
          <div className="classes-box">
            <div className="classes-row-img">
              <img
                loading="lazy"
                src={assets["classes-images-1"]}
                alt="Cohort Based Product Management Course in India"
              />
            </div>
            <div className="classes-row-content">
              <h3>20+ hours </h3>
              <p>Live Interactions</p>
            </div>
          </div>

          <div className="classes-box">
            <div className="classes-row-img">
              <img
                loading="lazy"
                src={assets.men2}
                alt="Learn with Product Management Courses Online"
              />
            </div>
            <div className="classes-row-content">
              <h3>1 on 1</h3>
              <p>Dedicated Mentorship & Mock Interviews</p>
            </div>
          </div>

          <div className="classes-box">
            <div className="classes-row-img">
              <img loading="lazy" src={assets.laptop} alt="Best Product Management Courses" />
            </div>
            <div className="classes-row-content">
              <h3>08</h3>
              <p>Industry Case Studies & Video content</p>
            </div>
          </div>

          <div className="classes-box">
            <div className="classes-row-img">
              <img
                loading="lazy"
                src={assets.men3}
                alt="Achieve Success with PM Schools Product Management Course"
              />
            </div>
            <div className="classes-row-content">
              <h3>472</h3>
              <p>success stories</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Classes;

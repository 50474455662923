import React, { useEffect } from "react";

import $ from "jquery";
import { assets } from "../../utils/assets";

const VideoContainer = () => {
  const suggestion1 = assets["suggestion-thumb-1"];
  const suggestion2 = assets["suggestion-thumb-2"];
  const suggestion3 = assets["suggestion-thumb-3"];
  const videothumb = assets["active-video-thumb"];
  useEffect(() => {
    $(".round-play-btn").click(function () {
      $(".active-video-wrapper").addClass("play");
      $(".active-video").trigger("play");
    });

    $(".active-video").click(function () {
      if (this.paused) {
        this.play();
      } else {
        $(".active-video-wrapper").toggleClass("play");
        this.pause();
      }
    });
  }, []);

  return (
    <section className="video-container">
      <div className="active-video-wrapper">
        <video
          className="active-video"
          onplay="videoEvents()"
          onpause="videoEvents()"
          poster={videothumb}
        >
          <source
            src={assets["pm_school_video"]}
            type="video/mp4"
          />
        </video>
        <div className="video-caption">
          <h5 className="title">meet us</h5>
          <h3 className="sub-title">
            Who are we and why we started PM School?
          </h3>
          <button className="round-play-btn">Play</button>
        </div>
        <div className="suggestion-wrapper">
          <div className="item">
            <img
              loading="lazy"
              className="sugegstion-img"
              alt=""
              style={{ maxWidth: "none" }}
              src={suggestion1}
            />
            <div className="suggestion-caption">
              <h3 className="title">Ankit Bareja</h3>
              <span className="desc">Cofounder at PM School, ex Games24x7</span>
            </div>
          </div>
          <div className="item">
            <img
              className="sugegstion-img"
              alt=""
              style={{ maxWidth: "none" }}
              src={suggestion2}
              loading="lazy"
            />
            <div className="suggestion-caption">
              <h3 className="title">Nikunj Sharma</h3>
              <span className="desc">AVP at HDFC Life, ex Swiggy</span>
            </div>
          </div>
          <div className="item">
            <img
              className="sugegstion-img"
              alt=""
              style={{ maxWidth: "none" }}
              src={suggestion3}
              loading="lazy"
            />
            <div className="suggestion-caption">
              <h3 className="title">Kushaal Devanahalli</h3>
              <span className="desc">
                Cofounder at Proximity Works, ex GMP
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoContainer;

import ParticipateChallenge from "./participate-challenge";
import AboutChallenges from "./about-challenges";
import PastWinning from "./past-winning";
import Leaderboard from "./leaderboard";
import { getChallengeDetailsRequest } from "../../api/ApiRequest";
import { useState, useEffect } from "react";
import MetaTagsScreen from "../../utils/metaTags";
import { useLocation } from "react-router-dom";

//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';
const ChallengeDetails = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const challenge_id = searchParams.get("id");
  const state = searchParams.get("state");
  // const challenge_id = props.match.params.id;
  const isChellangeActive = state == "live";
  const pastChallenge = state === "past";
  const [challenge, setChallengeData] = useState(null);
  const [ranks, setRanks] = useState([]);
  const [past_challenges, setPastChallenges] = useState([]);
  useEffect(() => {
    // Reset state before fetching new challenge details
    setChallengeData(null);

    // Fetch new challenge details based on updated challenge_id and state
    if (challenge_id && state) {
      getChallengeDetailsRequest({ challenge_id, state })
        .then((response) => {
          const { challenge, ranks, past_challenges, entry } = response.data;
          setChallengeData({
            challenge,
            ranks,
            past_challenges,
            entries: entry || [],
          });
        })
        .catch((error) => {
          console.error("Error fetching challenge details:", error);
          // Handle error state if needed
        });
    }
  }, [challenge_id, state]);

  // const req_data = {
  //   challenge_id: "60ab396f605b514951f2ab6d",
  // }

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return value;
  }

  if (challenge && ranks && past_challenges) {
    return (
      <>
        <MetaTagsScreen
          title="PM Challenge"
          desc={
            challenge.challenge && challenge.challenge.name
              ? challenge.challenge.name.length > 100
                ? `${challenge.challenge.name.substring(0, 100)}...`
                : challenge.challenge.name
              : "PM School Challenge"
          }
          canonicalLink="https://pmschool.io/ChallengeDetails"
        />
        <ParticipateChallenge
          allowSubmission={isChellangeActive}
          showUploadScreen={props.onModalChange}
          isLoggedIn={props.isLoggedIn}
          challenge={challenge.challenge}
          ranks={challenge.ranks}
          setChallengeId={props.setChallengeId}
          entries={challenge.entries}
        />
        {!isChellangeActive && (
          <Leaderboard
            isLoggedIn={props.isLoggedIn}
            setChallengeId={props.setChallengeId}
            showUploadScreen={props.onModalChange}
            challenge={challenge.challenge}
            ranks={challenge.ranks}
            showLeaderboard={isChellangeActive}
          />
        )}
        <AboutChallenges
          isLoggedIn={props.isLoggedIn}
          challenge={challenge.challenge}
        />
        {isChellangeActive && (
          <PastWinning
            isLoggedIn={props.isLoggedIn}
            past_challenges={challenge.past_challenges}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};
export default ChallengeDetails;

import React from 'react';
import {useState, useEffect} from 'react';

const sampleComponent = (props) => {
    return (
        <h1>This is the test!</h1>
    )
}

export default sampleComponent

import React, { useEffect } from "react";
import $ from "jquery";
import "./index.scss";
import ParticipatedCount from "../ParticipatedCount";
import { assets } from "../../../utils/assets";

const Banner = ({
  title,
  description,
  buttonText,
  video,
  bannerCounter,
  newTip,
  enrollLink,
}) => {
  const PlayVideoIcon = assets['play-video'];
  const banner3 = assets['pm-challange-b3'];
  const Participant1 = assets['1-banner'];
  const Participant2 = assets['2-banner'];
  const Participant3 = assets['3-banner'];
  const Participant4 = assets['4-banner'];
  useEffect(() => {
    // setTimeout(() => {
    //     window.aboutUsVideo()
    // }, 200);
    $(document).ready(function () {
      $(".round-play-btn_about").click(function () {
        $(".video-wrapper").addClass("play");
        $(".video").trigger("play");
      });
      $(".video").click(function () {
        if (this.paused) {
          // play();
        } else {
          // pause();
          $(".video-wrapper").toggleClass("play");
          videoEventsAboutUs();
        }
      });
    });
    function videoEventsAboutUs() {
      $(".video-wrapper").toggleClass("play");
    }
  }, []);
  return (
    <section className="homepage-banner">
      {newTip && <div className="newTip">NEW</div>}
      <h3 className="title">{title}</h3>
      <span style={{ marginBottom: "2rem" }} className="description">
        {description}
      </span>
      <div className="button__wrapper">
        <a
          href={enrollLink}
          style={{ textDecoration: "none", color: "unset" }}
          rel="noreferrer"
        >
          <button className="btn-green">{buttonText}</button>
        </a>
        {video && (
          <a href={video} className="video__button" target="_blank">
            <img loading="lazy" src={PlayVideoIcon} alt="" srcset="" />
            <button>Watch Video</button>
          </a>
        )}
      </div>
      {bannerCounter && (
        <ParticipatedCount
          countStart={0}
          countStop={1000}
          title="Enrolled"
          images={[Participant4, Participant3, Participant2, Participant1]}
        />
      )}
    </section>
  );
};

export default Banner;

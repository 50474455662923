import React from "react";
import { assets } from "../../utils/assets";

const Overview = () => {
  return (
    <section className="overview-tiles">
      <div className="row tiles-container">
        <div className="col-6 col-md-3 tile-wrapper">
          <img
            loading="lazy"
            src={assets["overview-tile-1-new"]}
            alt="Image 1"
            className="img -fluid tile-img  w-100"
          />
          <div className="tile-content-wrapper">
            <h4 className="title">460</h4>
            <p className="sub-title text-uppercase m-0">Successful Graduates</p>
          </div>
        </div>
        <div className="col-6 col-md-3 tile-wrapper">
          <img
            loading="lazy"
            src={assets["overview-tile-2-new"]}
            alt="Image 1"
            className="img -fluid tile-img  w-100"
          />
          <div className="tile-content-wrapper">
            <h4 className="title">1200</h4>
            <p className="sub-title text-uppercase m-0">
              PM Challenge Participants
            </p>
          </div>
        </div>
        <div className="col-6 col-md-3 tile-wrapper">
          <img
            loading="lazy"
            src={assets["overview-tile-3-new"]}
            alt="Image 1"
            className="img -fluid tile-img  w-100"
          />
          <div className="tile-content-wrapper">
            <h4 className="title">42</h4>
            <p className="sub-title text-uppercase m-0">Dedicated MENTORS</p>
          </div>
        </div>
        <div className="col-6 col-md-3 tile-wrapper">
          <img
            loading="lazy"
            src={assets["overview-tile-4-new"]}
            alt="Image 1"
            className="img -fluid tile-img  w-100"
          />
          <div className="tile-content-wrapper">
            <h4 className="title">38</h4>
            <p className="sub-title text-uppercase m-0">Companies</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Overview;

import React from "react";

import { assets } from "../../utils/assets";

const Banner = () => {
  let date = localStorage.getItem("cohortDate");
  return (
    <section className="banner-wrap testimonial">
      <div className="banner-images">
        <div className="banner-content">
          <div className="container-wrap">
            <h1>472 Product manager transitions in 3 years</h1>
            <p className="description">
              Only 4 steps to get a Product Manager role
            </p>
            <a
              href="https://forms.gle/HSbYWKHVCcfCGoMy6"
              style={{ textDecoration: "none", color: "white" }}
              target="_blank"
              rel="noreferrer"
              className="button-hover"
            >
              Apply now <i className="fa fas fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

import "./past-challenges.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllChallengesRequest } from "../../../api/ApiRequest";
import HtmlParser from "react-html-parser";
import InfiniteScroll from "react-infinite-scroll-component";


//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';
import { assets } from "../../../utils/assets";

let past_challenge_params = {
  number_of_pages: 1,
  search_key: "",
  page: 1,
  limit: 15,
  sort_by: "number",
  sort_order: -1,
  challenges_listing: [],
  filter_type: "past",
};

let current_filters = [];

const Past_challenge = (props) => {
  const [live_challenges, setChallenges] = useState(props.challenges || []);
  const [filters, setFilters] = useState(props.filters || []);
  const [page,setPage]=useState(1);
  const [totalPage,setTotalPage]=useState(Infinity);
  const [filterIsActive, setFilterActive] = useState(false);
  const [filerCount, setFilterCount] = useState(0);
const state= "past"
  // useEffect(() => {
  //   getChallenges();
  // }, []);

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return HtmlParser(value);
  }

  function getChallenges() {
    past_challenge_params["type_filters"] = current_filters;

    getAllChallengesRequest(past_challenge_params).then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          // element['end_date_timestamp'] = new Date(element['end_date_timestamp']).toDateString();
          element["end_date_timestamp"] = new Date(
            element["end_date_timestamp"]
          ).toUTCString();
          element["end_date_timestamp"] = element["end_date_timestamp"]
            .split(" ")
            .slice(0, 4)
            .join(" ");
          element["entries"] = element["all_entries"]
            ? element["all_entries"].length
            : 0;
        });
        setChallenges(response.data);
        if (response.filters) {
          setFilters(response.filters);
        }
      }
    });
  }

  async function getMoreChallengesRequest(){
    let params = {
      number_of_pages: 1,
      search_key: "",
      page: page,
      limit: 10,
      sort_by: "number",
      sort_order: -1,
      challenges_listing: [],
      filter_type: "past",
    };
    params["type_filters"] = current_filters;
    // console.log('isFilter',{filerCount,page})
    await getAllChallengesRequest(params).then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          // element['end_date_timestamp'] = new Date(element['end_date_timestamp']).toDateString();
          element["end_date_timestamp"] = new Date(
            element["end_date_timestamp"]
          ).toUTCString();
          element["end_date_timestamp"] = element["end_date_timestamp"]
            .split(" ")
            .slice(0, 4)
            .join(" ");
          element["entries"] = element["all_entries"]
            ? element["all_entries"].length
            : 0;
        });
        if(filerCount===0){
          if(page===1){
            setChallenges(response.data);
            setTotalPage(response.totalCount);
          }else{
            setChallenges([...live_challenges,...response.data]);
            setTotalPage(response.totalCount);
          }
        }else{
          if(page===1){
            setChallenges(response.data);
            setTotalPage(response.totalCount);
          }else{
            setChallenges([...live_challenges,...response.data]);
            setTotalPage(response.totalCount);
          }
        }
        if (response.filters) {
          setFilters(response.filters);
        }
        setPage((prePage)=>prePage+1);
      }
    }).catch((e)=>{
      console.log({e})
    });
  }

  function toggleActive(e, item) {
    e.target.classList.contains("active")
      ? e.target.classList.remove("active")
      : e.target.classList.add("active");

    e.target.classList.contains("active")
      ? setFilterCount(filerCount + 1)
      : setFilterCount(filerCount - 1);

    e.target.classList.contains("active")
      ? current_filters.push(item)
      : (current_filters = current_filters.filter(function (elem) {
          return elem !== item;
        }));

    // getChallenges();
    // getMoreChallengesRequest()
    setPage(1);
  }

  useEffect(()=>{
    (async function () {
      await getMoreChallengesRequest();
    })();
  },[filerCount])

  return (
    <div className="past-challenges-container">
      <div className="header-wrapper">
        <h3 className="title">Past Product Challenges</h3>
        <div className="filter-wrapper">
          {!filterIsActive && filerCount > 0 ? (
            <div className="filter-count-wrapper">
              <span className="filter-count">{filerCount}</span>
            </div>
          ) : null}
          <span className="filter">Filter</span>
          <img
            loading="lazy"
            className={`filter-icon ${filterIsActive ? "open" : ""}`}
            onClick={() => {
              setFilterActive(!filterIsActive);
            }}
            src={filterIsActive ? assets["filter-cross-icon"] : assets["filter-icon"]}
            alt=""
          />
        </div>
      </div>
      <div className={`filter-list ${filterIsActive ? "" : "d-none"}`}>
        {filters.map((item, idx) => {
          return (
            <button
              className="filter-btn"
              key={idx}
              onClick={(e) => toggleActive(e, item)}
            >
              {item}
            </button>
          );
        })}
      </div>
      <InfiniteScroll
        dataLength={live_challenges.length}
        next={getMoreChallengesRequest}
        hasMore={live_challenges.length<totalPage}
        loader={<div className="dot-loader"></div>}
      >
      {live_challenges.map((element, idx) => {
        return (
          <div
            className="card-wrapper"
            style={{ marginTop: "0.30417rem" }}
            key={idx}
          >
            <div className="challenge-logo">
              <img
                loading="lazy"
                src={element.logo || element.image}
                className="challenges-card-body-logo-past"
              ></img>
            </div>
            <div className="challenger-details">
              <span className="title">{element.name}</span>
              <span className="description">
                {Parser(element.challenge_text)}
              </span>
            </div>
            <div className="entries">
              <span className="title">ENTRIES</span>
              <span className="description">{element.entries} people</span>
            </div>
            <div className="challenged-closed">
              <span className="title">Challenge closed</span>
              <span className="description">{element.end_date_timestamp}</span>
            </div>
            <div className="winner-details">
              <div className="winner-list">
                <div className="imgWrapper">
                  {element.all_ranks &&
                    element.all_ranks.map((item, idx) => {
                      if (idx < 5) {
                        let profilepicture =
                          item.user.profile_picture &&
                          item.user.profile_picture !== ""
                            ? item.user.profile_picture
                            : assets["profile-pic"];
                        return (
                          <img
                            loading="lazy"
                            key={idx}
                            className="winner-pic"
                            alt=""
                            src={profilepicture}
                          ></img>
                        );
                      }
                    })}
                </div>
                <Link
                // to={"/ChallengeDetails/past/" + element._id}
                to={{
                  pathname: `/ChallengeDetails`,
                  search: `?state=${state}&id=${element._id}`,
                }}
                  className="view-list"
                >
                  View winner’s entry
                </Link>
              </div>
              <Link
                // to={"/ChallengeDetails/past/" + element._id}
                to={{
                  pathname: `/ChallengeDetails`,
                  search: `?state=${state}&id=${element._id}`,
                }}
                className="btnright"
              >
                <img loading="lazy" src={assets["right-circle-btn"]} alt="" style={{ maxWidth: "none" }} />
              </Link>
            </div>
          </div>
        );
      })}
      </InfiniteScroll>
    </div>
  );
};
export default Past_challenge;

import { useEffect, useState } from "react";
import { HelmetProvider,Helmet } from 'react-helmet';
import { getCertificateRequest, resumeLink } from "../../../api/ApiRequest";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import addDynamicText from "../../../utils/dynamicImage";

export const Upload = () => {
    const [certificateUrl, setCertificateUrl] = useState("");
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const programId = urlParams.get("pmRunProgramTypeId");
    const day = urlParams.get("day");
    const userId = urlParams.get("userId");
    const [userName, setUserName] = useState('');
    const baseUrl = window.location.origin;
    const shareLink = `${baseUrl}/pm-run/social-upload?day=${day}&pmRunProgramTypeId=${programId}&userId=${userId}`;

    const getCertificate = async (params) => {
        return getCertificateRequest(params)
            .then((response) => {
                if (response.code === 200) {
                    resumeLink(userId).then((res) => {
                        if (res.code === 200) {
                            setUserName(res.data.name);
                            addDynamicText(day, response.data[0], res.data.name).then((res) => {
                                setCertificateUrl(res);
                            });
                        }
                    });
                }
            })
            .catch((e) => {
                console.log("err:", e);
            });
    };
    useEffect(() => {
        (async function () {
            await getCertificate({
                day: day,
                pmRunProgramTypeId: programId,
                userId: userId,
            });
        })();
    }, []);
    return (
        <div>
            <Helmet>
                <title>PM School Certificate</title>
                <meta
                    property="og:title"
                    content={`PM Run Certificate Of Day ${day}`}
                />
                <meta
                    property="og:description"
                    content={`PM Run Certificate Of Day ${day}`}
                />
                <meta property="og:image" content={certificateUrl} />
                <meta property="og:url" content={shareLink} />
                <meta name="twitter:title" content={`PM Run Certificate Of Day ${day}`} />
                <meta name="twitter:description" content={`PM Run Certificate Of Day ${day}`} />
                <meta name="twitter:image" content={certificateUrl} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            {certificateUrl && (
                <img loading="lazy" src={certificateUrl} alt="" width="100%" height="auto" />
            )}
        </div>
    );
};
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./landing-banner.scss";
import CountUp from "react-countup";

import { getUserCount } from "../../../api/ApiRequest";
import { assets } from "../../../utils/assets";

function LandingBanner() {
  let windowType = "";

  if (window.innerWidth > 767 && window.innerWidth < 1200) {
    windowType = "tab";
  } else if (window.innerWidth < 768) {
    windowType = "mobile";
  }

  const [hideKey, setHideKey] = useState("");
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    // getallUserCount();
  }, []);

  function getallUserCount() {
    getUserCount()
      .then((response) => {
        if (response.code == 200) {
          if (response.data) {
            setUserCount(response.data);
          }
        } else {
        }
      })
      .catch((e) => {
        // this.props.alert.error(e)
      });
  }

  const cardInfoGreen = [
    {
      title: "What are PM School challenges",
      desc: "Challenges are your gateway to ace your Product management Interviews. Test yourself solving real world product problems by submitting solutions with wireframes, success metrics. See how you perform against other talented PM aspirants. You benefit by adding challenges on resume when applying for product roles",
    },
    {
      title: "Who should enter them?",
      desc: "Aspiring Product managers trying to crack their first Product manager role should participate in our Weekly Challenges to win 1-1 session(s) with experienced product managers and exciting rewards. Existing Product managers can test themselves in PM problems and see how they benchmark with other PMs",
    },
    {
      title: "How is it helpful to PMs?",
      desc: "Participate in challenges to create your own Product portfolio and showcase it to potential employers! Winning participants get to connect with PM School Mentors for Networking, Referrals or Mock Interviews",
    },
  ];

  const cardInfoYellow = [
    {
      title: "How does hosting a PM School Challenge help?",
      desc: "Companies conduct Product problems to access Top PM candidates or to get Feature ideas. Startups also create awareness in the PM School community. Colleges host case challenges with PM School to upskill students.",
    },
    {
      title: "How to set up PM School Challenge?",
      desc: "Write to us on community@pmschool.io with your requirements and our Product management experts will help with next steps.",
    },
    {
      title: "Who all can host a PM School Challenge?",
      desc: "Company HRs or Product Managers trying to hire a PM can host a challenge. Also, Product communities at Colleges can conduct a Product Challenge for PM aspirants.",
    },
  ];

  const hideBanner = (key) => {
    setHideKey(key);
  };

  function scroll() {
    var myElement = document.getElementById("container");
    let topPos = myElement.offsetTop;
    window.scrollTo(0, topPos);
  }

  return (
    <div className="pm-challange-banner-main" id="banner-div">
      {hideKey === "left" ? (
        <div className="hideLeft">
          <img
            src={assets["btn-right-arrow-png"]}
            alt=""
            className="hideArrow"
            onClick={() => hideBanner("")}
            loading="lazy"
          />
        </div>
      ) : (
        <div className="pm-challange-bg-1">
          <img
            loading="lazy"
            src={
              windowType
                ? windowType === "mobile"
                  ? assets.bluebanner
                  : assets["banner768-1"]
                : hideKey === "right"
                ? assets["challenge-1-full"]
                : assets.challenge_banner_1
            }
            className="Mask-Group-234"
            width={2880}
            height={2949}
          />
          <div className="contentDiv">
            <div className="pm-challange-content-1">
              Know about PM School’s Product Challenges
            </div>
            <div className="pm-challange-content-2">
              Join our clan of Challenge Takers
            </div>
            <div className="pm-challange-content-3">
              <button className="pm-challange-btn white" onClick={scroll}>
                Take a challenge
              </button>
            </div>

            {(hideKey === "right" || windowType) && (
              <section className="card-wrapper">
                {cardInfoGreen.map((element, idx) => {
                  return (
                    <div className="card" key={idx}>
                      <img loading="lazy" className="card-img" src={assets["challenges-dots-img"]} alt="" />
                      <h3 className="card-title">{element.title}</h3>
                      <p className="card-desc">{element.desc}</p>
                    </div>
                  );
                })}
              </section>
            )}
          </div>

          {hideKey === "right" ? null : (
            <React.Fragment>
              {/* <div className="pm-challange-content-4">
							<span className="pm-challange-content-4-count">{userCount} +</span>
							<span className="pm-challange-content-4-content">PARTICIPATED IN A CHALLENGE</span>
							</div> */}
              {!windowType && (
                <>
                  <span className="banner-counter">
                    <CountUp start={2000} end={3107} duration={5} /> +
                  </span>
                  <img loading="lazy" src={assets["pm-challange-b3"]} className="pm-challange-content-4" />
                </>
              )}
              <img
                src={assets["btn-left-arrow"]}
                alt=""
                className="arrow right"
                onClick={() => hideBanner("left")}
                loading="lazy"
              />
            </React.Fragment>
          )}
        </div>
      )}

      {hideKey === "right" ? (
        <div className="hideRight">
          <img
            src={assets["btn-left-arrow"]}
            alt=""
            className="hideArrow"
            onClick={() => hideBanner("")}
            loading="lazy"
          />
        </div>
      ) : (
        <div className="pm-challange-bg-2">
          {hideKey === "left" ? null : (
            <img
              src={assets["btn-right-arrow-png"]}
              alt=""
              className="arrow left"
              onClick={() => hideBanner("right")}
              loading="lazy"
            />
          )}
          <div className="contentDiv">
            <div className="pm-challange-content-1">
              Conduct a Challenge with us
            </div>
            <div className="pm-challange-content-2">
              Share a Product assignment to hire from PM School
            </div>
            <div className="pm-challange-content-3">
              <Link
                to="#"
                className="pm-challange-btn"
                onClick={(e) => {
                  window.location = "mailto:community@pmschool.io";
                  e.preventDefault();
                }}
              >
                Drop Us A Mail
              </Link>
            </div>

            {(hideKey === "left" || windowType) && (
              <div className="card-wrapper">
                {cardInfoYellow.map((element, idx) => {
                  return (
                    <div className="card" key={idx}>
                      <img loading="lazy" className="card-img" src={assets["challenges-dots-img"]} alt="" />
                      <h3 className="card-title">{element.title}</h3>
                      <p className="card-desc">{element.desc}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <img
            src={
              windowType
                ? windowType === "mobile"
                  ? assets.yellowbanner
                  : assets["banner768-2"]
                : hideKey === "left"
                ? assets["challenge-2-full"]
                : assets.challenge_banner_2
            }
            className="Mask-Group-234"
            loading="lazy"
          />
          {/* <a className="pm-challange-btn" target = "_blank" href="mailto:email@example.com">
                      Drop Us A Mail
                      </a> */}
          {/* <a className="pm-challange-btn" href="mailto:info@pmschool.io?subject=Mail from our Website&body=Dear W3docs Team">Send Email</a> */}
        </div>
      )}
    </div>
    // <div className="welcome-container">
    //   <div className="about-challenges">
    //     <h3 className="title">Welcome to PM School’s challenges</h3>
    //     <div className="card-wrapper">
    //       {cardInfo.map((element, idx) => {
    //         return (
    //           <div className="card" key={idx}>
    //             <img className="card-img" src={challengesDotImg} alt="" />
    //             <h3 className="card-title">{element.title}</h3>
    //             <p className="card-desc">{element.desc}</p>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   </div>
    //   {false && <div className="winner-wrapper">
    //     <div className="winner-announced">
    //       <img className="winner-icon" src={winnerIcon} alt="" />
    //       <div>
    //         <h3 className="title">5 Winners announced!</h3>
    //         <span className="desc">
    //           Challenge no. 45 - Intercity Bus Service for Ola cabs
    //         </span>
    //       </div>
    //     </div>
    //     <div className="winner-details">
    //       <div className="winner-list">
    //         <div className="imgWrapper">
    //           <img className="winner-pic" src={winnerpic5} alt="" />
    //           <img className="winner-pic" src={winnerpic4} alt="" />
    //           <img className="winner-pic" src={winnerpic3} alt="" />
    //           <img className="winner-pic" src={winnerpic2} alt="" />
    //           <img className="winner-pic" src={winnerpic1} alt="" />
    //         </div>
    //         <a href="#top" className="view-list">
    //           View winner’s entry
    //         </a>
    //       </div>
    //       <a href="#top" className="btnright">
    //         <img src={rightCircleBtn} alt="" />
    //       </a>
    //     </div>
    //   </div>}
    // </div>
  );
}
export default LandingBanner;

import React, { useEffect } from "react";
import $ from "jquery";

const Banner = () => {
  useEffect(() => {
    // setTimeout(() => {
    //     window.aboutUsVideo()
    // }, 200);
    $(document).ready(function () {
      $(".round-play-btn_about").click(function () {
        $(".video-wrapper").addClass("play");
        $(".video").trigger("play");
      });
      $(".video").click(function () {
        if (this.paused) {
          // play();
        } else {
          // pause();
          $(".video-wrapper").toggleClass("play");
          videoEventsAboutUs();
        }
      });
    });
    function videoEventsAboutUs() {
      $(".video-wrapper").toggleClass("play");
    }
  }, []);
  return (
    <section className="homepage-banner">
      <h3 className="title">
        Step into the shoes of Product Manager with confidence
      </h3>
      <span style={{ marginBottom: "2rem" }} className="description">
        Kickstart your Product Management career. Apply to level up your
        learning!
      </span>
      <a
        href="/product-management-course"
        style={{ textDecoration: "none", color: "unset" }}
        rel="noreferrer"
      >
        <button className="btn-green">Explore the PM Course</button>
      </a>
    </section>
  );
};

export default Banner;

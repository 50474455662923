export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_API = BASE_URL + "api/";

export const LOGIN = "admin/login";
export const CHANGEPASSWORD = "admin/change_password";
export const SIGNUP = "admin/register";
export const LOGOUT = "admin/logout";
export const SOCIALLOGIN = "admin/social_login";
export const GETUSERDETAILS = "admin/get_user_details";
export const FORGOTPASSWORD = "admin/reset_password";
export const ADDCHALLENGE = "admin/challenge/add_challenge";
export const GETCHALLENGE = "admin/challenge/get_challenge";
export const GETALLCHALLENGES = "admin/challenge/get_all_challenges";
export const GETCHALLENGEDETAILS = "admin/challenge/get_challenge";
export const GETCHALLENGEENTRIES = "admin/entries/get_entries";
export const UPDATERANK = "/admin/entries/update_rank";
export const SUBMITTOLIVE = "/admin/entries/submit_to_live";
export const GETLEADERBOARD = "/admin/entries/get_leaderboard";
export const GETPROFILEDETAILS = "admin/get_profile_details";
export const SUBMITENTRY = "/admin/entries/submit_entry";
export const COHORTDATE = "/admin/config/get_cohort_date";
export const GETUSERCOUNT = "admin/count_user";
export const ADDDRIVELINK = "/admin/entries/add_drive_link";
export const UPDATEPROFILE = "/admin/update_profile";
export const PUBLICPROFILE = "/admin/public_profile";
export const RUNMASTER = "/admin/pmrun/getRunMaster";
export const QUESTIONDATA = "/admin/pmrun/getUserQuestionData";
export const SUBMITQUESTION = "admin/pmrun/submituserquestions";
export const GETCERTIFICATE = "admin/pmrun/fetchCertificate";
export const QUESTIONHISTORYDATA ="admin/pmrun/getUserQuestionHistory"
export const COUPONCODE ="admin/pmrun/getDiscountCoupons"
export const GETCERTIFICATEBYID="admin/pmrun/certificate"
export const DYNAMICCERTIFICATE="admin/pmrun/dynamicCertificate"
export const RENDERCERTIFICATE="admin/pmrun/renderCertificate"
export const GETCERTIFICATEIMAGE="admin/pmrun/certificateImage"

export const EMAIL = "email";
export const PASSWORD = "password";
export const NAME = "name";
export const DEVICEOSVERSION = "device_os_version";
export const DEVICEOS = "device_os";
export const DEVICETOKEN = "device_token";
export const DEVICENAME = "device_name";
export const PROVIDERID = "provider_id";
export const PROVIDER = "provider";
export const PROFILEPIC = "profile_pic";
export const PROVIDERTOKEN = "provider_token";
export const ROLE = "role";

import React from "react";

import moment from "moment";
import { assets } from "../../utils/assets";

const Banner = () => {
  let date = localStorage.getItem("cohortDate");
  return (
    <section className="banner-wrap">
      <div className="banner-images">
        <img loading="lazy" src={assets["banner-img"]} className="desktopbanner" alt="Banner Images" />
        <img loading="lazy" src={assets["iped-banner"]} alt="Banner Images" className="ipedbanner" />
        <img loading="lazy" src={assets["mobilebanner-img"]} alt="Banner Images" className="mobilebanner" />
        <div className="banner-content">
          <div className="container-wrap">
            <h1>
              Reinvent your career with a Product Management Course at PM School
            </h1>
            <ul className="marks">
              <li>
                <p>Course Duration</p>
                <span>2 months</span>
              </li>
              <li>
                <p>Next Cohort</p>
                <span>{date ? date : ""}</span>
              </li>
              <li>
                <p>Placement</p>
                <span>Support for 1 year</span>
              </li>
            </ul>
            <a
              href="https://forms.gle/HSbYWKHVCcfCGoMy6"
              style={{ textDecoration: "none", color: "white" }}
              target="_blank"
              rel="noreferrer"
              className="button-hover"
            >
              Apply now <i className="fa fas fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

import React from "react";
import "./Loader.scss";
import img from "../../../assets/logo/Union 1.svg";

const Box_container = () => {
  return (
    <div className="Rectangle-6016">
      <div className="Rectangle-6018"></div>
      <div className="Rectangle-6019"></div>
      <div className="Rectangle-6020"></div>
      <div className="container4">
        <div className="Ellipse-839"></div>
        <div className="Rectangle-6021"></div>
      </div>
      <div className="container4">
        <div className="Ellipse-840"></div>
        <div className="Rectangle-6022"></div>
      </div>
      <div className="container4">
        <div className="Ellipse-841"></div>
        <div className="Rectangle-6023"></div>
      </div>
      <div className="container5">
        <div className="Rectangle-6024"></div>
        <div className="Rectangle-6025"></div>
      </div>
    </div>
  );
};

const Loader = () => {
  return (
    <div className="Loader">
      <div className="container1">
        <div className="container1_internal">
          <div className="Rectangle-6008" />
          <div className="Rectangle-6009" />
          <div className="Rectangle-6010" />
          <div className="Rectangle-6011" />
          <div className="Rectangle-6012" />
          <div className="container2">
            <div className="Ellipse-837"></div>
            <div className="Rectangle-6014" />
            <div className="Ellipse-838"></div>
            <div className="Rectangle-6015" />
          </div>
        </div>
        <div className="Rectangle-6013">
          <img
            loading="lazy"
            src={img}
            className="Union-1"
            alt="img"
          ></img>
        </div>
      </div>
      <div className="container5">
        <Box_container />
        <Box_container />
      </div>
      <div className="Rectangle-6035">
        <div className="Rectangle-6036" />
        <div className="Rectangle-6037" />
        <div className="Rectangle-6038" />
        <div className="container5">
          <div className="Rectangle-6042">
            <img
              loading="lazy"
              src={img}
              className="Union-3"
              alt="img"
            ></img>
          </div>
          <div className="Rectangle-6042">
            <img
              loading="lazy"
              src={img}
              className="Union-3"
              alt="img"
            ></img>
          </div>{" "}
          <div className="Rectangle-6042">
            <img
              loading="lazy"
              src={img}
              className="Union-3"
              alt="img"
            ></img>
          </div>{" "}
          <div className="Rectangle-6042">
            <img
              loading="lazy"
              src={img}
              className="Union-3"
              alt="img"
            ></img>
          </div>
        </div>
      </div>
      <div className="Rectangle-6047">
        <div className="Rectangle-6051"></div>
        <div className="Rectangle-6050"></div>
        <div className="Rectangle-6049"></div>
        <div className="Rectangle-6048"></div>
        <div className="Rectangle-6052"></div>
      </div>
      <div className="Rectangle-6054"></div>
      <div className="container3">
        <div className="Rectangle-6055"></div>
        <div className="Ellipse-845"></div>
      </div>
      <div className="container3">
        <div className="Rectangle-6053"></div>
        <div className="Ellipse-846"></div>
      </div>
      <div className="container3">
        <div className="Rectangle-6056"></div>
        <div className="Ellipse-847"></div>
      </div>
      <div className="container3">
        <div className="Rectangle-6057"></div>
        <div className="Ellipse-848"></div>
      </div>
      <div className="container3">
        <div className="Rectangle-6058"></div>
        <div className="Ellipse-849"></div>
      </div>
      <div className="container3">
        <div className="Rectangle-6059"></div>
        <div className="Ellipse-850"></div>
      </div>
      <div className="container3">
        <div className="Rectangle-6060"></div>
        <div className="Ellipse-851"></div>
      </div>
      <div className="container3">
        <div className="Rectangle-6061"></div>
        <div className="Ellipse-852"></div>
      </div>
    </div>
  );
};

export default Loader;

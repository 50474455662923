import React from "react";
import { assets } from "../../utils/assets";

const WhyPmSchool = () => {
  const listItem1 = assets['list-item-img-1'];
  const listItem2 = assets['list-item-img-2'];
  const listItem3 = assets['list-item-img-3'];
  const listItem4 = assets['list-item-img-4'];
  return (
    <section className="why-pm-school">
      <span className="title">
        Why PM School for Product Management Certification Program?
      </span>
      <span className="subtitle">Empowering students for path to success</span>
      <div className="list-wrapper row no-gutters">
        <div className="item-wrapper col-6 col-md-3">
          <p className="item-content">
            Guided self-growth through Weekly Challenges
          </p>
          <img
            loading="lazy"
            className="item-img"
            src={listItem1}
            alt="PM School Product Management Certification"
          />
        </div>
        <div className="item-wrapper col-6 col-md-3">
          <p className="item-content">
            2 months exclusive Live Program to transition you to a Product
            manager
          </p>
          <img
            loading="lazy"
            className="item-img"
            src={listItem2}
            alt="Live Product Management Certification Program"
          />
        </div>
        <div className="item-wrapper col-6 col-md-3">
          <p className="item-content">
            Problem Solving with battle-tested Product Manager Mentors
          </p>
          <img
            loading="lazy"
            className="item-img"
            src={listItem3}
            alt="Certification for Product Managers"
          />
        </div>
        <div className="item-wrapper col-6 col-md-3">
          <p className="item-content">
            Life time connections with Industry experts
          </p>
          <img
            loading="lazy"
            className="item-img"
            src={listItem4}
            alt="Cohort based Product Management Program"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyPmSchool;

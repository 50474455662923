import React, { useEffect } from "react";
import { assets } from "../../utils/assets";

const Learning = () => {
  const slider = assets["slider-planning"];
  const sliderImg = assets["slider-images"];
  const skills = assets["analytical-skills"];
  const thinking = assets["design-thinking"];
  const retention = assets["retention-focus"];
  const agile = assets["agile-planning"];
  const problem = assets["problem-framing"];
  useEffect(() => {
    window.call_learning_slide();
  }, []);
  return (
    <section className="learning-experience-wrap">
      <div className="container-wrap">
        <div className="slider-title">
          <h4>Learning Experience</h4>
        </div>
        <div
          className="slider-wrapper"
          id="slick-1"
        >
          <div className="slider-title">
            <h4>Learning Experience</h4>
          </div>
          <ul className="slider-nav-course">
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link active"
                data-slick-index="0"
              >
                <span className="icon">
                  <img
                    src={slider}
                    align="icon images"
                    loading="lazy"
                  />
                </span>
                Technology Basics
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="1"
              >
                <span className="icon">
                  <img
                    src={slider}
                    align="icon images"
                    loading="lazy"
                  />
                </span>
                Problem Framing
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="2"
              >
                <span className="icon">
                  <img
                    src={slider}
                    align="icon images"
                    loading="lazy"
                  />
                </span>
                Analytical Skill-set
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="3"
              >
                <span className="icon">
                  <img
                    src={slider}
                    align="icon images"
                    loading="lazy"
                  />
                </span>
                Design Thinking
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="4"
              >
                <span className="icon">
                  <img
                    src={slider}
                    align="icon images"
                    loading="lazy"
                  />
                </span>
                Retention Focus
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="slider-nav-course-link"
                data-slick-index="5"
              >
                <span className="icon">
                  <img
                    src={slider}
                    align="icon images"
                    loading="lazy"
                  />
                </span>
                Agile Planning
              </a>
              <div className="slider-progress">
                <div className="progress"></div>
              </div>
            </li>
          </ul>

          <div className="learning-experience-slider">
            <div className="learning-slider">
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={sliderImg}
                    alt="Primer on All things Technology"
                    loading="lazy"
                  />
                </div>
                <div className="slid-content">
                  <h2>Technology basics</h2>
                  <p>
                    Know about APIs vs Webhooks, Native and Hybrid apps. Get a
                    primer on Databases, Front-end, Back-end frameworks
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={problem}
                    alt="Problem Framing and Building for Success"
                    loading="lazy"
                  />
                </div>
                <div className="slid-content">
                  <h2>Problem framing</h2>
                  <p>
                    Getting to the core of a user’s problem. Sizing up the
                    opportunity. Building for success
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={skills}
                    alt="Achieve Analytical Skill Set through Our PM Course"
                    loading="lazy"
                  />
                </div>
                <div className="slid-content">
                  <h2>Analytical skills</h2>
                  <p>
                    Define success metrics and interpret user behavior, learn
                    how to conduct experiments
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={thinking}
                    alt="PM Course to Help with Design Thinking"
                    loading="lazy"
                  />
                </div>
                <div className="slid-content">
                  <h2>Design thinking</h2>
                  <p>
                    Study user personas and prototyping. Learn to conduct user
                    interviews, plan design sprints, define user journeys, and
                    about a PM’s relationship with UX/UI teams
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={retention}
                    alt="Learn Product Growth Levers within Our PM Course"
                    loading="lazy"
                  />
                </div>
                <div className="slid-content">
                  <h2>Retention focus</h2>
                  <p>
                    Figure how to measure cohorts, LTV and slice user groups to
                    define behaviour for possible opportunities towards product
                    growth levers
                  </p>
                </div>
              </div>
              <div className="slide">
                <div className="slid-images">
                  <img
                    src={agile}
                    alt="Acquire knowledge about OKRs, Scrum Planning, Sprints etc."
                    loading="lazy"
                  />
                </div>
                <div className="slid-content">
                  <h2>Agile planning</h2>
                  <p>
                    Learn Roadmap prioritisation, setting up OKRs, conducting
                    engineering ceremonies such as daily scrums, sprint
                    planning, backlog grooming, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Learning;

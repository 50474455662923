import React from "react";
import CountUp from "react-countup";
import "./index.scss";

const ParticipatedCount = ({
  countStart,
  countStop,
  countDuration,
  title,
  images,
}) => {
  return (
    <div className="participated__count">
      <div className="firstFour__userImg">
        {images.map((image, index) => (
          <img loading="lazy" src={image} alt="" key={index} />
        ))}
      </div>
      <div className="participated__number">
        <span className="counter">
          <CountUp
            start={countStart}
            end={countStop}
            duration={5}
            style={{
              position: "absolute",
              left: 0,
            }}
          />{" "}
          <span
            style={{
              opacity: 0,
            }}
          >
            {countStop}
          </span>{" "}
          +
        </span>
        <span className="participated__text">{title}</span>
      </div>
    </div>
  );
};

export default ParticipatedCount;

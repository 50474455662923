import React from "react";
import "./index.scss";
import { assets } from "../../../utils/assets";

const LogoContainer = () => {
  const AtlassianLogo=assets['Atlassian'];
  const BayjusLogo=assets['Byjus'];
  const OlaLogo=assets['OLA'];
  const ZetaLogo=assets['Zeta'];
  const HousingLogo=assets['housing'];
  const CashfreeIcon=assets['Cashfre_payments'];
  const HighradiusLogo=assets['Highradius'];
  const YourstoryLogo=assets['Yourstory'];
  const ClassplusLogo=assets['Classplus'];
  const SharechatLogo=assets['ShareChat'];
  const MplLogo=assets['MPL'];
  const YuluLogo=assets['Yulu'];
  const KhatabookLogo=assets['Khatabook'];
  const MindtickleLogo=assets['mindtickle'];
  const HaptikLogo=assets['Haptik']
  return (
    <div className="logo__container">
      <h2 className="title">
        PM School is trusted by 500+ top companies in India
      </h2>
      <div className="logos__wrapper">
        <img loading="lazy" src={AtlassianLogo} alt="" />
        <img loading="lazy" src={BayjusLogo} alt="" />
        <img loading="lazy" src={OlaLogo} alt="" />
        <img loading="lazy" src={ZetaLogo} alt="" />
        <img loading="lazy" src={HousingLogo} alt="" />
        <img loading="lazy" src={CashfreeIcon} alt="" />
        <img loading="lazy" src={HighradiusLogo} alt="" />
        <img loading="lazy" src={YourstoryLogo} alt="" />
        <img loading="lazy" src={ClassplusLogo} alt="" />
        <img loading="lazy" src={SharechatLogo} alt="" />
        <img loading="lazy" src={MplLogo} alt="" />
        <img loading="lazy" src={YuluLogo} alt="" />
        <img loading="lazy" src={KhatabookLogo} alt="" />
        <img loading="lazy" src={MindtickleLogo} alt="" />
        <img loading="lazy" src={HaptikLogo} alt="" />
      </div>
    </div>
  );
};

export default LogoContainer;

import React from "react";
import { assets } from "../../utils/assets";
function Companies({ title, ...props }) {
  return (
    <>
      <div className="logos" {...props}>
        <h1 style={{ textAlign: "center" }}>
          <b>{title || "Companies that have hired from us!"}</b>
        </h1>
        <div
          className="d-flex justify-content-center"
          style={{ margin: "5rem", alignItems: "center" }}
        >
          <div className="col-sm-2">
            <img loading="lazy" src={assets['AF165F2F-E951-4CD3-AAE9-1B9287849F9A']} alt="make my trip" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['7E91A5AF-BBB1-4F1D-9F11-BB5F8CFABE7C']} alt="byjus" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['5FF333A4-5C1D-41B6-B4D5-871C3E830E96']} alt="ola" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['9DDE2988-DD98-4D48-99DE-D8C5E62D66B6']} alt="zelta" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['B81B3533-3F71-4B96-8394-D99B11006A45']} alt="drip" />
          </div>
        </div>
      </div>

      <div className="logos" style={{ margin: "5rem" }}>
        <div
          className="d-flex justify-content-center"
          style={{ alignItems: "center" }}
        >
          <div className="col-sm-2">
            <img loading="lazy" src={assets['36AC94BC-AF7E-43E2-9A11-4E643F165DB9']} alt="cashfree" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['F3BAC8D1-5C70-408B-839C-35E6DBBE1753']} alt="make my trip" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['F14DFA04-675E-4F79-8980-FF7DD38AF224']} alt="yourstory" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['273E9F9D-F409-4AEF-8C85-460A41856EB2']} alt="cassplus" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['A1137B48-544B-4733-9559-6CB17DE0400C']} alt="fynd" />
          </div>
        </div>
      </div>
      <div className="logos" style={{ margin: "5rem" }}>
        <div
          className="d-flex justify-content-center"
          style={{ alignItems: "center" }}
        >
          <div className="col-sm-2">
            <img loading="lazy" src={assets['7467F328-4DCB-4FEA-8632-75F84C10E279']} alt="mpl" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['CED0FF97-8D9E-4210-9826-3BBBFA45DB31']} alt="yuyu" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['DD160942-6511-45DB-B52C-06443501F666']} alt="karza" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['705B6594-B276-438A-8C9A-91F6CA1E6D91']} alt="sdka" />
          </div>
          <div className="col-sm-2">
            <img loading="lazy" src={assets['22961C4E-F120-4C65-A3C4-9E874FF62E21']} alt="cflow" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Companies;

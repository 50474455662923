import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  getRunMaster,
  getProfileDetails,
  getUserQuestionData,
} from "../../../api/ApiRequest";
import "./index.scss";
import { assets } from "../../../utils/assets";

const DayRun = ({ showLoginModal, isLoggedIn, setRedirectUrl }) => {
  const PlanningIcon = assets["noun_Planning_252745"];
  const showLoginPopup = (e, programId) => {
    e.preventDefault();
    showLoginModal("login", true);
    setRedirectUrl(
      `/product-management-question?programId=${programId}`
    );
  };

  const [runMaster, setRunMaster] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [queData, setQueData] = useState([]);
  const [showHistory, setShowHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const runMasterResponse = await getRunMaster();
        const runMasterData = runMasterResponse.data;
        setRunMaster(runMasterData);

        const profileResponse = await getProfileDetails();
        const profileData = profileResponse.data;
        setProfileData(profileData);

        const promises = runMasterData.map((entry) => {
          return getUserQuestionData({
            userId: profileData?.id.toString(),
            pmRunProgramTypeId: entry?._id.toString(),
          });
        });

        const results = await Promise.all(promises);

        const queDataArray = results.map((result) => {
          return result?.data || null;
        });

        const showHistoryArray = results.map((result) => {
          return result?.data?.daysCompleted >= 1;
        });
        setQueData(queDataArray);
        setShowHistory(showHistoryArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const showContinueButton = queData.map((result, index) => {
    if (result.daysCompleted <= 0) {
      return result?.currentQuestion[0]?.questionNo > 1;
    } else {
      return result?.lastSubmittedQues[0]?.questionNo >= 1;
    }
  });

  return (
    <div data-testid="day-run-container" className="dayRun__cards">
      {runMaster.map((item, index) => (
        <div data-testid="day-run-card" className="card" key={item._id}>
          <h4>{item.pmRunProgramType}</h4>
          <p>{item.metaData}</p>
          <div className="plannings">
            {item.list.map((listItem, i) => (
              <div className="item" key={i}>
                <img loading="lazy" src={PlanningIcon} alt="" />
                <span>{listItem}</span>
              </div>
            ))}
          </div>
          <div className="button-container">
            <Link
              to={
                isLoggedIn
                  ? showContinueButton[index]
                    ? queData[index]?.daysCompleted ===
                      queData[index]?.totalDays
                      ? {
                          pathname: `history`,
                          search: `?userId=${profileData?.id}&pmRunProgramTypeId=${item._id}`,
                        }
                      : {
                          pathname: `product-management-question`,
                          search: `?programId=${item._id}`,
                        }
                    : {
                        pathname: `product-management-question`,
                        search: `?programId=${item._id}`,
                      }
                  : "javascript:void(0)"
              }
            >
              <button
                data-testid="day-run-btn"
                className="btn-white"
                onClick={(e) => !isLoggedIn && showLoginPopup(e, item._id)}
              >
                {isLoggedIn
                  ? showContinueButton[index]
                    ? queData[index]?.daysCompleted ===
                      queData[index]?.totalDays
                      ? "VIEW HISTORY"
                      : "CONTINUE"
                    : "GET STARTED"
                  : "LOGIN TO GET STARTED"}
              </button>
            </Link>
            {/* View Progress button based on data condition */}
            {(queData[index]?.daysCompleted !== queData[index]?.totalDays && queData[index]?.daysCompleted>=1) && (
              <Link
                to={{
                  pathname: `history`,
                  search: `?userId=${profileData?.id}&pmRunProgramTypeId=${item._id}`,
                }}
              >
                {showHistory[index] && (
                  <button className="viewProgress__btn">VIEW PROGRESS</button>
                )}
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DayRun;

import "./leaderboard.scss";

import { useState, useEffect } from "react";
import { assets } from "../../../utils/assets";

//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';
const Leaderboard = (props) => {
  const [activeIndex, setIndex] = useState(0);
  const [ranks, setRanks] = useState();
  useEffect(() => {
    if (props.ranks) {
      setRanks(props.ranks);
    }
  }, []);

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return value;
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="leaderboard-container">
      <div className="header-wrapper">
        <span className="header">Challenge leaderboard</span>
      </div>
      {ranks && (
        <div className="content-wrapper">
          <div className="list-wrapper">
            {ranks.map((item, idx) => {
              let profilepicture =
                item.profile_picture && item.profile_picture !== ""
                  ? item.profile_picture
                  : assets["profile-pic"];
              return (
                <div
                  key={idx}
                  className={`list-items ${activeIndex === idx ? "active" : ""
                    }`}
                  onClick={() => {
                    handleSelect(idx);
                  }}
                >
                  <div
                    // className={`img-wrapper ${item.rank == 1 ? "winner-icon" : ""}`}
                    className={`img-wrapper ${item.rank == 1 ? "" : ""}`}
                  >
                    <img
                      loading="lazy"
                      alt=""
                      className="winner-img"
                      src={profilepicture}
                    ></img>
                  </div>
                  <div className="winner-details">
                    <span className="name">{item.name}</span>
                    <span className="score">
                      {item.score} / {item.totalScore}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="carousel-wrapper">
            {
              <div>
                {/* <Carousel
                  fade
                  onSlid={handleSelect}
                  prevLabel={`${activeIndex + 1} OF ${ranks.length}`}
                  activeIndex={activeIndex}
                  nextLabel=""
                >
                  {ranks.map((item, idx) => {
                    return (
                      <Carousel.Item key={idx}>
                        <Carousel.Caption>                        
                        </Carousel.Caption>
                      </Carousel.Item>
                    );
                  })}
                </Carousel> */}

                <div className="item-wrapper">
                  <div>
                    <h3 className="title">Solution by {ranks[activeIndex] ? ranks[activeIndex]['name'] : ''}</h3>
                  </div>
                  <div className="img-card-wrapper">
                    <iframe
                      title={"entry_user_pdf_for_" + (ranks[activeIndex] ? ranks[activeIndex]['name'] : '')}
                      style={{
                        height: "400px",
                        width: "100%",
                        border: "none",
                        outline: "none",
                      }}
                      src={ranks[activeIndex] ? ranks[activeIndex]['entry_id']['entry_pdf'] : ''}
                    />
                    {/* <img className="item-img" src={itemImage} alt=""></img> */}
                    {/* <div className="item-details">
                      <span className="item-title">
                        LinkedIn for school students case study
                      </span>
                      <span className="person-name"></span>
                    </div> */}
                  </div>
                  {/* <div className="person-caption">
                    {item.remark_text}
                  </div> */}
                </div>

                <div className="feedback-wrapper">
                  <div className="title">Feedback from the experts</div>
                  <div className="detail">
                    <div className="relative">
                      <img loading="lazy" src={assets["Group 25367"]} alt="" className="feedback" />
                      {/* <img src={feedbackLike} alt="" className="feedLike" /> */}
                    </div>
                    <div className="remark">
                      <img loading="lazy" src={assets.quote1} alt="" className="quote1" />
                      <span>{ranks[activeIndex]?.remark_text}</span>
                      <img loading="lazy" src={assets.quote2} alt="" className="quote2" />
                    </div>
                  </div>
                </div>
              </div>
            }
            {!props.isLoggedIn && (
              <div className="loginWrapper">
                <img loading="lazy" className="lock-icon" src={assets.lock} alt="" />
                <button
                  className="btn-login"
                  onClick={() => {
                    props.setChallengeId({
                      challenge_id: props.challenge._id,
                      end_date_timestamp: props.challenge.end_date_timestamp,
                    });
                    props.showUploadScreen("login", true);
                  }}
                >
                  Login to view
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;

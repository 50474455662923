import {
  ADDDRIVELINK,
  BASE_URL_API,
  CHANGEPASSWORD,
  COHORTDATE,
  FORGOTPASSWORD,
  GETALLCHALLENGES,
  GETCERTIFICATE,
  GETCHALLENGEDETAILS,
  GETCHALLENGEENTRIES,
  GETLEADERBOARD,
  GETPROFILEDETAILS,
  GETUSERCOUNT,
  LOGIN,
  LOGOUT,
  PUBLICPROFILE,
  QUESTIONDATA,
  RUNMASTER,
  SIGNUP,
  SOCIALLOGIN,
  SUBMITENTRY,
  SUBMITQUESTION,
  UPDATEPROFILE,
  QUESTIONHISTORYDATA,
  COUPONCODE,
  GETCERTIFICATEBYID,
  DYNAMICCERTIFICATE,
  RENDERCERTIFICATE,
  GETCERTIFICATEIMAGE,
} from "./ApiConstants";
import {
  getAddDriveLinkUrl,
  getChangePasswordParameterMap,
  getForgotPasswordParameterMap,
  getLoginParameterMap,
  getSignUpParameterMap,
} from "./ApiParameter";

import axios from "axios";
// const FileDownload = require('js-file-download');

const API_TIMEOUT = 60000;
const TIMEOUT = 60000;
const SUBMITENTRYTIMEOUT = 60000;

const instance = axios.create({
  baseURL: BASE_URL_API,
  timeout: API_TIMEOUT,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  withCredentials: true, // Add this option to enable sending cookies with cross-origin requests
});

function getHeaders() {
  let user_id = localStorage.getItem("PMuserId");
  let token = localStorage.getItem("PMtoken");
  if (user_id != null && token != null) {
    return { headers: { user_id, token } };
  }
  return null;
}

function getHeadersProfile() {
  let user_id = localStorage.getItem("PMuserId");
  let token = localStorage.getItem("PMtoken");
  if (user_id != null && token != null) {
    return { headers: { user_id, token, "Content-Type": "application/json" } };
  }
  return null;
}

const rawinstance = axios.create({
  baseURL: BASE_URL_API,
  timeout: API_TIMEOUT,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers",
    "Content-Type": "application/json",
  },
  withCredentials: true, // Add this option to enable sending cookies with cross-origin requests
});

const getFormUrlEncodedBody = (body) => {
  var formBody = [];
  for (var property in body) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
};

const parse = (res, resolve, reject) => {
  if (res && res.data) {
    const response = res.data;
    if (response.code === 200 || response.code === 202) {
      resolve(response);
    } else {
      if (response.msg_type && response.msg_type != "") {
        if (response.msg_type == "LOCATION_NOT_ALLOWED")
          reject(
            "Due to licensing constraints, we are currently unable to allow streaming outside of the United States. We are working to remedy this and appreciate your patience!"
          );
        else reject(response.msg_type);
      } else if (response.msg && response.msg != "") {
        reject(response.msg);
      } else {
        reject("Server Error");
      }
    }
  } else {
    reject("No response");
  }
};

const postCall = (url, body, config) => {
  if (config?.async) {
    // If config.async is true, handle it asynchronously
    return Promise.race([
      new Promise(async (resolve, reject) => {
        try {
          const response = await instance.post(url, body, config);
          parse(response, resolve, reject);
        } catch (error) {
          reject(error); // Handle errors properly
        }
      }),
      new Promise((_, reject) =>
        setTimeout(() => reject("Timeout exceeded"), TIMEOUT)
      ),
    ]);
  } else {
    // Handle it synchronously (without async/await)
    return Promise.race([
      new Promise((resolve, reject) => {
        instance
          .post(url, body, config)
          .then((response) => {
            parse(response, resolve, reject);
          })
          .catch((error) => {});
      }),
      new Promise((_, reject) =>
        setTimeout(() => reject("Timeout exceeded"), TIMEOUT)
      ),
    ]);
  }
};


const postRawCall = (url, body, config) => {
  return Promise.race([
    new Promise((resolve, reject) => {
      rawinstance
        .post(url, body, config)
        .then((response) => {
          parse(response, resolve, reject);
        })
        .catch((error) => {});
    }),
    new Promise((_, reject) =>
      setTimeout(
        () =>
          url == "/admin/entries/submit_entry"
            ? reject(
                "Please try submitting the document over a stable internet network"
              )
            : reject("Timeout exceeded"),
        SUBMITENTRYTIMEOUT
      )
    ),
  ]);
};

// const postCallDownload = (url, body, config) => {
//     return Promise.race([
//         new Promise((resolve, reject) => {
//             instance
//                 .post(url, body, config)
//                 .then((response) => {
//                     //
//                     if (response.data.code) {
//                         parse(response, resolve, reject);
//                     } else {
//                         FileDownload(response.data, 'certificate.png');
//                     }
//                 })
//                 .catch((error) => {

//                 });
//         }),
//         new Promise((_, reject) =>
//             setTimeout(() => reject("Timeout exceeded"), TIMEOUT)
//         ),
//     ]);
// };

const putCall = (url, body, config) => {
  return Promise.race([
    new Promise((resolve, reject) => {
      instance
        .put(url, body, config)
        .then((response) => {
          parse(response, resolve, reject);
        })
        .catch((error) => {});
    }),
    new Promise((_, reject) =>
      setTimeout(() => reject("Timeout exceeded"), TIMEOUT)
    ),
  ]);
};

const deleteCall = (url, config) => {
  return Promise.race([
    new Promise((resolve, reject) => {
      instance
        .delete(url, config)
        .then((response) => {
          parse(response, resolve, reject);
        })
        .catch((error) => {});
    }),
    new Promise((_, reject) =>
      setTimeout(() => reject("Timeout exceeded"), TIMEOUT)
    ),
  ]);
};

const getCall = (url, body) => {
  return Promise.race([
    new Promise((resolve, reject) => {
      instance
        .get(url, body)
        .then((response) => {
          parse(response, resolve, reject);
        })
        .catch((error) => {});
    }),
    new Promise((_, reject) =>
      setTimeout(() => reject("Timeout exceeded"), TIMEOUT)
    ),
  ]);
};

export function signupRequest(email, password, name, user) {
  return postCall(
    SIGNUP,
    getFormUrlEncodedBody(getSignUpParameterMap(email, password, name, user))
  );
}

export function loginRequest(
  email,
  password,
  device_os,
  device_os_version,
  device_token,
  device_name
) {
  return postCall(
    LOGIN,
    getFormUrlEncodedBody(
      getLoginParameterMap(
        email,
        password,
        device_os,
        device_os_version,
        device_token,
        device_name
      )
    ),
    {
      async: true
    }
  );
}
export function logoutRequest(device_token) {
  return postCall(LOGOUT, getFormUrlEncodedBody({ device_token }), {
    ...getHeaders(),
  });
}

export function forgotPasswordRequest(email) {
  return postCall(
    FORGOTPASSWORD,
    getFormUrlEncodedBody(getForgotPasswordParameterMap(email))
  );
}

export function changePasswordRequest(email, oldpassword, newpassword) {
  return postCall(
    CHANGEPASSWORD,
    getFormUrlEncodedBody(
      getChangePasswordParameterMap(email, oldpassword, newpassword)
    ),
    {
      ...getHeaders(),
    }
  );
}

export function setGoogleDriveLink(entryId, link) {
  return postCall(
    ADDDRIVELINK,
    getFormUrlEncodedBody(getAddDriveLinkUrl(entryId, link)),
    {
      ...getHeaders(),
    }
  );
}

export function getProfileDetails() {
  return getCall(GETPROFILEDETAILS, {
    ...getHeaders(),
  });
  // return axios.get(`http://localhost:4000/api/${GETPROFILEDETAILS}`, { ...getHeaders() }).then((res) => res.data)
}

export function getAllChallengesRequest(data) {
  return getCall(GETALLCHALLENGES, {
    params: data,
  });
  //return axios.get(`http://localhost:4000/api/${GETALLCHALLENGES}`, { params: data }).then((res) => res.data)
}

export function getCertificateRequest(data) {
  return getCall(GETCERTIFICATE, {
    params: data,
  });
  //return axios.get(`http://localhost:4000/api/${GETALLCHALLENGES}`, { params: data }).then((res) => res.data)
}

export function getUserCount() {
  return getCall(GETUSERCOUNT, {
    // params: data
  });
  // return axios.get(`http://localhost:4000/api/${GETUSERCOUNT}`, {}).then((res) => res.data)
}

export function getChallengeEntriesRequest(data) {
  return getCall(GETCHALLENGEENTRIES, {
    params: data,
  });
}

export function getLeaderboardRequest(data) {
  return getCall(GETLEADERBOARD, {
    params: data,
  });
}

export function getChallengeDetailsRequest(data) {
  return getCall(GETCHALLENGEDETAILS, {
    params: data,
  });
  //return axios.get(`http://localhost:4000/api/${GETCHALLENGEDETAILS}`, { params: data }).then((res) => res.data)
}

export function submitEntry(data) {
  return postRawCall(SUBMITENTRY, data, {
    ...getHeaders(),
  });
}

export function socialLogin(data) {
  return postRawCall(SOCIALLOGIN, data);
}

export function getCohortDate() {
  return getCall(COHORTDATE);
  //return axios.get(`http://localhost:4000/api${COHORTDATE}`).then((res) => res.data)
}

export function updateProfile(obj) {
  return postCall(UPDATEPROFILE, obj, { ...getHeadersProfile() });
  // return axios.post(`http://localhost:4000/api${UPDATEPROFILE}`, obj, { ...getHeaders }).then((res) => res.data)
}

export function resumeLink(data) {
  return getCall(PUBLICPROFILE, { params: data });
}

export function getRunMaster() {
  return getCall(RUNMASTER);
}

export function getUserQuestionData(obj) {
  return postCall(QUESTIONDATA, obj, { ...getHeadersProfile() });
}

export function submitQuestion(obj) {
  return postCall(SUBMITQUESTION, obj, { ...getHeadersProfile() });
}

export function getUserQuestionDataHistory(data) {
  return getCall(QUESTIONHISTORYDATA, {
    params: data,
  });
}
export function getCouponCode(data) {
  return getCall(COUPONCODE, {
    params: data,
  });
}
export function getCertificateById(data) {
  return getCall(GETCERTIFICATEBYID, {
    params: data,
  });
}
export function getDynamicCertificate(data) {
  return getCall(DYNAMICCERTIFICATE, {
    params: data,
  });
}
export function renderCertificate(data) {
  return getCall(RENDERCERTIFICATE, {
    params: data,
  });
}
export function getCertificateImage(data) {
  return getCall(GETCERTIFICATEIMAGE, {
    params: data,
  });
}

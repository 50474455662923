import { Tab, Nav } from "react-bootstrap";
import "./terms-and-condition.scss";
import React, { useState, useEffect } from "react";
import MetaTagsScreen from "../../utils/metaTags";

const data = [
  {
    tabName: "Terms & Conditions",
    title: "Terms & Conditions",
    id: 0,
  },
  {
    tabName: "Refunds & Cancellation Policy",
    title: "Refunds & Cancellation Policy",
    id: 1,
  },
];

const TermsAndCondition = (props) => {
  const [page, setPage] = useState(0);
  const checkFun = (e, id) => {
    // console.log(e)
    window.scrollTo(0, 0);
    setPage(id);
  };

  useEffect(() => {
    if (props.location.pathname == "/terms-and-condition") {
      setPage(0);
    } else {
      setPage(1);
    }
  }, []);
  return (
    <>
      <MetaTagsScreen canonicalLink="https://pmschool.io/terms-and-condition" />
      <Tab.Container defaultActiveKey="item_0">
        <div className="d-block  d-md-flex terms-and-condition-tab-section">
          <div className="terms-and-condition-tab-btn-container">
            <Nav
              variant="pills"
              navbarScroll={true}
              className="flex-row flex-md-column flex-nowrap terms-and-condition-nav-sticky"
            >
              {/* {data.map((item, idx) => { */}
              {/* return ( */}
              <Nav.Item>
                <Nav.Link
                  active={page == 0 ? true : false}
                  className={`terms-and-condition-tab-item`}
                  onClick={(e) => checkFun(e, 0)}
                  eventKey="item_0"
                >
                  {"Terms & Conditions"}
                </Nav.Link>
                <Nav.Link
                  active={page == 1 ? true : false}
                  className={`terms-and-condition-tab-item`}
                  onClick={(e) => checkFun(e, 1)}
                  eventKey="item_1"
                >
                  {"Refunds & Cancellation Policy"}
                </Nav.Link>
              </Nav.Item>
              {/* ); */}
              {/* })} */}
            </Nav>
          </div>
          {
            <div style={{ borderLeft: "solid 1px rgba(112, 112, 112, 0.2)" }}>
              <Tab.Content className="h-100">
                {data.map((item, idx) => {
                  return (
                    <Tab.Pane
                      eventKey={`item_${idx}`}
                      className="h-100"
                      key={idx}
                    >
                      <div className="terms-and-condition-content-detail">
                        {/* <span className="title">{item.title}</span> */}
                        {page == 0 && (
                          <React.Fragment>
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  1. Introduction
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  This page (together with the documents
                                  referred to on it) sets out the terms and
                                  conditions under which we provide the Programs
                                  (“Programs”) listed on our website{" "}
                                  <a
                                    style={{ color: "#ffffff !important" }}
                                    href="https://pmschool.io"
                                    target="_blank"
                                  >
                                    <span className="text-style-3">
                                      https://pmschool.io
                                    </span>
                                  </a>{" "}
                                  and our Learning Management system{" "}
                                  <span className="text-style-2">
                                    pmschool.thinkific.com{" "}
                                  </span>{" "}
                                  (together referred to as “site”). Please read
                                  these terms and conditions carefully before
                                  Admission to any Program from the site. You
                                  should understand that by Admission to any of
                                  our Program (“Program Admission”), you agree
                                  to be bound by these terms and conditions.
                                </span>
                                <div className="terms-and-condition-content-dot-wraper">
                                  <div className="terms-and-condition-content dot">
                                    The site{" "}
                                    <a
                                      style={{ color: "#ffffff !important" }}
                                      href="https://pmschool.io"
                                      target="_blank"
                                    >
                                      <span className="text-style-3">
                                        https://pmschool.io
                                      </span>
                                    </a>{" "}
                                    and our Learning management system (“we” or
                                    “us”) provide online (live video streaming)
                                    training in product management discipline.
                                    Our program(s) are designed to help
                                    participants learn by getting product
                                    management training thereby making them
                                    PM-ready.
                                  </div>
                                </div>
                                <div className="terms-and-condition-content-dot-wraper">
                                  <div className="terms-and-condition-content dot">
                                    Our Registered office address is C805,
                                    Muktangan, Upper Govindnagar, Malad (east),
                                    Mumbai PIN: 400097
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  2. Your status
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  By placing a request for Admission through our
                                  site you warrant that:
                                </span>
                                <div className="terms-and-condition-content-sublist">
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      You are legally capable of entering into
                                      binding Agreement; and
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      You are at least 18 years old; and
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      You do not have an outstanding bad debt
                                      with PM School; and
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      You have not previously been asked to
                                      leave a Program.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  3. How the Agreement is formed between you and
                                  us
                                </span>
                              </div>
                              <div>
                                <div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      <span className="text-style-1">
                                        {" "}
                                        Acceptance of this Agreement
                                      </span>
                                      <br />
                                      By clicking on the ‘APPLY NOW’ or “ENROLL
                                      NOW”, “EXPLORE the PM Course” and similar
                                      options, the participant (“You” or “Your”)
                                      agrees to the Terms and Conditions,
                                      obligations, representations, warranties,
                                      and agreements contained herein (the
                                      “Agreement”). In the event, You are not
                                      willing to accept the Agreement, You shall
                                      not be authorized or allowed to proceed
                                      further to view or use in any manner any
                                      content, information, courseware, products
                                      and services (“Services”) published,
                                      available or provided by PM School, which
                                      is owned, maintained and monitored by us.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      <span className="text-style-1">
                                        {" "}
                                        User ID and Password
                                      </span>
                                      <br />
                                      By entering into this Agreement, You
                                      acknowledge and agree that your user ID
                                      and password (“Participant Account”) is
                                      for your exclusive use only. Use or
                                      sharing of Your Participant Account with
                                      another user is not permitted and is cause
                                      for immediate blocking of your access to
                                      the Website, the Services and the Content,
                                      the Courseware, and termination of this
                                      Agreement and would result in forfeiture
                                      of the fees already paid by you. You agree
                                      that you are solely responsible for
                                      maintaining the confidentiality of Your PM
                                      School Account and for all activities that
                                      occur under it. You agree to immediately
                                      notify us on{" "}
                                      <span className="text-style-1">
                                        hello@pmschool.io
                                      </span>{" "}
                                      if you become aware of or have reason to
                                      believe that there is any unauthorized use
                                      of Your Participant Account. You also
                                      agree to take all reasonable steps to stop
                                      such unauthorized use and to cooperate
                                      with us in any investigation of such
                                      unauthorized uses. We shall not under any
                                      circumstances be held liable for any
                                      claims related to the use or misuse of
                                      Your Participant Account due to the
                                      activities of any third party outside of
                                      our control or due to Your failure to
                                      maintain the confidentiality and security
                                      of Your Participant Account.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      <span className="text-style-1">
                                        {" "}
                                        A.Y.O.D policy:
                                      </span>
                                      <br />
                                      You are required to arrange y‌our o‌wn
                                      computing d‌evice for the online class to
                                      work on assignments, quizzes or other
                                      training activities.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      <span className="text-style-1">
                                        {" "}
                                        Program Contents
                                      </span>
                                      <br />
                                      As a part of our Services offered through
                                      our Website, We shall grant you access to
                                      our content, practice quizzes, and other
                                      information, documents, and data which may
                                      be in audio, video, written, graphic,
                                      recorded, photographic, or any
                                      machine-readable format in relation to the
                                      specific certification of Program You have
                                      registered. We reserve the right to amend,
                                      revise or update the Content of the
                                      Program offered to You.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      <span className="text-style-1">
                                        {" "}
                                        Usage of the Website and Services
                                      </span>
                                      <br />
                                      We grant you a personal, restricted,
                                      non-transferable, non-exclusive, and
                                      revocable license to use the Website, the
                                      Services, and the Content of the Program
                                      offered through the Website till the time
                                      the completion of the certification
                                      training program that you have enrolled
                                      for or the termination of this Agreement
                                      according to the Terms and Conditions set
                                      forth herein, whichever is earlier. The
                                      Services and the Content of the Program
                                      are provided solely for Your personal and
                                      non-commercial use to assist you in
                                      completing the certification training
                                      course You have registered for
                                      (“Restricted Purpose”). You are permitted
                                      online access to the Website, the
                                      Services, and the Content and Courseware
                                      but you may not download, save, or print
                                      the Content. The Courseware is solely for
                                      the Restricted Purpose. You are not
                                      permitted to reproduce, transmit,
                                      distribute, sub-license, broadcast,
                                      disseminate, or prepare derivative works
                                      of the Content of the Program, or any part
                                      thereof, in any manner or through any
                                      communication channels or means, for any
                                      purpose other than the Restricted Purpose,
                                      without Our prior written consent.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  <div>
                                    <div className="terms-and-condition-title">
                                      <span className="Refunds-Cancellation-Policy">
                                        4. Price and payment
                                      </span>
                                    </div>
                                    <div>
                                      <div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            The Program fee will be as quoted on
                                            our site at the time of Admission,
                                            except in cases of obvious error.
                                            Taxes if included or not would be
                                            clearly mentioned.
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            Prices are liable to change at any
                                            time, but changes will not affect
                                            admission in respect of which we
                                            have already sent you an order
                                            confirmation.
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            We are under no obligation to admit
                                            you on a Program at the incorrect
                                            (lower) price, even after we have
                                            sent you an order confirmation, if
                                            the pricing error is obvious and
                                            unmistakable and could have
                                            reasonably been recognised by you as
                                            a mis-pricing
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            Payment by you via our third party
                                            payment gateway must be by credit,
                                            debit card, UPI, netbanking or NEFT.
                                            You may contact our Admissions Team
                                            if you wish to pay the Program fee
                                            by another method. (Payment will be
                                            considered received only on receipt
                                            in our bank account. The client may
                                            claim amount sent but may not be
                                            received at our end)
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            Payment Process Flow
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-sublist">
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              PM School generates payment links
                                              (Razorpay and Instamojo)
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              PM School sends these payment
                                              links to students via email
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              When the student clicks on the
                                              link, he/she is redirected to the
                                              Razorpay or Instamojo
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              Payment Gateway from where he/she
                                              initiates the payment
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  5. Our liability and intellectual property
                                </span>
                              </div>
                              <div>
                                <div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      You expressly agree that use of the
                                      Website, the Services, and the Content and
                                      Courseware are at Your sole risk. We do
                                      not warrant that the Website or the
                                      Services or access to the Contents of
                                      Program will be uninterrupted or error
                                      free; nor is there any warranty as to the
                                      results that may be obtained from the use
                                      of the Website, the Services or the
                                      Contents of Program or as to the accuracy
                                      or reliability of any information provided
                                      through the Website, the Services, or the
                                      Content of Program. In no event will we or
                                      any person or entity involved in creating,
                                      producing, or distributing the Website,
                                      the Services, or the Content and
                                      Courseware be liable for any direct,
                                      indirect, incidental, special, or
                                      consequential damages arising out of the
                                      use of or inability to use the Website,
                                      the Services, or the Content of Program.
                                      The disclaimer of liability contained in
                                      this clause applies to any and all damages
                                      or injury caused by any failure of
                                      performance, error, omission,
                                      interruption, deletion, defect, delay in
                                      operation or transmission, computer virus,
                                      communication line failure, theft or
                                      destruction or unauthorizsed access to,
                                      alteration of, or use of records or any
                                      other material, whether for breach of
                                      contract, negligence, or under any other
                                      cause of action. You hereby specifically
                                      acknowledge that we are not liable for any
                                      defamatory, offensive, wrongful, or
                                      illegal conduct of third parties, or other
                                      users of the Website, the Services or the
                                      Contents of Program and that the risk of
                                      damage or injury from the foregoing rests
                                      entirely with each user. You agree that
                                      Our liability or the liability of Our
                                      affiliates, directors, officers,
                                      employees, agents, and licensors, if any,
                                      arising out of any kind of legal claim
                                      (whether in contract, tort or otherwise)
                                      in any way connected with the Services or
                                      the Contents of Program shall not exceed
                                      the fee you paid to Us for the particular
                                      Program.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      While You are granted a limited and
                                      non-exclusive right to use the Website,
                                      the Services, and the Contents of Program
                                      for the Restricted Purpose as set forth in
                                      this Agreement, You acknowledge and agree
                                      that We are the sole and exclusive owner
                                      of the Website, the Services and the
                                      Contents of Program and as such are vested
                                      with all Intellectual Property Rights and
                                      other proprietary rights in the Website,
                                      the Services, and the Contents of Program.
                                      You acknowledge and agree that this
                                      Agreement other than permitting You to use
                                      the Website, the Services, and the
                                      Contents of Program for the Restricted
                                      Purpose does not convey to You in any
                                      manner or form any right, title or
                                      interest of a proprietary, or any other
                                      nature in the Website, the Services, and
                                      the Contents of Program.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* 6 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  6. Usage of Personal Information of
                                  Participants
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  We reserve the right to feature your picture
                                  in any photos, videos, or other promotional
                                  material used by us. Further, we may use your
                                  personal information to inform You about other
                                  certification training courses offered by Us.
                                  However, We shall not distribute or share Your
                                  personal information with any third party
                                  marketing database or disclose Your personal
                                  information to any third party except on a
                                  case-to-case basis after proper verification
                                  of such third party or if required under any
                                  applicable law.
                                </span>
                              </div>
                            </div>
                            {/* 6 END*/}

                            {/* 7 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  7. Term and Termination
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  This Agreement will become effective upon your
                                  enrolment to our program and will remain in
                                  effect till You maintain a current, fully paid
                                  up online Participant Account, or until
                                  terminated by Us, whichever is earlier. We
                                  reserve the right to terminate this Agreement
                                  and block Your access to the Contents of the
                                  Program with immediate effect by sending a
                                  written notice through email to You to this
                                  effect (“Immediate Termination Date”), if such
                                  termination is made as a result of Your
                                  misrepresentation, default, misconduct, or
                                  breach of Your obligations related to or under
                                  this Agreement (“Event of Default”). On the
                                  occurrence of any Event of Default, We shall
                                  be authorized to exercise all the rights and
                                  remedies under this Agreement or applicable
                                  Law or available in equity to seek
                                  indemnification for any Loss or Claim
                                  resulting from any such Event of Default. The
                                  provisions of this clause of Agreement shall
                                  survive the termination of this Agreement.
                                </span>
                              </div>
                            </div>
                            {/* 7 END*/}

                            {/* 8 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  8. Indemnity
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  You agree to indemnify and hold Us, Our
                                  contractors, licensors, directors, officers,
                                  employees, and agents, harmless from and
                                  against any and all claims, losses, damages,
                                  liabilities, and expenses including attorneys’
                                  fees, arising out of Your unauthorized use of
                                  the Website, the Services, and the Contents of
                                  the Program or any violation or breach of this
                                  Agreement or any provisions hereof.
                                </span>
                              </div>
                            </div>
                            {/* 8 END*/}

                            {/* 9 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  9. Written communications
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  Applicable laws require that some of the
                                  information or communications we send to you
                                  should be in writing. When using our site, you
                                  accept that communication with us will be
                                  mainly electronic. We will contact you by
                                  email or provide you with information by
                                  posting notices on our website. For
                                  contractual purposes, you agree to this
                                  electronic means of communication and you
                                  acknowledge that all Agreement, contracts,
                                  notices, information and other communications
                                  that we provide to you electronically comply
                                  with any legal requirement that such
                                  communications be in writing.
                                </span>
                              </div>
                            </div>
                            {/* 9 END*/}

                            {/* 10 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  10. Transfer of rights and obligations
                                </span>
                              </div>
                              <div>
                                <div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      The Agreement be tween you and us is
                                      binding on you and us and on our
                                      respective successors and assigns.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      You may not transfer, assign, charge or
                                      otherwise dispose of a agreement, or any
                                      of your rights or obligations arising
                                      under it, without our prior written
                                      consent.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      We may transfer, assign, charge,
                                      sub-contract or otherwise dispose of a
                                      contract, or any of our rights or
                                      obligations arising under it, at any time
                                      during the term of the contract.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* 10 END*/}

                            {/* 11 START*/}

                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  <div>
                                    <div className="terms-and-condition-title">
                                      <span className="Refunds-Cancellation-Policy">
                                        11. Events outside our control
                                      </span>
                                    </div>
                                    <div>
                                      <div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            PM School, reserves the right to
                                            postpone/cancel an event/lecture, or
                                            change the location of an
                                            event/lecture because of instructor
                                            illness or force majeure events
                                            (like floods, earthquakes, political
                                            instability, etc) or any reason
                                            deemed appropriate by PM School
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            We will not be liable or responsible
                                            for any failure to perform, or delay
                                            in performance of, any of our
                                            obligations under this Agreement
                                            that is caused by events outside our
                                            reasonable control (force majeure
                                            event).
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            A force majeure event includes any
                                            act, event, non-happening, omission
                                            or accident beyond our reasonable
                                            control and includes in particular
                                            (without limitation) the following:
                                          </div>
                                        </div>

                                        <div className="terms-and-condition-content-sublist">
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              Strikes, lock-outs or other
                                              industrial action.
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              Civil commotion, riot, invasion,
                                              terrorist attack or threat of
                                              terrorist attack, war (whether
                                              declared or not) or threat or
                                              preparation for war.
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              Fire, explosion, storm, flood,
                                              earthquake, subsidence, epidemic
                                              or other natural disaster.
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              Impossibility of the use of
                                              railways, shipping, aircraft,
                                              motor transport or other means of
                                              public or private transport.
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              Impossibility of the use of public
                                              or private telecommunications
                                              networks.
                                            </div>
                                          </div>
                                          <div className="terms-and-condition-content-dot-wraper">
                                            <div className="terms-and-condition-content dot">
                                              The acts, decrees, legislation,
                                              regulations or restrictions of any
                                              government.
                                            </div>
                                          </div>
                                        </div>
                                        <div className="terms-and-condition-content-dot-wraper">
                                          <div className="terms-and-condition-content dot">
                                            Our performance under this agreement
                                            is deemed to be suspended for the
                                            period that the force majeure event
                                            continues, and we will have an
                                            extension of time for performance
                                            for the duration of that period. We
                                            will use our reasonable endeavours
                                            to bring the force majeure event to
                                            a close or to find a solution by
                                            which our obligations under the
                                            agreement may be performed despite
                                            the force majeure event.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                            {/* 11 END*/}

                            {/* 12 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  12. Severability
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  If any of these terms and conditions or any
                                  provisions of agreement are determined by any
                                  competent authority to be invalid, unlawful or
                                  unenforceable to any extent, such term,
                                  condition or provision will to that extent be
                                  severed from the remaining terms, conditions
                                  and provisions which will continue to be valid
                                  to the fullest extent permitted by law.
                                </span>
                              </div>
                            </div>
                            {/* 12 END*/}

                            {/* 13 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  13. Our right to vary these terms and
                                  conditions
                                </span>
                              </div>
                              <div>
                                <div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      We have the right to revise and amend
                                      these terms and conditions from time to
                                      time.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      You will be subject to the policies and
                                      terms and conditions in force at the time
                                      that you request for admission of Program
                                      from us, unless any change to those
                                      policies or these terms and conditions is
                                      required to be made by law or governmental
                                      authority (in which case it will apply to
                                      request for admission previously placed by
                                      you).
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* 13 END*/}

                            {/* 14 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  14. Law and jurisdiction
                                </span>
                              </div>
                              <div>
                                <span className="terms-and-condition-content">
                                  Agreement for enrolment to Programs on our
                                  site and any dispute or claim arising out of
                                  or in connection with them or their subject
                                  matter or formation (including non-contractual
                                  disputes or claims) will be governed by laws
                                  of India. Any dispute or claim arising out of
                                  or in connection with such agreement or their
                                  formation (including non-contractual disputes
                                  or claims) shall be subject to the
                                  jurisdiction of the courts of MUMBAI.
                                </span>
                              </div>
                            </div>
                            {/* 14 END*/}

                            {/* 15 START*/}
                            <div>
                              <div className="terms-and-condition-title">
                                <span className="Refunds-Cancellation-Policy">
                                  15. Entire agreement
                                </span>
                              </div>
                              <div>
                                <div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      These terms and conditions and any
                                      document expressly referred to in them
                                      represent the entire agreement between us
                                      in relation to the subject matter of any
                                      contract and supersede any prior
                                      agreement, understanding or arrangement
                                      between us, whether oral or in writing.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      We each acknowledge that, in entering into
                                      a contract, neither of us has relied on
                                      any representation, undertaking or promise
                                      given by the other or be implied from
                                      anything said or written in negotiations
                                      between us prior to such contract except
                                      as expressly stated in these terms and
                                      conditions.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      Neither of us shall have any remedy in
                                      respect of any untrue statement made by
                                      the other, whether orally or in writing,
                                      prior to the date of any contract (unless
                                      such untrue statement was made
                                      fraudulently) and the other party’s only
                                      remedy shall be for breach of contract as
                                      provided in these terms and conditions.
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      No Batch transfer requests would be
                                      considered post batch commencement
                                    </div>
                                  </div>
                                  <div className="terms-and-condition-content-dot-wraper">
                                    <div className="terms-and-condition-content dot">
                                      For batch transfer requests before the
                                      batch commencement date, 15 working days
                                      notice needs to be given
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* 15 END*/}
                          </React.Fragment>
                        )}
                        {page == 1 && (
                          <div>
                            <div className="terms-and-condition-title">
                              <span className="Refunds-Cancellation-Policy">
                                Refunds & Cancellation Policy
                              </span>
                            </div>
                            <div>
                              <div>
                                <div className="terms-and-condition-content-dot-wraper">
                                  <div className="terms-and-condition-content dot">
                                    The Program fee for PM School Live Program
                                    or Online Training offerings is
                                    non-refundable
                                  </div>
                                </div>
                                <div className="terms-and-condition-content-dot-wraper">
                                  <div className="terms-and-condition-content dot">
                                    The student cannot choose to postpone the
                                    admission to the next batch. In such a case,
                                    PM School reserves the right to postpone
                                    admission at the current fee structure at
                                    which the fee is paid or at the prevailing
                                    fee structure of the next batch. PM School’s
                                    decision in such matters is final and not
                                    negotiable
                                  </div>
                                </div>
                                <div className="terms-and-condition-content-dot-wraper">
                                  <div className="terms-and-condition-content dot">
                                    The Program Fees are non-transferable
                                  </div>
                                </div>
                                <div className="terms-and-condition-content-dot-wraper">
                                  <div className="terms-and-condition-content dot">
                                    We do not guarantee refunds for lack of
                                    program content usage or dissatisfaction
                                  </div>
                                </div>
                                <div className="terms-and-condition-content-dot-wraper">
                                  <div className="terms-and-condition-content dot">
                                    You will not have the right to cancel the
                                    admission where the services have been fully
                                    performed or delivered to your email
                                    address.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </div>
          }
        </div>
      </Tab.Container>
    </>
  );
};

export default TermsAndCondition;

import React from "react";
import Banner from "./banner";
import Classes from "./classes/index";
import FAQ from "./faq";
import MentorsSection from "./for-mentor-section";
import "./index.scss";
import Learning from "./learning";
import LiveChallenges from "./live-challenges";
import Logo from "./logo";
import MetaTagsScreen from "../../utils/metaTags";

const OurPmCourse = () => {
  return (
    <div>
      <MetaTagsScreen
        title="PM School Reviews | Gauge the impact of the Product Management Flagship Course with Testimonials from our PM School Graduates"
        desc="Our Product Management Course has empowered countless professionals to succeed in their careers. Read about PM School's reviews and success stories from alumni, ex-students who have experienced the product management flagship course. The PM course testimonials are from existing product managers who have been able to get into a product manager role."
        canonicalLink="https://pmschool.io/testimonials"
      />
      <Banner />
      <Classes />
      <Learning />
      <MentorsSection />
      {/* <LiveChallenges /> */}
      <Logo />
      <FAQ />
    </div>
  );
};

export default OurPmCourse;

import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import TagManager from "react-gtm-module";
import "./header.scss";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { getCohortDate, getProfileDetails } from "../../../api/ApiRequest";
import { assets } from "../../../utils/assets";

function Header(props) {
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [profilePic, setProfilePic] = useState();
  const [cohortDate, setCohortDate] = useState("");

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // alert(splitLocation)

  // if(splitLocation)
  // if(splitLocation.includes("ChallengeDetails")) {

  // }

  const getProfile = () => {
    getProfileDetails().then((res) => {
      if (res.code === 200) {
        setUserName(res.data.name);
        setUserEmail(res.data.email);
        setProfilePic(res.data.profile_picture);
      }
    });
  };

  const fetchCohortDate = () => {
    getCohortDate()
      .then((res) => {
        if (res.code === 200) {
          if (res.data.cohortDate) {
            let date = moment(res.data.cohortDate).format("DD MMMM");
            setCohortDate(date);
            localStorage.setItem("cohortDate", date);
          } else {
            localStorage.setItem("cohortDate", "");
          }
        } else {
          localStorage.setItem("cohortDate", "");
        }
      })
      .catch(function (error) {
        localStorage.setItem("cohortDate", "");
      });
  };

  useEffect(() => {
    if (props.isLoggedIn) {
      getProfile();
    }
    fetchCohortDate();

    TagManager.initialize({ gtmId: "GTM-22Y3N269HB" });
  },[]);

  return (
    <Navbar
      variant="dark"
      className="navbar-wrapper"
      expand="md"
    >
      <div className="d-flex flex-row-reverse flex-md-row justify-content-between mini-header-wrapper">
        <div className="d-flex d-md-none">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeXfCmC6PQBJdOr3EXpv2oZDDI1KnmHcUilysJdmjxyN1xHlw/viewform"
            target="_blank"
          >
            <div className="course-wrapper">
              <span className="title">Next cohort starts on</span>
              <span className="date">{cohortDate}</span>
            </div>
          </a>
          {props.isLoggedIn ? (
            <Nav.Link
              className="loggedin"
              to="/Profile"
              as={Link}
            >
              <img
                src={profilePic ? profilePic : assets["profile-pic"]}
                alt=""
              />
            </Nav.Link>
          ) : (
            <Nav.Link
              className="login ms-3"
              href="#"
              onClick={() => {
                props.showLoginModal("login", true);
              }}
            >
              LOGIN
            </Nav.Link>
          )}
        </div>
        <div className="d-flex flex-row-reverse">
          <Navbar.Brand
            to="/"
            as={Link}
            className="ml-md-5 "
          >
            <img
              src={assets["logo"]}
              className="logo"
              alt=""
            />
            <img
              src={assets["header-mobile-logo"]}
              className="mobile-logo"
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="btn-hamburger"
          />
        </div>
      </div>
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="flex-gro-0"
      >
        <Nav className="mr-auto mennu-wrapper">
          {/* <Nav.Link className={splitLocation[1] === "course" ? "menu-item-active" : "menu-item"} to="/course" as={Link}>
            Our pm COURSE
          </Nav.Link> */}
          <Nav.Link
            className={"menu-item"}
            to="/product-management-course"
            as={Link}
          >
            PM Flagship Course
          </Nav.Link>
          <Nav.Link
            className={
              splitLocation[1] === "ChallengesListing"
                ? "menu-item-active menu-item"
                : splitLocation.includes("ChallengeDetails")
                ? "menu-item-active menu-item"
                : "menu-item"
            }
            to="/product-challenges"
            as={Link}
          >
            Challenges
          </Nav.Link>
          <Nav.Link
            className={
              splitLocation[1] === "about-us"
                ? "menu-item-active"
                : "menu-item micro"
            }
            href="/product-management-training-program"
          >
            Micro Courses
          </Nav.Link>

          {/* <Nav.Link className="menu-item" to="#learning">
            Continuous learning
          </Nav.Link> */}
          {/* <Nav.Link className="menu-item" href="/hire-product-managers">
      Hire From Us
     </Nav.Link> */}
          <Nav.Link
            className={
              splitLocation[1] === "pm-run"
                ? "menu-item-active menu-item"
                : splitLocation.includes("pm-run")
                ? "menu-item-active menu-item"
                : "menu-item"
            }
            href="/pm-run"
          >
            PM Run
          </Nav.Link>
          <Nav.Link
            className="menu-item"
            href="https://www.pmjobs.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jobs
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <div className="d-none d-md-flex">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeXfCmC6PQBJdOr3EXpv2oZDDI1KnmHcUilysJdmjxyN1xHlw/viewform"
          target="_blank"
        >
          <div className="course-wrapper">
            <span className="title">Next cohort starts on</span>
            <span className="date">{cohortDate}</span>
          </div>
        </a>
        {props.isLoggedIn ? (
          <Nav.Link
            className="loggedin"
            to="/Profile"
            as={Link}
          >
            <img
              src={profilePic ? profilePic : assets["profile-pic"]}
              alt=""
              onError={(e) => {
                e.target.src = assets["profile-pic"];
              }}
            />
          </Nav.Link>
        ) : (
          <Nav.Link
            className="login"
            href="#"
            onClick={() => {
              props.showLoginModal("login", true);
            }}
          >
            LOGIN
          </Nav.Link>
        )}
      </div>
    </Navbar>
  );
}

export default Header;

const addDynamicText = (day, base64Img,userName) => {
    return new Promise((resolve, reject) => {
        let modifiedUserName = userName.split(" ");
    let name = '';
    // if (modifiedUserName.length >= 3) {
    //     name = `${modifiedUserName[0][0]} ${modifiedUserName[1]} ${modifiedUserName[2]}`;
    // }
    // let newName=''
    for(let n=0;n<modifiedUserName.length;n++){
        if(modifiedUserName[n].length > 15 ){
            name+=modifiedUserName[n][0]+' ';
        }else{
            name+=modifiedUserName[n]+' ';
        }
    }
    const canvas = document.createElement("canvas");
    const date = getDate();
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = `data:image/png;base64,${base64Img}`;
    let result='';
    image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        const fontSize1 = 84;
        const fontFamily1 = "ElMessiri";
        const fontWeight1 = "bold";
        const textColor1 = "#141414";
        ctx.font = `${fontWeight1} ${fontSize1}px ${fontFamily1}`;
        ctx.fillStyle = textColor1;
        const maxLineWidth1 = 500;
        const lineHeight1 = 1.19 * fontSize1;

        // Function to split text into lines if it exceeds max width
        function wrapText(context, text, x, y, maxWidth, lineHeight) {
            let words = text.split(" ");
            let line = "";
            for (let i = 0; i < words.length; i++) {
                let testLine = line + words[i] + " ";
                let metrics = context.measureText(testLine);
                let testWidth = metrics.width;
                if (testWidth > maxWidth && i > 0) {
                    context.fillText(line, x, y);
                    line = words[i] + " ";
                    y += lineHeight;
                } else {
                    line = testLine;
                }
            }
            context.fillText(line, x, y);
        }

        const x1 = 200;
        let y1 = 650;
        const numberOfLines1 = name.length >= 15;
        if (numberOfLines1 && modifiedUserName.length > 1) {
            y1 = 550;
            wrapText(ctx, name, x1, y1, maxLineWidth1, lineHeight1);
        } else {
            wrapText(ctx, name, x1, y1, maxLineWidth1, lineHeight1);
        }

        // Second Text
        const text2 = `for successfully completing Day ${day} of PM Run conducted by PM School on ${date}`;
        const fontSize2 = 32;
        const fontFamily2 = "Montserrat"; // Different font family for the second text
        const fontWeight2 = "normal"; // Different font weight for the second text
        const textColor2 = "#141414"; // Different text color for the second text
        ctx.font = `${fontWeight2} ${fontSize2}px ${fontFamily2}`;
        ctx.fillStyle = textColor2;
        const maxLineWidth2 = 850;
        const lineHeight2 = 1.2 * fontSize2;

        const x2 = 200;
        let y2 = 750;
        // Split the text into parts
        const parts = text2.split(" ");
        let currentLine = "";
        let currentLineWidth = 0;
        let boldText = ["Day", "PM", "Run"];
        // Add numbers from 1 to 30 to the boldText array
        for (let i = 1; i <= 30; i++) {
            boldText.push(i.toString());
        }
        let isBoldPmRun = false;
        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const partWidth = ctx.measureText(part).width;

            // Check if adding the next part exceeds the max line width
            if (currentLineWidth + partWidth > maxLineWidth2 && i > 0) {
                // Draw the current line
                ctx.fillText(currentLine, x2, y2);
                y2 += lineHeight2; // Move to the next line
                currentLine = "";
                currentLineWidth = 0;
            }

            // Check if the current part is "Day 7" or "PM Run" and set font style to bold
            if (boldText.includes(part)) {
                if (part === "PM" && isBoldPmRun) {
                    continue;
                }
                ctx.font = `600 ${fontSize2}px ${fontFamily2}`;
                ctx.fillText(part, x2 + currentLineWidth, y2);
                ctx.font = `${fontWeight2} ${fontSize2}px ${fontFamily2}`; // Reset font style
                if (part === "PM") {
                    isBoldPmRun = true;
                }
            } else {
                ctx.fillText(part, x2 + currentLineWidth, y2);
            }

            currentLineWidth += partWidth + ctx.measureText(" ").width; // Add space width
        }

        // Draw any remaining text
        if (currentLine) {
            ctx.fillText(currentLine, x2, y2);
        }

        result = canvas.toDataURL("image/png");
        resolve(result)
    };
    image.onerror = (error) => {
        reject(error);
    };
    })
};

const getDate = () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.toLocaleString("default", { month: "short" });
    let year = date.getFullYear();

    // Function to add ordinal suffix to the day
    function addOrdinalSuffix(day) {
        if (day > 3 && day < 21) return `${day}th`;
        switch (day % 10) {
            case 1:
                return `${day}st`;
            case 2:
                return `${day}nd`;
            case 3:
                return `${day}rd`;
            default:
                return `${day}th`;
        }
    }
    // Format the date
    return `${addOrdinalSuffix(day)} ${month}, ${year}`;
};

export default addDynamicText;
import React, { useEffect } from "react";

// import Jitendra from "../../assets/testimonial/Jitendra-thumb.jpg";
import { LinkedinIcon } from "react-share";
import { assets } from "../../utils/assets";

const MentorsSection = () => {
  const Abhijeet = assets['Abhijeet_Vyas'];
  const Ajay = assets['Ajay Athreya'];
  const Ananya = assets['Ananya Nandan'];
  const Anuj = assets['Anuj Hegde'];
  const Aravindam = assets['B Aravindam'];
  const Debpriya = assets['Debpriya Lal'];
  const Deepak = assets['Deepak Kumar'];
  const dhanan = assets['Dhananjai Jagannathan'];
  const Hardik = assets['Hardik Gupta'];
  const harish = assets['Harish Chakravarthi'];
  const harshita = assets['Harshita SInghla'];
  const jaydeep = assets['Jaideep Kalsi'];
  const jatin = assets['Jatin Garg'];
  const Karishma = assets['Karishma Kapoor'];
  const kriti = assets['Kriti Sharma'];
  const mohhan = assets['Mohammed Hussain'];
  const prashant = assets['Prashanth Bhaskaran'];
  const pratik = assets['Pratik Upacharya'];
  const prtitu = assets['Prithu Verma'];
  const Ravya = assets['Ravyant Gupta'];
  const rohan = assets['Rohan Mitra'];
  const rutvik = assets['Ruthvik Majety'];
  const sai = assets['Sai Ruthvik'];
  const saurabh = assets['Saurabh Bhatia'];
  const shoeb = assets['Shoaib Hussain'];
  const shid = assets['Siddhant Parekh'];
  const shiddesh = assets['Siddhesh Desai'];
  const talha = assets['Talha Khan'];
  const Tushar = assets['Tushar Anand'];
  const ved = assets['Ved Prakash Pandey'];
  const virali = assets['Virali Parekh'];
  const user = assets['user'];

  // window.call_slider()
  useEffect(() => {
    // $(document).ready(function () {
    window.call_slider();
    // })
  }, []);
  return (
    <section className="for-mentor-section for-mentor-section-2">
      <section className="our-mentors">
        <div className="our-mentors-container">
          <div className="our-mentors-title">
            <h2>Testimonials</h2>
          </div>
          <div className="mentors-slider-wrapper">
            <div className="mentors-slider mentors-slider-2">
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I've been working in the startup space for quite sometime
                    now and Product Management is something I've been pretty
                    curious & enthusiastic about. While speaking to Nikunj S.
                    about this he suggested I schedule a mock interview session
                    with PM School to get an idea if I have the right
                    aptitude...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Debpriya Pal </h3>
                  <a href="https://www.linkedin.com/posts/debpriyapal_productmanagement-pmschool-mockinterviews-activity-6691636239772262400-tIHv">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Would highly recommend PM School to anyone looking to learn
                    about product management or transition into a PM role A
                    fantastic course with real life case studies and lectures
                    from PMs across various domains that gives you good
                    insights...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Samhita Kasuganti </h3>
                  <a href="https://www.linkedin.com/posts/samhita-kasuganti-a84b31156_100daysofproduct-pmschool-success-activity-6852904528468033536-S_IX">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    In the last couple of weeks when I was looking to explore
                    the #productmanagement space, I felt the need to join a
                    program where
                    <br />
                    a) I will get my basics in place
                    <br />
                    b) Get 1-on-1 mentorship
                    <br />
                    c) Be a part of an awesome product community...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Agastya Tiwari </h3>
                  <a href="https://www.linkedin.com/posts/agastyatiwari9_productmanagement-productmanager-pm-activity-6773111873786269697-3Dd3">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    "There are secret opportunities hidden inside every
                    failure."
                    <br />
                    <br />I am happy to share that I joined BYJU's as a Product
                    Manager. I am beyond grateful for everything that #pmschool
                    community has poured into me! The learnings I've gained and
                    the wisdom I've collected will stay with me forever...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Karishma Kapoor </h3>
                  <a href="https://www.linkedin.com/posts/karishma-kapoor-b8a68670_pmschool-productmanagement-activity-6860833130094428160-cqf9">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I recently completed a 2 month Product Management program at
                    PM School. This extensive course covered topics right from
                    what a PM does, problem-solving, analytics to how one should
                    prepare for an interview. I would like to thank Ankit
                    Bareja, Nikunj S. and Kushaal Devanahalli for this
                    amazing...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Anuj Hegde </h3>
                  <a href="https://www.linkedin.com/posts/activity-6834739203537010688-Oune">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I recently completed the Live Program at PM School. Would
                    recommend it to anyone looking to grasp the finer nuances of
                    product management and build further on it.
                    <br />
                    The 8-week program comprises of live lectures from industry
                    experts and case studies which simulate actual problems...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Rayvant Gupta </h3>
                  <a href="https://www.linkedin.com/posts/rayvantgupta_reinvent-your-career-with-a-course-at-pm-activity-6848624657713774592-IwUN">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I am delighted to share that I have joined Cashfree as an
                    Associate Product Manager. It’s been 3 weeks since I have
                    joined Cashfree remotely. The remote work environment has
                    made it challenging to interact and get along with the new
                    team members but I am thankful to everyone at Cashfree
                    for...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Siddhesh Desai </h3>
                  <a href="https://www.linkedin.com/posts/siddhesh-desai-aa615611b_i-am-delighted-to-share-that-i-have-joined-activity-6799372991244382208-rdho">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    First LinkedIn Post:
                    <br />
                    Hi all. I have recently made a career switch to product
                    management. It was quite a challenge for me to make this
                    shift. Hence I thought I will share 2 things that worked for
                    me, hoping that it might help a few others who are looking
                    for a PM role...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Ruthvik Majety </h3>
                  <a href="https://www.linkedin.com/posts/ruthvikmajety_join-product-manager-training-program-activity-6812594461143535616-65Yq">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    It's been two weeks since I started out as an APM with
                    Housing.com, and I think it's high time I give credit where
                    credit's due. A big thanks to PM School and the awesome team
                    running them, without you guys this would not have been
                    possible...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Prithu Verma </h3>
                  <a href="https://www.linkedin.com/posts/prithu-verma_product-productmanagement-learning-activity-6858489492962115584-UB1f">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Nervous? A little bit. 😰
                    <br />
                    Risky? Maybe.
                    <br />
                    Excited? Most definitely!
                    <br />
                    <br />
                    Super stoked to start my new role in the Product team at
                    Airtel Business as a Product Operations Manager!...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Rohan Mitra </h3>
                  <a href="https://www.linkedin.com/posts/mitrarohan_newroles-newposition-productops-activity-6844142564686274560-SZrJ">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Last October 2020, When I started to look for product
                    management communities I came across PM School they were
                    doing weekend challenges. So, I participated in a couple of
                    their challenges and got a taste of product management.
                    After that, I decided to join their January cohort (2
                    months...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Jatin Garg </h3>
                  <a href="https://www.linkedin.com/posts/jatingarg619_last-october-2020-when-i-started-to-look-activity-6785184485701947392-w1WV">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Working at Unacademy for the last couple of years has been a
                    great learning experience. It helped me develop user empathy
                    and learn about innovatively solving problems. I got to work
                    with various teams and learn from so many talented folks.
                    Thank you so much for this journey...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Kriti Sharma </h3>
                  <a href="https://www.linkedin.com/posts/kritisharma275_unstoppable-productmanagement-cohortlearning-activity-6865654342951821312-a0fH">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I am delighted to share that I have joined Crypso as a
                    Product Manager. It has been quite an experience working at
                    Crypso for past one month & the learnings from PM School
                    have definitely come in handy. When I was thinking to
                    transition into the product role, I tried to speak with a
                    few of my college seniors to...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Abhijeet Vyas </h3>
                  <a href="https://www.linkedin.com/posts/abhijeetvyas_i-am-delighted-to-share-that-i-have-joined-activity-6868197761444057088-_3m9">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Thanks, PM School !! Special thanks to Fynd (Shopsense
                    Retail Technologies Ltd.) for giving me this opportunity.
                    Anyone who wants to transits a career into Product
                    Management, please get in touch with PM School, Ankit Bareja
                    Nikunj S...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Talha Khan </h3>
                  <a href="https://www.linkedin.com/posts/talha1891_100daysofproduct-pmschool-success-activity-6877512143915245568-y8vb">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I don't generally post on LinkedIn. But would like to make
                    an exception and give a shout-out to PM School. Having
                    undertaken multiple online courses related to product
                    management, I stumbled upon PM school on LinkedIn and
                    started following their page and weekly challenges, and...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Sagar Dang </h3>
                  <a href="https://www.linkedin.com/posts/sagar-dang_pmschool-productmanagement-activity-6884338108104511488-At_v">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Kudos to Ankit Bareja Nikunj S. Kushaal Devanahalli of PM
                    School for crafting an excellent 8-week cohort program. PM
                    School's Youtube content on interview prep & Hiring Case
                    Challenges will help alot in Portfolio building & Interview
                    prep...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Maruthi Ram M N V </h3>
                  <a href="https://www.linkedin.com/posts/activity-6897522377245241344-uLDn?utm_source=share&utm_medium=member_desktop">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Appreciation post for PM School.
                    <br />
                    <br />I have been getting all the support and guidance from
                    Ankit, Nikunj, and Kushaal since day 1 when I decided to
                    pursue PM as a career. One of the learnings that I had
                    personally is that if you really want to thrive in this
                    career don't look for hacks just to clear interviews instead
                    focus on structuring the problem...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Hardik Gupta</h3>
                  <a href="https://www.linkedin.com/posts/guptahardik_appreciation-post-for-pm-school-i-have-activity-6854073982954614784-d-Ud">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I stumbled on PM School weekend challenges when I was
                    looking for a way to get into a Product role. By winning the
                    weekend challenge, I got a chance for a mock interview with
                    Nikunj. Later in the year, I decided to join the PM School
                    cohort...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Virali Parekh</h3>
                  <a href="https://www.linkedin.com/posts/virali-parekh_mentorship-productmanager-productmanagement-activity-6777854638772260864-RJxm">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Recently I joined this 8-week training program by PM School
                    which helped me gain a better understanding of product
                    management. PM School has a well-structured course in which
                    they try to cover all the topics which are essential to
                    break into a PM role...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Siddhant Parekh</h3>
                  <a href="https://www.linkedin.com/posts/siddhant-parekh-03a392171_product-pmschool-productmanagement-activity-6801837233117638656-oAeI">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Hello Everyone,
                    <br />
                    It's been more than a month since I joined SugarBox Networks
                    as a PM intern and journey so far has been really exciting
                    and interesting. I am working with a bunch of really
                    talented people who have been helping me through a career
                    towards Product management...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Dhananjai Jagannathan</h3>
                  <a href="https://www.linkedin.com/posts/dhananjai-jagannathan-mdi_product-intern2022-newjob-activity-6888008658211495936-xlLa">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I recently had a chance to participate in a mock PM
                    interview with Ankit Bareja from PM School. It was such an
                    interesting interview. From the set of questions to the
                    valuable and actionable feedback, everything was just
                    amazing!...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Jaideep Kalsi</h3>
                  <a href="https://www.linkedin.com/posts/jaideep-kalsi-705351145_productmanagement-communities-activity-6761278114313187328-KBAU">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    | First Post |
                    <br />
                    Since Placement season is approaching, Let me share my story
                    with you : It started with solving Case studies and getting
                    mock interviews with Mentors, I remember last year Ankit
                    telling me, “You should go off-campus Shoaib, you’ve got a
                    good profile”. Eventually, won many Case Study competitions
                    and was able to create my Portfolio. Indeed it was
                    Challenging...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Shoaib Hussain</h3>
                  <a href="https://www.linkedin.com/posts/-shoaib-hussain_productmanagement-product-placements-activity-6815300837867360256-mX08">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Hi everyone! I am delighted to share that I’ve joined Ola as
                    Product Manager. Special Thanks to my alumni network for
                    introducing me to this opportunity and the amazing team at
                    PM School for guiding me during the whole preparation phase.
                    I never would have been able to reach this milestone without
                    the mentors I've so fortunately met in my life...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Tushar Anand</h3>
                  <a href="https://www.linkedin.com/posts/anand-tushar_newjob-productmanagement-ola-activity-6890184142672527360-lpN9">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    A PM School appreciation post :)
                    <br />
                    <br />
                    Product management being a new domain, it doesn't really
                    have a proper curriculum as such, colleges don't teach the
                    subject and all that aspiring entrants can do is watch a few
                    Youtube videos and read a few blogs. I was in the same
                    boat...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Prashanth Bhaskaran</h3>
                  <a href="https://www.linkedin.com/posts/prashanthbhaskar_pmschool-learning-career-activity-6848521842605535232--B8u">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Hi everyone, I have started a new chapter, as a Product
                    Analyst at inFeedo after 2 years in Myntra as a Data
                    Analyst. I would like to express my sincere gratitude to
                    Ankit Bareja & Nikunj S. from PM School for helping me
                    iterate through my resume, providing references,
                    motivating...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>B Aravindram</h3>
                  <a href="https://www.linkedin.com/posts/b-aravindram_hi-everyone-i-have-started-a-new-chapter-activity-6829577149146652672-AOar">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Happy to announce that I have joined ShareChat as a Product
                    Manager. I would like to thank PM School and Nikunj S. for
                    helping me throughout this job search. Nikunj S. personally
                    mentored and motivated me from time to time. I would also
                    like to thank Rathinamurthy R and Crio...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Pratik Upacharya</h3>
                  <a href="https://www.linkedin.com/posts/pratikupacharya_sharechat-productmanager-pmschool-activity-6823096817757024256-Ej8C">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Appreciation post for PM School. (PS: This is not a
                    certificate boasting post). As soon as my interest in
                    product management grew, I knew it would be an added
                    advantage to get proper guidance and mentorship to get
                    through this competitive field...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Mohammad Hussain</h3>
                  <a href="https://www.linkedin.com/posts/activity-6713443138452688896-fRl3">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    My first day at Mobile Premier League (MPL) as an Associate
                    Product Manager has been nothing less than exciting and
                    exhilarating, to say the least. With a smooth onboarding
                    process, all thanks to Manpreet Walia & Ishita Jain, a
                    glimpse into one of the companies that are revolutionizing
                    the gaming industry has been one amazing day...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Ananya Nandan</h3>
                  <a href="https://www.linkedin.com/posts/ananyanandan_newjob-productmanagement-activity-6886224207278030848-NAJP">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I want to take a moment to thank the team at PM School. With
                    their guidance, I was able to clear assignment rounds of
                    most of the companies which were hiring for APM/Product
                    Analyst during my college placements...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Saurabh Bhatia</h3>
                  <a href="https://www.linkedin.com/posts/saurabh-bhatia02_i-want-to-take-a-moment-to-thank-the-team-activity-6652276802939121664-vOed">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I've recently joined AirBlack as Product Management Intern.
                    The PM School has been a driving force behind the whole
                    process. Their 2-month well-structured Product management
                    coursework along with practice interviews helped me evolve
                    and fine-tune my concepts about PM...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Harish Chakravarthi</h3>
                  <a href="https://www.linkedin.com/posts/harish-chakravarthi-44818872_productmanagement-product-activity-6680942965541740544-E_7t">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    A PM School appreciation post. I recently completed 8 week
                    Live Program at PM School and would recommend to any one who
                    is looking to learn every aspect of Product Management.
                    Whether you are looking to switch to Product Management or
                    you are already an APM and looking to get the fundamentals
                    right, Live Program is the go to course for you. As a part
                    of the Live Program, live lectures from industry experts,...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Deepak Kumar</h3>
                  <a href="https://www.linkedin.com/posts/dkr17_pm-school-home-activity-7047545091120324608-BO__?utm_source=share&utm_medium=member_desktop">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    Happy to announce that I have joined ShareChat as a Product
                    Manager. I would like to thank PM School and Nikunj S. for
                    helping me throughout this job search. Nikunj S. personally
                    mentored and motivated me from time to time...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Jayesh Rout</h3>
                  <a href="https://www.linkedin.com/posts/activity-7038393743602790400-fcVP?utm_source=share&utm_medium=member_desktop">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I am excited to announce that last month I have joined
                    WhizAI as an #Analytics #ProductManager! With 8 years of
                    experience in building Analytical products & services, I am
                    looking forward to utilizing my skills in this new role and
                    contributing to the growth of the company...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Ved Prakash Pandey</h3>
                  <a href="https://www.linkedin.com/posts/ved-prakash-pandey-845b4995_analytics-productmanager-newjob-ugcPost-7038062008516132864-9MDd?utm_source=share&utm_medium=member_desktop">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
              <div className="slide-container">
                <div className="mentors-thumb">
                  <span className="quote quote__top">“</span>
                  <p>
                    I have completed Product Management course from PM School
                    with great learnings. This helped me to an effective switch
                    in job to product management domain. The one to one
                    mentorship provided at PM school is very helpful. PM school
                    has it's own seperate placement cell which is very helpful
                    to find you a right job in product domain...
                  </p>
                  <span className="quote quote__bottom">,,</span>
                </div>
                <div className="mentors-content">
                  <h3>Sai Ruthvik</h3>
                  <a href="https://www.linkedin.com/posts/sairuthvik_productmanagement-productcareer-activity-6933709451010945024-gfSG?utm_source=share&utm_medium=member_desktop">
                    <LinkedinIcon />
                    <span>View Profile on Linkedin</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="mentors-dslider">
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Debpriya} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={user} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={user} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Karishma} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Anuj} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Ravya} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={shid} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={rutvik} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={prtitu} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={rohan} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={jatin} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={kriti} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Abhijeet} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={talha} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={user} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={user} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Hardik} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={virali} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={shid} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={dhanan} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={jaydeep} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={shoeb} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Tushar} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={prashant} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Aravindam} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={pratik} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={mohhan} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Ananya} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={saurabh} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={harish} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={Deepak} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={user} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={ved} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
              <div className="mslide-btn">
                <div className="dlogo-thumb">
                  <span className="thumb">
                    <img loading="lazy" src={sai} alt="Logo" />
                  </span>
                  <svg
                    className="progress-circle"
                    viewBox="0 0 33.83098862 33.83098862"
                    width="107"
                    height="107"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle-chart__background"
                      stroke="#fff"
                      stroke-width="1"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                    <circle
                      className="progress-line"
                      stroke="#636363"
                      stroke-width="1"
                      stroke-dasharray="30,100"
                      stroke-linecap="round"
                      fill="none"
                      cx="16.91549431"
                      cy="16.91549431"
                      r="15"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default MentorsSection;

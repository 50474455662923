import React, { useCallback } from "react";
import "./upload-drive.scss";
import { TosterMessageSuccess, TosterMessageError } from "../../Toster/Toster";
// import { FileDrop } from 'react-file-drop';
import { setGoogleDriveLink } from "../../../api/ApiRequest";
import { globalStrings } from "../../../strings";
import { assets } from "../../../utils/assets";
function UploadDriveLink(props) {
  const uploaddrive = assets["uploaddrive"];
  const [val, setVal] = React.useState(props.linkUrl);

  const changeDriveLink = (e) => {
    setVal(e.target.value);
  };

  const handleClick = (event) => {
    setGoogleDriveLink(props.entryId, val)
      .then((res) => {
        if (res.code === 200) {
          //
          props.onChangeView("", false);
          TosterMessageSuccess({ msg: "Google drive url successfully saved" });
        } else {
          //
          TosterMessageError({ msg: globalStrings.something_went_wrong });
        }
      })
      .catch((err) => {
        TosterMessageError({ msg: globalStrings.something_went_wrong });
      });
  };

  return (
    <div className="upload-files-conatiner">
      <h3 className="title">Upload Link For Challenge</h3>
      <div style={{ textAlign: "center" }}>
        <img
          style={{ height: "150px", width: "150px" }}
          src={uploaddrive}
          loading="lazy"
        />
      </div>
      {/* <h5 style={{fontWeight : "bold", textAlign : "center", paddingTop : "30px"}}> </h5> */}
      <div className="field-wrapper">
        <input
          className="input-field"
          value={val}
          placeholder="Add Google Drive Link"
          onChange={changeDriveLink}
        />
      </div>
      <div className="btnWrapper">
        <button
          className={`btn-green ${val ? "" : "disabled-btn"}`}
          disabled={val ? false : true}
          onClick={handleClick}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
export default UploadDriveLink;

import React from 'react'


const Challenge = () => {
    return (
        <section className="our-challenges">
            <div className="content-container d-flex flex-column flex-md-row justify-content-around">
                <div className="challenge-name d-flex justify-content-center flex-column">
                    <span className="title">Our challenges</span>
                    <h3 className="desc">
                        Enter our unique challenges tailor-made for different PM
                        situations
                    </h3>
                    <a href="product-challenges" id="challenge_card_active_link" style={{ textDecoration: 'none', color: 'unset' }} 
                        rel="noreferrer">
                        <button className="btn-black">enter challenge</button>
                    </a>
                </div>
                <div id="challenge_card_active" style={{overflow : 'hidden'}} className="challenge-card">

                </div>
            </div>
        </section>
    )
}

export default Challenge
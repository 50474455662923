import React from "react";
import CourseIcon from "../../../../assets/Course.svg";
import "./index.scss";

const CourseDetail = ({ icon, title }) => {
  return (
    <div className="course__detail">
      <div className="img__wrapper">
        <img loading="lazy" src={icon} alt="" />
      </div>
      <span>{title}</span>
    </div>
  );
};

export default CourseDetail;

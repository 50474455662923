import "./participate-challenge.scss";
import { assets } from "../../../utils/assets";
import { useState, useEffect } from "react";
import HtmlParser from "react-html-parser";
import moment from "moment";

import { FacebookShareButton, LinkedinShareButton } from "react-share";

//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';

const Past_challenge_details = (props) => {
  const [isbtnActive, setBtnActive] = useState(false);
  const [challenge, setChallengeData] = useState();
  const [ranks, setRanks] = useState();
  const PMuserId = localStorage.getItem("PMuserId");
  const [entries, setEntries] = useState([]);
  useEffect(() => {
    console.log("Share URL ->", window.location.href);
    console.log("------------------------------");
    if (props.challenge) {
      var diff = new Date(props.challenge.end_date_timestamp) - new Date();
      props.challenge["days_difference"] = Math.round(
        diff / (1000 * 3600 * 24)
      );
      // Math.round(diff
      setChallengeData(props.challenge);
      setEntries(props.entries);
    }
    if (props.ranks) {
      setRanks(props.ranks);
    }
  }, []);

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return HtmlParser(value);
  }

  return (
    <>
      {challenge && (
        <div className="current-challenge-section">
          <div className="challenge-wrapper">
            <div>
              <img
                src={challenge.logo || challenge.image}
                className="challenges-card-body-logo"
                loading="lazy"
              ></img>
            </div>
            {props.allowSubmission ? (
              <span className="challenge-no">
                Live Challenge no. {challenge.number}
              </span>
            ) : (
              <span className="challenge-no-past">
                Past Challenge no. {challenge.number}
              </span>
            )}
            <h3 className="challenge-name">{challenge.name}</h3>
            {props.allowSubmission ? (
              <>
                <div className="reward-deadline-info">
                  <div
                    className="deadline"
                    style={{ borderRight: 0 }}
                  >
                    <span className="title">
                      Deadline{" "}
                      <span className="days-left">
                        {challenge.days_difference == 1
                          ? "1 day left"
                          : challenge.days_difference == 0
                          ? "less than 1 day left"
                          : challenge.days_difference + " days left"}
                      </span>
                    </span>
                    <span className="description">
                      {moment(challenge.end_date_timestamp).format(
                        "Do MMM YY, hh:mm A"
                      ) + " IST"}
                    </span>
                  </div>
                </div>
                <div
                  className="reward-deadline-info"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="reward"
                    style={{ flex: 1 }}
                  >
                    <span className="title">points</span>
                    <span className="description">{challenge.points}</span>
                  </div>
                  <div
                    className="reward"
                    style={{ flex: 1 }}
                  >
                    <span className="title entries">entries</span>
                    <span className="description">
                      {challenge["all_entries"]
                        ? challenge["all_entries"].length
                        : entries.length > 0
                        ? entries.length
                        : 0}{" "}
                      People
                    </span>
                  </div>
                </div>

                {props?.entries?.filter((x) => x?.user_id == PMuserId)?.length >
                0 ? (
                  <>
                    <div className="ald_sub_app">
                      <span className="title entries">
                        You have already submitted the application.
                      </span>
                    </div>
                    <div className="btnWrapper">
                      <button
                        className="btn-yellow"
                        onClick={() => {
                          if (props.isLoggedIn) {
                            props.setChallengeId({
                              challenge_id: challenge._id,
                              end_date_timestamp: challenge.end_date_timestamp,
                            });
                            props.showUploadScreen("uploadModal", true);
                          } else {
                            props.setChallengeId({
                              challenge_id: challenge._id,
                              end_date_timestamp: challenge.end_date_timestamp,
                            });
                            props.showUploadScreen("login", true);
                          }
                        }}
                      >
                        Re-submit
                      </button>
                      {/* <button className="btn-share">
                  <img alt="" src={btnshare}></img>
                </button> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="btnWrapper">
                      <button
                        className="btn-green"
                        onClick={() => {
                          if (props.isLoggedIn) {
                            props.setChallengeId({
                              challenge_id: challenge._id,
                              end_date_timestamp: challenge.end_date_timestamp,
                            });
                            props.showUploadScreen("uploadModal", true);
                          } else {
                            props.setChallengeId({
                              challenge_id: challenge._id,
                              end_date_timestamp: challenge.end_date_timestamp,
                            });
                            props.showUploadScreen("login", true);
                          }
                        }}
                      >
                        Participate Now
                      </button>
                      {/* <button className="btn-share">
                  <img alt="" src={btnshare}></img>
                </button> */}
                    </div>
                  </>
                )}
              </>
            ) : null}
            {!props.allowSubmission ? (
              <>
                <div className="past-challenge-time">
                  <span className="waslive">Was live from</span>
                  <span className="time-period">
                    {moment(challenge.start_date_timestamp).format(
                      "Do MMM YY, hh:mm A"
                    ) + " IST"}
                  </span>
                  <span className="waslive till">Was live till</span>
                  <span className="time-period">
                    {moment(challenge.end_date_timestamp).format(
                      "Do MMM YY, hh:mm A"
                    ) + " IST"}
                  </span>
                </div>
                {ranks && ranks.length == 5 && (
                  <div className="winner-announced">
                    <span className="no-of-winner">
                      {ranks.length} Winners announced
                    </span>
                    <div className="img-wrapper">
                      {ranks.map((item, idx) => {
                        let profilepicture =
                          item.profile_picture && item.profile_picture !== ""
                            ? item.profile_picture
                            : assets["profile-pic"];
                        return (
                          <img
                            loading="lazy"
                            key={idx}
                            className="winner-img"
                            alt=""
                            src={profilepicture}
                          ></img>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
          <div className="challenge-information">
            <span className="title">You are?</span>
            <span className="description">
              {Parser(challenge.persona_text)}
            </span>
            <span className="title">Type</span>
            <span className="description">{challenge.type_text}</span>
            {props.allowSubmission ? (
              <>
                <span className="title">Winners get</span>
                {challenge.winners_get_text && (
                  <span className="description winners-get">
                    <img
                      loading="lazy"
                      alt=""
                      src={assets["winner-icon"]}
                    />
                    1st - {Parser(challenge.winners_get_text)}
                  </span>
                )}

                {challenge.winners_get_text2 && (
                  <span className="description winners-get">
                    <img
                      loading="lazy"
                      alt=""
                      src={assets["winner-icon-2"]}
                    />
                    2nd - {Parser(challenge.winners_get_text2)}
                  </span>
                )}

                {challenge.winners_get_text3 && (
                  <span className="description">
                    <img
                      loading="lazy"
                      alt=""
                      src={assets["winner-icon-3"]}
                    />
                    3rd - {Parser(challenge.winners_get_text3)}
                  </span>
                )}
              </>
            ) : null}
            <span className="title">The challenge is?</span>
            <span className="sub-description">
              {Parser(challenge.challenge_text)}
            </span>
          </div>
          {true && (
            <div className="navigation-btn-wrapper">
              {true ? (
                <>
                  <button
                    className="btn-share"
                    onClick={() => {
                      setBtnActive(!isbtnActive);
                    }}
                  >
                    {isbtnActive ? (
                      <img
                        loading="lazy"
                        alt=""
                        src={assets["floating-close"]}
                      ></img>
                    ) : (
                      <img
                        loading="lazy"
                        alt=""
                        src={assets["btn-share-icon"]}
                      ></img>
                    )}
                  </button>
                  <div
                    className={`btnShareWrapper d-flex flex-column ${
                      isbtnActive ? "active" : ""
                    }`}
                  >
                    {/* <button className="btn-tw"> */}
                    <FacebookShareButton
                      className="btn-tw"
                      url={window.location.href}
                      quote={Parser(challenge.challenge_text)}
                    >
                      <img
                        loading="lazy"
                        alt=""
                        src={assets["floating-facebook"]}
                      ></img>
                    </FacebookShareButton>
                    {/* </button> */}
                    {/* <button className="btn-in">
                  <img alt="" src={insta}></img>
                </button> */}
                    {/* <button className="btn-ld"> */}
                    <LinkedinShareButton
                      className="btn-ld"
                      url={window.location.href}
                    >
                      <img
                        loading="lazy"
                        alt=""
                        src={assets["floating-linkedin"]}
                      ></img>
                    </LinkedinShareButton>
                    {/* </button> */}
                    {/* <button className="btn-yt">
                  <img alt="" src={youtube}></img>
                </button> */}
                  </div>
                </>
              ) : null}
              {/* <button className="btn-right-nav">
            <img alt="" src={rightNav}></img>
          </button>
          <button className="btn-left-nav">
            <img alt="" src={leftNav}></img>
          </button> */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

// function ParticipateChallenge(props) {

// }

export default Past_challenge_details;

import React from "react";
import "./index.scss";
import { assets } from "../../../utils/assets";

const Classes = () => {
  const class1 = assets['c1'];
  const laptop = assets['c3'];
  const men2 = assets['c4'];
  const men3 = assets['c2'];
  const cm1 = assets['cm1'];
  const cm2 = assets['cm2'];
  const cm3 = assets['cm3'];
  const cm4 = assets['cm4'];
  return (
    <section className="classes-wrap classes-wrap-2">
      <div className="container-wrap">
        <div className="classes-row-wrap">
          <div className="classes-box">
            <div className="classes-row-img">
              <img
                loading="lazy"
                src={class1}
                className="desktopbanner"
                alt="Cohort Based Product Management Course in India"
              />
              <img
                loading="lazy"
                src={cm4}
                className="mobilebanner"
                alt="Cohort Based Product Management Course in India"
              />
              <img
                loading="lazy"
                src={cm4}
                className="ipedbanner"
                alt="Cohort Based Product Management Course in India"
              />
            </div>
            <div className="classes-row-content">
              <h3>1</h3>
              <p>
                Join <br></br>PM School
              </p>
            </div>
          </div>

          <div className="classes-box">
            <div className="classes-row-img">
              <img
                loading="lazy"
                src={laptop}
                className="desktopbanner"
                alt="Learn with Product Management Courses Online"
              />
              <img
                loading="lazy"
                src={cm1}
                className="mobilebanner"
                alt="Cohort Based Product Management Course in India"
              />
              <img
                loading="lazy"
                src={cm1}
                className="ipedbanner"
                alt="Cohort Based Product Management Course in India"
              />
            </div>
            <div className="classes-row-content">
              <h3>2</h3>
              <p>
                Practise <br></br> Interviews
              </p>
            </div>
          </div>

          <div className="classes-box">
            <div className="classes-row-img">
              <img
                loading="lazy"
                src={men2}
                className="desktopbanner"
                alt="Best Product Management Courses"
              />
              <img
                loading="lazy"
                src={cm3}
                className="mobilebanner"
                alt="Cohort Based Product Management Course in India"
              />
              <img
                loading="lazy"
                src={cm3}
                className="ipedbanner"
                alt="Cohort Based Product Management Course in India"
              />
            </div>
            <div className="classes-row-content">
              <h3>3</h3>
              <p>
                Create <br></br>your portfolio
              </p>
            </div>
          </div>

          <div className="classes-box">
            <div className="classes-row-img">
              <img
                loading="lazy"
                className="desktopbanner"
                src={men3}
                alt="Achieve Success with PM Schools Product Management Course"
              />
              <img
                loading="lazy"
                src={cm2}
                className="mobilebanner"
                alt="Cohort Based Product Management Course in India"
              />
              <img
                loading="lazy"
                src={cm2}
                className="ipedbanner"
                alt="Cohort Based Product Management Course in India"
              />
            </div>
            <div className="classes-row-content">
              <h3>4</h3>
              <p>
                Get Placement <br></br> support
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Classes;

import React, { useEffect } from "react";
import $ from "jquery";
import { assets } from "../../utils/assets";

const WhyPmSchool = () => {
  useEffect(() => {
    // setTimeout(() => {
    //     window.aboutUsVideo()
    // }, 200);
    $(document).ready(function () {
      $(".round-play-btn_about").click(function () {
        $(".video-wrapper").addClass("play");
        $(".video").trigger("play");
      });
      $(".video").click(function () {
        if (this.paused) {
          this.play();
        } else {
          this.pause();
          $(".video-wrapper").toggleClass("play");
          // videoEventsAboutUs()
        }
      });
    });
    // function videoEventsAboutUs() {
    //   $(".video-wrapper").toggleClass("play");
    // }
  }, []);

  return (
    <section className="why-pm-school">
      <div className="video-wrapper">
        <img
          src={assets["why-pm-school"]}
          alt=""
          className="video  d-none"
          loading="lazy"
        />
        <video
          className="video"
          onplay="videoEventsAboutUs()"
          onpause="videoEventsAboutUs()"
          poster={assets["active-video-thumb"]}
        >
          <source
            src={assets["pm_school_video"]}
            type="video/mp4"
          />
        </video>
        <button className="round-play-btn_about">Play</button>
      </div>
      <div className="content-wrapper">
        <h3 className="title">Why we started PM School?</h3>
        <p className="description">
          Nikunj, Ankit and Kushaal had been mentoring juniors from their
          respective alma maters BITS. Pilani and IIT Bombay crack PM roles.
          Although we helped people independently, we got talking about the
          dearth of mentorship for a candidate trying to crack the first PM
          role. A bunch of people whom we helped crack a PM role really
          appreciated our approach. This was a big validation for us. Also, as
          hiring managers, we’d go to the top colleges of India to hire. We saw
          a huge interest in the role but the knowledge required to be a PM
          wasn’t there. In India, academia hasn’t kept up with the ever-evolving
          tech/product landscape. Voila, that’s how we started!
        </p>
      </div>
    </section>
  );
};

export default WhyPmSchool;

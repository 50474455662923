import React, { useState } from "react";

import { changePasswordRequest } from '../../../api/ApiRequest';
import { TosterMessageSuccess, TosterMessageError } from '../../Toster/Toster';
import { globalStrings } from '../../../strings';

import "./reset-password.scss";
function ResetPassword(props) {
  const [isShow, setIsShow] = useState(false);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  const handleOldPassword = (e) => {
    setOldPassword(e.target.value);
  }

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  }

  const ChangePassword = () => {
    changePasswordRequest(props.email, oldPassword, newPassword).then(res => {
      if (res.code === 200) {
        // 
        props.onChangeView("", false);
        TosterMessageSuccess({ msg: globalStrings.change_pass_success });
      } else {
        // 
        TosterMessageError({ msg: globalStrings.something_went_wrong });
      }
    }).catch(err => {
      let msg;
      
      if (err == "INVALID_CREDENTIALS") {
        msg = globalStrings.change_pass_fail;
      } else {
        msg = globalStrings.something_went_wrong;
      }
      TosterMessageError({ msg: msg });
    })
  }

  return (
    <div className="reset-password-container">
      <div className="content-body">
        <h3 className="title">Reset Password</h3>
        <div className="field-wrapper">
          <input className="input-field" type="text" placeholder="Old Password" onChange={handleOldPassword} />
        </div>
        <div className="field-wrapper d-flex justify-content-between">
          <input
            className="input-field"
            type={isShow ? "text" : "password"}
            placeholder="New Password"
            onChange={handleNewPassword}
          />
          <button
            onClick={() => {
              setIsShow(!isShow);
            }}
            className="visible-icon"
          ></button>
        </div>
        <div className="btnWrapper">
          <button onClick={ChangePassword} className="btn-green">Reset Password</button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
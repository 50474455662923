import React, { useState } from "react";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Toster from "../src/pages/Toster/Toster";
import sampleComponent from "../src/pages/sampleComponent/sampleComponent";
import "./App.scss";
import ProtectedRoute from "./ProtectedRoute";
import ChallengesDetails from "./pages/challenges-details";
import ChallengesListing from "./pages/challenges-listing";
import Footer from "./pages/comon-component/footer";
import Header from "./pages/comon-component/header";
import MicroCourse from "./pages/micro-course";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AboutUs from "./pages/about-us";
import ScrollToTop from "./pages/comon-component/scroll-to-top";
import course from "./pages/course";
import HomePage from "./pages/homepage";
import ModalPages from "./pages/modal-pages";
import PmRun from "./pages/pm-run";
import PmRunAchieve from "./pages/pm-run-achieve";
import PmRunQna from "./pages/pm-run-qna";
import Profile from "./pages/profile";
import TermsAndCondition from "./pages/terms-and-condition";
import OurPmCourse from "./pages/testimonials";
import HistoryPage from "./pages/pm-run/History-page/History-page";
import { Upload } from "./pages/comon-component/modal/Upload";
import { CommonMetaData } from "./pages/comon-component/header/CommonMetaData";
import SharedProfile from "./pages/profile/SharedProfile";

function App() {
  // window.$crisp = [];
  // window.CRISP_WEBSITE_ID = "9c06418a-7e5b-44aa-828e-1973938a440e";
  // (function () {
  //   var d = document;
  //   var s = d.createElement("script");

  //   s.src = "https://client.crisp.chat/l.js";
  //   s.async = 1;
  //   d.getElementsByTagName("head")[0].appendChild(s);
  // })();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("login");
  const [redirectUrl, setRedirectUrl] = useState();
  //  console.log(
  //   "is logged in ? ",
  //   !!localStorage.getItem("PMuserId") && !!localStorage.getItem("PMtoken")
  //  );
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("PMuserId") && !!localStorage.getItem("PMtoken")
  );
  const [userEmail, setUserEmail] = useState();
  const [challengeId, setChallengeId] = useState({});
  const [entryId, setEntryId] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [selectedFile, setFile] = useState();
  const _modalConfig = (type, show, redirect) => {
    setShowModal(show);
    setModalType(type);
  };
  const currentRoute = window.location.pathname;

  return (
    <>
      <BrowserRouter>
        <Header
          showLoginModal={_modalConfig}
          changeLoginState={setIsLoggedIn}
          isLoggedIn={isLoggedIn}
        />
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/ChallengesDetails"
            component={(props) => (
              <ChallengesDetails
                isLoggedIn={isLoggedIn}
                onModalChange={_modalConfig}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/ChallengeDetails"
            component={(props) => (
              <ChallengesDetails
                isLoggedIn={isLoggedIn}
                onModalChange={_modalConfig}
                setChallengeId={setChallengeId}
                {...props}
              />
            )}
          />
          <Route exact path="/product-management-course" component={course} />
          <Route
            exact
            path="/product-challenges"
            component={ChallengesListing}
          />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/terms-and-condition"
            component={TermsAndCondition}
          />
          <Route
            exact
            path="/refund-and-cancellation"
            component={TermsAndCondition}
          />
          <Route exact path="/testimonials" component={OurPmCourse} />

          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <Route exact path="/shared-profile" component={Profile} />
          <Route
            exact
            path="/product-management-training-program"
            component={MicroCourse}
          />
          <Route
            exact
            path="/pm-run"
            component={(props) => (
              <PmRun
                showLoginModal={_modalConfig}
                changeLoginState={setIsLoggedIn}
                isLoggedIn={isLoggedIn}
                setRedirectUrl={setRedirectUrl}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/product-management-question"
            component={PmRunQna}
          />
          <Route exact path="/pm-run/achievements" component={PmRunAchieve} />
          <Route exact path="/pm-run/social-upload" component={Upload} />
          <ProtectedRoute
            exact
            path="/Profile"
            component={() => (
              <Profile
                changeLoginState={setIsLoggedIn}
                onModalChange={_modalConfig}
                setUserEmail={setUserEmail}
                setEntryId={setEntryId}
                setLinkUrl={setLinkUrl}
              />
            )}
          />
          <ProtectedRoute exact path="/sample" component={sampleComponent} />
          <Route exact path="/history" component={HistoryPage} />
          {/* <Route exact path="/linkedin" component={LinkedInPopUp} /> */}
        </Switch>
        <Footer />
        <ModalPages
          type={modalType}
          show={showModal}
          onModalChange={_modalConfig}
          changeLoginState={setIsLoggedIn}
          isLoggedIn
          userEmail={userEmail}
          setUserEmail={setUserEmail}
          challengeId={challengeId}
          setChallengeId={setChallengeId}
          setEntryId={setEntryId}
          entryId={entryId}
          selectedFile={selectedFile}
          setFile={setFile}
          setLinkUrl={setLinkUrl}
          linkUrl={linkUrl}
          redirectUrl={redirectUrl}
        />
      </BrowserRouter>
      <Toster />
    </>
  );
}

export default App;

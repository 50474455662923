import React from 'react'
import { assets } from '../../utils/assets'


const Weeks = () => {
    const week1=assets['week-icon-1'];
    const week2=assets['week-icon-2'];
    const Shantanu=assets['Shantanu-Korada'];
    const Mohammad=assets['Mohammad-Hussain'];
    const Debpriya=assets['Debpriya-Pal'];
    const Debarati=assets['Debarati-Bhattacharjee'];
    const Chandrashekhar=assets['Chandrashekhar-Koushik'];
    const Deepak=assets['Deepak-Doguparti'];
    const Parijat=assets['Parijat-Kalita'];
    const Anu = assets['Anu-Sharma'];
    return (
        <section className="week-section-wrap">
            <div className="container-wrap">
                <div className="week-row-wrap">
                    <div className="week-button">
                        <ul className="nav nav-tabs">
                            <li><a className="active" data-toggle="tab" href="#week1">Week 1</a></li>
                            <li><a data-toggle="tab" href="#week2">Week 2</a></li>
                            <li><a data-toggle="tab" href="#week3">Week 3</a></li>
                            <li><a data-toggle="tab" href="#week4">Week 4</a></li>

                            <li><a data-toggle="tab" href="#week5">Week 5</a></li>
                            <li><a data-toggle="tab" href="#week6">Week 6</a></li>
                            <li><a data-toggle="tab" href="#week7">Week 7</a></li>
                            <li><a data-toggle="tab" href="#week8">Week 8</a></li>
                        </ul>
                    </div>
                    <div className="week-content">
                        <div className="tab-content">
                            <div id="week1" className="tab-pane fade in active show">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>Introduction to PM role</h2>
                                        <ul>
                                            <li>Who is a Product Manager - Role, skill sets </li>
                                            <li>Where does a PM fit?</li>
                                            <li>Technology Products </li>
                                            <li>Different Types of PM </li>
                                            <li>Product Lifecycle</li>
                                            <li>Research analysis</li>
                                            <li>Basics of what is to come ahead in the course</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>A GTM assignment for a fintech startup product</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Framework(s)</p>
                                                <h6>Product Strategy, TG Identification, Differentiators-first approach </h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Shantanu} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>I had been trying to get into a PM role for a while but with PM being the latest market
                                                    craze, there were a tonne of resources out there that made the choice to select one difficult.
                                                    Fortunately ended up choosing PM School and it had been an amazing experience for me, right
                                                    from the course learnings, to the referrals that I got via them </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="week2" className="tab-pane fade">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>Problem Solving</h2>
                                        <ul>
                                            <li>Problem Definition </li>
                                            <li>Killer Product Ideas</li>
                                            <li>Market and Opportunity Sizing</li>
                                            <li>Crystallizing Business Needs</li>
                                            <li>SWOT Analysis</li>
                                            <li>Doing the right research</li>
                                            <li>Getting the Assumptions right</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>Monetisation problem assignment + New vertical entrant scoping</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Framework(s)</p>
                                                <h6>Top-down, Bottom-up approach, SWOT, Competitor analysiss</h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Mohammad} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>Getting to be part of the first cohort of PM school really helped me. In the two month
                                                    cohort, I learned about various aspects of Product Management such as acquisition, retention,
                                                    UX, roadmap, development, different Technologies & product analytics. I got to know more about
                                                    real-world product management instead of bookish knowledge.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="week3" className="tab-pane fade">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>UX Design</h2>
                                        <ul>
                                            <li>Customer Research</li>
                                            <li>Good vs Great Design principles</li>
                                            <li>User Personas</li>
                                            <li>Design Thinking</li>
                                            <li>Design Tools</li>
                                            <ol type="abc">
                                                <li>Functional mapping</li>
                                                <li>Prototyping basics</li>
                                                <li>Wireframing</li>
                                            </ol>
                                            <li>Design Process</li>
                                            <li>User Interviews</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>User Persona, Feature suggestions and Wireframing assignment</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Framework(s)</p>
                                                <h6>Figma or Balsamiq</h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Debpriya} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>The coursework is pretty hands - on with active discussions, really cool mentors and some
                                                    really interesting projects. A special shoutout to the mentors Nikunj Sharma, Ankit Bareja and
                                                    Kushaal Devanahalli - amazing people, always willing to lend an ear and motivate you.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="week4" className="tab-pane fade">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>Analytics +  Experimentation</h2>
                                        <ul>
                                            <li>Analytics to quantify new feature impact</li>
                                            <li>Validating product ideas</li>
                                            <li>Building the right product</li>
                                            <li>A/B Testing</li>
                                            <li>Defining MVP and Success Metrics</li>
                                            <li>Event design</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>Success Metrics, Event design problem solving and Root cause analysis assignment</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Framework(s)</p>
                                                <h6>Google Analytics or CleverTap</h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Debarati} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>The mock interview session has helped me immensely in improving my interview preparation and
                                                    as such I was able to crack the interview process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="week5" className="tab-pane fade">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>Roadmap Setup + Team Management</h2>
                                        <ul>
                                            <li>Roadmaps and Backlogs</li>
                                            <li>How to write consumable PRDs, User Stories</li>
                                            <li>Prioritisation</li>
                                            <li>Product Development Work with devs, designers and other stakeholders</li>
                                            <li>Agile Processes</li>
                                            <li>Release Management</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>Product requirements doc creation and OKRs planning assignment</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Framework(s)</p>
                                                <h6>JIRA or a project management planning </h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Chandrashekhar} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>I did a course with them on Product Management, which covered the various aspects of PM such
                                                    as acquisition, retention, UX, execution, analytics etc. and helped with interview preparation
                                                    conducting assignments ,mock interviews and giving solid feedback.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="week6" className="tab-pane fade">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>Technology for PMs</h2>
                                        <ul>
                                            <li>How applications are built?</li>
                                            <li>System architecture</li>
                                            <li>APIs and Webhooks</li>
                                            <li>Structured vs No-SQL Databases</li>
                                            <li>Minimum viable product approach</li>
                                            <li>Hybrid vs. Native vs. PWA apps</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>Technology Solutioning and Prioritisation problem assignment</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Tool(s)</p>
                                                <h6>Postman</h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Deepak} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>Best was sessions by Guest PM experts in their fields. Nikunj, Ankit helped elucidate the
                                                    concepts, real product experiences with an insightful process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="week7" className="tab-pane fade">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>Acquisition + Retention</h2>
                                        <ul>
                                            <li>Launching a Product</li>
                                            <li>Channel optimisation (ITL vs LTP funnels)</li>
                                            <li>User acquisition</li>
                                            <li>Retention Metrics</li>
                                            <li>Working backwards from product, business goals</li>
                                            <li>Measuring how your product is doing (Product Health)</li>

                                            <li>Continuous Discovery, Iterations</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>feature suggestions for solving Acquisition / Retention with UI prototypes</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Framework(s)</p>
                                                <h6>AARRR, Funnel optimisation strategies</h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Parijat} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>I have learnt so much within a short span of time and am only getting started. If you are an
                                                    aspiring PM, do consider PM School’s program to build your foundations.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="week8" className="tab-pane fade">
                                <div className="week-content-wrap">
                                    <div className="week-content-left-row">
                                        <h3>The Curriculum</h3>
                                        <h2>Approaching Interviews and solving case studies</h2>
                                        <ul>
                                            <li>Solving an Interview assignment</li>
                                            <li>Product Metrics, Design and Behavioural questions</li>
                                            <li>Signaling PM cues in a Resume</li>
                                            <li>How to prepare for Interviews - approaching different type of product questions</li>
                                        </ul>
                                    </div>
                                    <div className="week-content-right-row">
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week1} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Case Study</p>
                                                <h6>Usage Adoption question, Product launch assignment</h6>
                                            </div>
                                        </div>
                                        <div className="week-icon-wrap">
                                            <div className="week-icon">
                                                <img loading="lazy" src={week2} alt="icon" />
                                            </div>
                                            <div className="week-icon-content">
                                                <p>Framework(s)</p>
                                                <h6>Interview Prep tips and tricks, Case Study approach</h6>
                                            </div>
                                        </div>
                                        <div className="week-testimonial-wrap">
                                            <div className="week-testimonial-images">
                                                <img loading="lazy" src={Anu} alt="testimonial images" />
                                            </div>
                                            <div className="week-testimonial-content">
                                                <h6>STUDENT SPEAKS</h6>
                                                <p>Thank you PM School for helping me understand advanced Product concepts. Nikunj sir helped me
                                                    with the product strategy dilemmas and practice scenarios before interviews.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Weeks
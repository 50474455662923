import React, { useState } from "react";
import { getUserQuestionData } from "../../../api/ApiRequest";
import Check from "./Modal/Check";
import Option from "./Option";
import "./index.scss";

const Question = ({
  setShowPopup,
  question,
  questionNo,
  answers,
  multiSelection,
  programId,
  userId,
  setQuestionData,
  setCurrentQuestion,
  currentQuestion,
  questionData,
  dayComplete,
  lastQuestionCorrect,
  setChallengeComplete,
  challengeComplete,
  isAnswerCorrect,
  correctAns,
  isSubmitted,
  setIsSubmitted,
  questionSubmit,
  selectedAnswers,
  setSelectedAnswers,
}) => {
  const [inputSelected, setInputSelected] = useState(false);

  const nextQuestionBtn = () => {
    getUserQuestionData({
      userId: userId?.toString(),
      pmRunProgramTypeId: programId?.toString(),
    })
      .then((res) => res)
      .then((json) => {
        setSelectedAnswers([]);
        setQuestionData(json.data);
        setCurrentQuestion(json.data.currentQuestion[0]);
        setIsSubmitted(false);
        isAnswerCorrect(null);
      })
      .catch((error) => error);
  };
  return (
    <div className="question__wrapper">
      <div className="question__top">
        <h6>QUESTION {questionNo}</h6>
        <p className="question">{question}</p>
        {!dayComplete ? (
          isSubmitted ? (
            <Check
              isCorrect={isAnswerCorrect}
              nextQuestionBtn={nextQuestionBtn}
              currentQuestion={currentQuestion}
              questionData={questionData}
              setShowPopup={setShowPopup}
              setChallengeComplete={setChallengeComplete}
              challengeComplete={challengeComplete}
              correctAns={correctAns}
              userId={userId}
              pmRunProgramTypeId={programId}
            />
          ) : (
            <form className="ans__form" onSubmit={questionSubmit}>
              <div className="options">
                {answers?.map((answer) => (
                  <Option
                    answer={answer.answer}
                    correctAnswer={answer.isCorrectAnswer}
                    key={answer._id}
                    setSelectedAnswers={setSelectedAnswers}
                    selectedAnswers={selectedAnswers}
                    answerId={answer._id}
                    multiSelection={multiSelection}
                    setInputSelected={setInputSelected}
                    inputSelected={inputSelected}
                  />
                ))}
              </div>
              <button
                type="submit"
                className={`submit__btn ${
                  selectedAnswers.length <= 0 && "disabled"
                }`}
                disabled={selectedAnswers.length <= 0}
              >
                Submit
              </button>
            </form>
          )
        ) : (
          <Check
            isCorrect={isAnswerCorrect}
            nextQuestionBtn={nextQuestionBtn}
            currentQuestion={questionData?.lastSubmittedQues[0]}
            questionData={questionData}
            setShowPopup={setShowPopup}
            lastQuestionCorrect={lastQuestionCorrect}
            dayComplete={dayComplete}
            correctAns={correctAns}
            userId={userId}
            pmRunProgramTypeId={programId}
          />
        )}
      </div>
    </div>
  );
};

export default Question;

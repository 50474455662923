import React, { useEffect, useState } from "react";

const Option = ({
 answer,
 correctAnswer,
 selectedAnswers,
 setSelectedAnswers,
 answerId,
 multiSelection,
}) => {
 const [isChecked, setIsChecked] = useState(false);
 useEffect(() => {
  const check = selectedAnswers.find((id) => id === answerId);
  if (check) {
   setIsChecked(true);
  } else {
   setIsChecked(false);
  }
 }, [selectedAnswers, answerId]);
 return (
  <div className={`option ${isChecked && "active"}`}>
   <div className="radio__button"></div>
   <p>{answer}</p>
   <input
    type="checkbox"
    checked={isChecked}
    onClick={(e) => {
     if (selectedAnswers.find((id) => id === answerId)) {
      const updatedAnswers = selectedAnswers.filter(
       (selectedAnswerId) => answerId !== selectedAnswerId
      );
      setSelectedAnswers(updatedAnswers);
     } else {
      if (multiSelection) {
       setSelectedAnswers([...selectedAnswers, answerId]);
      } else {
       setSelectedAnswers([answerId]);
      }
     }
    }}
   />
  </div>
 );
};

export default Option;

import React from "react";
import Banner from "./Banner";
import CourseCard from "./CourseCard";
import "./index.scss";
import StudentPathCard from "./StudentPathCard";
import Slider from "react-slick";
import TestimonialCard from "./TestimonialCard";
import FAQ from "../course/faq";
import LogoContainer from "./LogoContainer";
import { assets } from "../../utils/assets";

const MicroCourse = () => {
  const LaurinImg = assets["laurin-lukas"];
  const PrashanthImg = assets["prashanth-bhaskaran"];
  const IntelligenceIcon = assets["intellicence"];
  const GroupIcon = assets["group-icon"];
  const PuzzleIcon = assets["puzzle-icon"];
  const SlackGroupIcon = assets["slack-group-icon"];
  const CalenderOkIcon = assets["calender-ok-icon"];
  const BadgeIcon = assets["badge-icon"];
  const AnanyaImg = assets["ananya"];
  const KarishmaImg = assets["Karishma"];
  const PrithuImg = assets["Prithu"];
  const RuthvikImg = assets["Ruthvik"];
  const RayvantImg = assets["Rayvant"];
  const SagarImg = assets["Sagar"];
  const SiddheshImg = assets["Siddhesh"];
  const TusharImg = assets["Tushar"];
  const ved = assets["ved"];

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    dots: true,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div></div>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div className="microCourse__wrapper">
      <Banner
        title="Are you interested in
learning the basics of
Product management?"
        description="Enrol in our PM X Course today!"
        buttonText="Explore Now"
        enrollLink="#microCourse"
        video={assets["Micro Courses_V2"]}
        bannerCounter={true}
        newTip={true}
      />

      <section
        className="micro__course"
        id="microCourse"
      >
        <h2 className="microCourse__title">Micro Courses</h2>
        <p className="description">
          A self serve learning program to help you figure the fundamentals of
          Product Management
        </p>
        <CourseCard
          courseTeachers={[
            {
              name: "Laurin Lukas Stahl",
              img: LaurinImg,
              post: "Teacher",
              profileLink: "https://www.linkedin.com/in/laurin-stahl/",
            },
          ]}
          title="PM X Course"
          description="A 4 hour self-serve learning program to help you figure the fundamentals of Product management. You get high definition video content, Industry Case studies and Lifetime access to the PM School Slack community. If you are starting from zero and want to pick up on Product management basics in a short amount of time, this is the course for you."
          course="PMX Course"
          time="4 hours to complete"
          certification="Certificate of Completion"
          language="English"
          access="Full time access"
          price="₹ 6,999"
          getStartedUrl="https://pmschool.thinkific.com/courses/pm-school"
          comingsoon="Get Started"
        />
        <CourseCard
          courseTeachers={[
            {
              name: "Laurin Lukas Stahl",
              img: LaurinImg,
              post: "Teacher",
              profileLink: "https://www.linkedin.com/in/laurin-stahl/",
            },
          ]}
          title="Masterclass on Product Strategy"
          description="Explore the nuanced world of product strategy with our comprehensive masterclass. This course offers a step-by-step guide, from initial concept to strategic planning. Benefit from real-world examples and expert insights to understand the key components of effective product strategy. Enroll today to deepen your knowledge and refine your approach to product development."
          course="Online, Part Time"
          time="3 hours to complete"
          certification="Certificate of Completion"
          language="English"
          access="Full time access"
          price="₹ 2,999"
          getStartedUrl="https://pmschool.thinkific.com/enroll/2637248?price_id=3454036"
          comingsoon="Get Started"
          // comingsoon="Coming soon"
        />
        <CourseCard
          courseTeachers={[
            {
              name: "Ved Pandey",
              img: ved,
              post: "Teacher",
              profileLink:
                "https://www.linkedin.com/in/ved-prakash-pandey-845b4995/",
            },
          ]}
          title="Analytics for PMs"
          description="PM School’s Product Analytics for PMs is a course designed to help product managers understand the fundamentals of how data and analytics are used to help product managers build better products and make better product decisions. The course walks you through the types of data, sources to collect this data, tools to analyse, and techniques built on top of this data to help guide PMs to assess the current state of the product they’re working on, and make better decisions which help with the future success of the product or features."
          course="Online, Part Time"
          time="2 hours to complete"
          certification="Certificate of Completion"
          language="English"
          access="Full time access"
          price="₹ 1,999"
          getStartedUrl="https://pmschool.thinkific.com/courses/pm-school-analytics-for-pms"
          comingsoon="Get Started"
        />

        <CourseCard
          courseTeachers={[
            {
              name: "Prashanth Bhaskaran",
              img: PrashanthImg,
              post: "Teacher",
              profileLink: "https://www.linkedin.com/in/prashanthbhaskar/",
            },
          ]}
          title="Interview Prep"
          description="A 60 mins mini course curated to focus solely on Interview preparation. Get Product management Interview question videos and access to the PM School Slack community. If you have an upcoming Interview, and are unsure of where to start, pick up this course to get guidance on potential Product management Interview questions and Resume making guidance."
          course="Online, Part Time"
          time="2 hours to complete"
          certification="Certificate of Completion"
          language="English"
          access="Full time access"
          price="₹ 1,999"
          getStartedUrl="https://pmschool.thinkific.com/courses/pm-school-interview-prep"
          comingsoon="Get Started"
        />

        {/* <div className="upcoming__course">
          <span className="recommend">Work In Progress</span>
          <h3 className="title">Upcoming Courses</h3>
          <p className="description">
            We are in the process of developing more courses for you!
          </p>
          <div className="course__list">
            <div className="upCourse__card">
              <img src={CourseIcon} alt="" />
              <h3>Analytics for PM</h3>
              <p>Simplifying data analytics and decision making for PM’s</p>
            </div>
            <div className="upCourse__card">
              <img src={CourseIcon} alt="" />
              <h3>Technology Basics for PM’s</h3>
              <p>
                A primer on Technology basics for candidates from a
                non-technical background.
              </p>
            </div>
            <p className="more__course">
              + More curated courses upcoming for you!
            </p>
          </div>
        </div> */}
      </section>
      <section className="student__path">
        <h2>Empowering Students For The Path To Success</h2>
        <div className="card__list">
          <StudentPathCard
            img={IntelligenceIcon}
            text="Guided self-growth through
            weekly challenges"
          />
          <StudentPathCard
            img={GroupIcon}
            text="Lifetime connections with
            Industry experts"
          />
          <StudentPathCard
            img={PuzzleIcon}
            text="Problem Solving with
            battle-tested Mentors"
          />
          <StudentPathCard
            img={SlackGroupIcon}
            text="Lifetime access to Slack
            community"
          />
          <StudentPathCard
            img={CalenderOkIcon}
            text="Opportunity to attend exclusive
            PM School alumni only events"
          />
          <StudentPathCard
            img={BadgeIcon}
            text="Speak to Industry veterans"
          />
        </div>
      </section>

      <section className="student__testimonials">
        <h2>Student Testimonials</h2>
        <div
          className="slider"
          onMouseDown={(e) => (e.target.style.cursor = "grabbing")}
          onMouseUp={(e) => (e.target.style.cursor = "grab")}
        >
          <Slider {...settings}>
            <TestimonialCard
              name="Ananya Nandan"
              position="Associate Product Manager at MPL"
              description="Working at Mobile Premier League (MPL) as an APM has  been nothing less than exciting, to say the least. Couldn’t have been possible without PM School, a community that has supported me for the last 2 years to move into Product Management. Extremely grateful to the encouragement and mentorship of Ankit Bareja and other mentors who guided me at every step and made this transition successful."
              img={AnanyaImg}
            />
            <TestimonialCard
              name="Prashanth Bhaskaran"
              position="Product Manager at CRED"
              description="The 8-week curriculum covers all the major topics in product management that an aspiring PM should know about. A structured learning like this in my view is really important especially for a profession that is new and one that colleges don’t cover much at all. The mentorship aspect of the program is one of the best value-adds for any aspirant."
              img={PrashanthImg}
            />
            <TestimonialCard
              name="Karishma Kapoor"
              position="Product Manager at BYJU’S"
              description="I am beyond grateful for everything that PM School has poured into me! The learnings and the wisdom I've collected will stay with me forever. I would like to thank to the entire team of PM School for creating such an intensive curriculum that gives a holistic view of Product Management. I learned a ton from my mentors Ankit Bareja, Nikunj Sharma, Manish Soni, Nikhita Aron in my cohort."
              img={KarishmaImg}
            />
            <TestimonialCard
              name="Prithu Verma"
              position="Associate Product Manager at Housing.com"
              description="A big thanks to PM School and the awesome team running them, without you guys this would not have been possible. The two months of learning with PM School and the referrals I got from them at various amazing companies is what helped me achieve making a transition into this newest chapter of my professional life. A special thanks to Ankit Bareja, Nikunj Sharma, and Kushaal Devanahalli."
              img={PrithuImg}
            />
            <TestimonialCard
              name="Rayvant Gupta"
              position="Product Manager at HD Works"
              description="Recently completed the Live Program at PM School. Would recommend it to anyone looking to grasp the finer nuances of product management and build further on it. The 8-week program comprises of live lectures from industry experts and case studies which simulate actual problems. Add to this the 1-on-1 mentorship, mock-interviews, assignment feedback & an access to PM community and its support."
              img={RayvantImg}
            />
            <TestimonialCard
              name="Ruthvik Majety"
              position="Product Manager at HighRadius"
              description="I would like to thank PM School for their 2 months program. I found very good mentors there such as Ankit Bareja and Arjav Jain who have helped me through out all of my interview calls with mock interviews, feedback and mentorship. PM School is a great platform to both learn about PM role and also a networking platform to get good referrals. I can’t stress enough about how important this was for me. "
              img={RuthvikImg}
            />
            <TestimonialCard
              name="Sagar Dang"
              position="Lead Product Manager at MakeMyTrip"
              description="The unique approach of live classes along with weekly assignments in the form of interesting PM case studies made sure I learned about various types of typical PM problems and different ways to tackle them. It helped me transition from a B2B product and analytics background to a more streamlined B2C product role. Last but not the least, an earnest shout-out to Ankit Bareja Nikunj Sharma for being always there as a sounding board for countless queries or curiosity-driven doubts I had."
              img={SagarImg}
            />
            <TestimonialCard
              name="Siddhesh Desai"
              position="Associate Product Manager at Cashfree"
              description="PM School program offered a well-structured 8- week training on different aspects of roles of PM along with assignments, to get a complete understanding of the role. PM School also offered  one on one mentorship, mock interview sessions, and access to a community of fellow PMs where you will find people frequently sharing PM-specific content and  job opportunities. I would like to thank Ankit and Nikunj the co-founders of PM School and Prajney my mentor at PM School for guiding me throughout my course. "
              img={SiddheshImg}
            />
            <TestimonialCard
              name="Tushar Anand"
              position="Product Manager at OLA"
              description="Special Thanks to my alumni network for introducing me to this opportunity and the amazing team at PM School for guiding me during the whole preparation phase. I never would have been able to reach this milestone without the mentors I’ve so fortunately met in my life."
              img={TusharImg}
            />
          </Slider>
        </div>
      </section>
      <LogoContainer />
      <FAQ
        title="FAQ's"
        mainEntity={[
          {
            "@type": "Question",
            name: "What's the difference between the PM Flagship course and the Micro courses (PM X)?",
            acceptedAnswer: {
              "@type": "Answer",
              text: `The PM Flagship course is more comprehensive with hands-on Mentor engagement. Whereas, a PM X Course is self-serve with \n -Teaching Assistant support not included \n -Live Classes not included \n -No placement support.`,
            },
          },
          {
            "@type": "Question",
            name: "Who are the Micro courses for?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Working professionals and students in the final year of their degree programs interested in PM roles are ideal for our product micro courses. You need not have prior product experience.  ",
            },
          },
          {
            "@type": "Question",
            name: "How long will I have access to the Micro course after purchase? ",
            acceptedAnswer: {
              "@type": "Answer",
              text: "You will have life-time access to the course material as well as exclusive access to the PM School Slack community with channels on queries, job-postings, and access to product leadership.  ",
            },
          },
          {
            "@type": "Question",
            name: "What do I get after completion of this program?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "You get a certificate of completion from PM School, a strong network of Product management peers and a lifetime access to the PM School community.",
            },
          },
        ]}
      />
    </div>
  );
};

export default MicroCourse;
